import React, { useEffect, useRef, useState } from 'react';
import { RegisterFormTypes, useDataProvider, useLink, useLogin, useRegister, useTranslate } from '@refinedev/core';
import { logoNonCollapsed } from '../assets';
import { CredentialResponse } from '../interfaces/google';
import { allowedDomains, emailRegex } from '../web-config';
import { Button, Card, Col, DatePicker, Divider, Form, Input, Row, Select } from 'antd';
import { Box, Button as MUIButton, Typography, useMediaQuery } from '@mui/material';
import { LandingFooter, LandingHeader } from '../components/layout';
import { CustomBackground } from '../components';
import { CheckCircle, UploadFile } from '@mui/icons-material';
import { axios } from '../App';
import { useLocation } from 'react-router-dom';

interface RegistrationFormTypes extends RegisterFormTypes {
    firstName: string;
    lastName: string;
    avatar: string | null;
    gender: string;
    phone: string;
    dateOfBirth: Date;
    degreeDepartment: string  | "";
}

export const VerificationPage: React.FC = () => {
    const Link = useLink();
    const dataProvider = useDataProvider();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    const [error, setError] = useState<string>('');
    const translate = useTranslate();

    useEffect(() => {
        async function fetchResponseData() {
            const response = await axios.get(`${dataProvider().getApiUrl()}/auth/verification/${token}`);
            if (response.status !== 200) {
                setError(translate("register-page.errorLink", 'Invalid link. Please try sending another verification email from the settings page.'));
            } else {
                const user = localStorage.getItem('user');
                if (user) {
                    const parsedUser = JSON.parse(user);
                    parsedUser.verified = true;
                    localStorage.setItem('user', JSON.stringify(parsedUser));
                }
            }
        }

        fetchResponseData();
    }, [dataProvider, token, translate]);

    return (
        <>
            <CustomBackground />
            <LandingHeader landingPage={false} />
            <Card style={{
                maxWidth: 550,
                width: "100%",
                margin: "auto",
                marginTop: 60,
                textAlign: "center",
                boxShadow: '5px 5px 20px #000000',
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100%',
            }}
            >
                <Box sx={{ marginTop: "10px", marginBottom: "10px" }}>
                    <Link to="/">
                        <img src={logoNonCollapsed} height="35%" width="35%" alt="BraudeOverflow Logo" />
                    </Link>
                </Box>
                <Divider style={{ fontSize: "clamp(18px, 1.9vw, 22px)" }}>{translate("register-page.divtext", "Verify Account")}</Divider>
                {error !== '' ? (
                    <Box mt={2} mb={2}>
                        <Typography fontSize={18} fontWeight={500} color="#11142D">{error}</Typography>
                    </Box>
                    ) : (
                    <Box mt={2} mb={2}>
                        <Typography fontSize={18} fontWeight={500} color="#11142D">{translate("register-page.verifySuccess", "Account successfully verified.")}</Typography>
                        <CheckCircle sx={{ color: "#2EB700", fontSize: ["2rem", "3rem"], paddingTop: "1rem" }}/>
                    </Box>
                )}
            </Card>
            <Box
                sx={{
                    position: "absolute",
                    bottom: 0,
                    width: "100%",
                    backgroundColor: "transparent",
                    color: "#fff",
                    textAlign: "center",
                }}
            >
                <LandingFooter />
            </Box>
        </>
    );
}

export const Register: React.FC = () => {
    const { mutate: login } = useLogin<CredentialResponse>();
    const { mutate: register } = useRegister<RegistrationFormTypes>();
    const [form] = Form.useForm();
    const [avatar, setAvatar] = useState({ name: "", url: "" });
    const [error, setError] = useState<string | null>(null);
    const [verificationMessage, setVerificationMessage] = useState<string>('');
    const fileInputRef = useRef<HTMLInputElement>(null);
    const canFooterFit = useMediaQuery('(min-width: 768px)');
    const maxWidthCol = useMediaQuery('(min-width: 450px)');
    const Link = useLink();
    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const { Option } = Select;

    const handleRegistration = ({ email, password, firstName, lastName, avatar, gender, phone, dateOfBirth, degreeDepartment } : RegistrationFormTypes) => {
        register({
            email, password, firstName, lastName, avatar, gender, phone, dateOfBirth, degreeDepartment
        },{
            onSuccess: async (data) => {
                setError('');
                if (!data.success) {
                    if (data.error?.message === 'User already exists') {
                        setError('User already exists');
                    } else {
                        setError('Something went wrong, please try again later');
                    }
                    return;
                }

                const response = await axios.post(`${dataProvider().getApiUrl()}/auth/verification`, {
                    email: email?.toLowerCase(),
                });

                if (response.status !== 200) {
                    setVerificationMessage('Error sending verification email. Please try again later from the settings page, or contact support - support@braudeoverflow.live');
                }

                setVerificationMessage(translate("register-page.emailSent", "Thank you for registering to our network. You may login and start using our services. If you wish you unlock the full potential of our webapps, you need to verify your account. A verification email and further instructions have been sent to the email address you registered with."));
            }
        });
    }

    const handleAvatarChange = (file: File) => {
        const reader = (readFile: File) => new Promise<string>((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.onload = () => resolve(fileReader.result as string);
            fileReader.readAsDataURL(readFile);
        });

        reader(file).then((result: string) => setAvatar({ name: file?.name, url: result }));
    };

    const handleResetAvatar = () => {
        setAvatar({ name: '', url: '' });
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }
    }

    const onFinish = (values : RegistrationFormTypes) => {
        values.avatar = avatar.url;
        handleRegistration(values);
    };

    const validateEmail = (_, value) => {
        if (value && !emailRegex.test(value)) {
            return Promise.reject(translate("register-page.emailerr", "Please enter your email address"));
        }
        const domain = value?.split('@')[1];
        if (value && allowedDomains.indexOf(domain) === -1) {
            return Promise.reject(translate("register-page.invaliddomain", "Email domain is not allowed"));
        }

        return Promise.resolve();
    };

    const GoogleButton = (): JSX.Element => {
        const divRef = useRef<HTMLDivElement>(null);

        useEffect(() => {
            if (typeof window === "undefined" || !window.google || !divRef.current) {
                return;
            }

            try {
                window.google.accounts.id.initialize({
                    ux_mode: "popup",
                    client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
                    callback: async (res: CredentialResponse) => {
                        if (res.credential) {
                            login({
                                providerName: "Google",
                                credential: res.credential
                            },{
                                onSuccess: async (data) => {
                                    setError('');
                                    if (!data.success) {
                                        setError(data.error.message);
                                    }
                                }
                            });
                        }
                    },
                });
                window.google.accounts.id.renderButton(divRef.current, {
                    theme: "filled_blue",
                    size: "medium",
                    type: "standard",
                    width : "240",
                    shape: "pill",
                    text: "signup_with"
                });
            } catch (error) {
                console.log(error);
            }
        }, []);

        return <div ref={divRef} />;
    };

    return (
        <Box display="flex" justifyContent="center" mt={maxWidthCol ? 10 : 15}>
            <CustomBackground />
            <LandingHeader landingPage={false} />
            <Card style={{
                    maxWidth: 650,
                    width: "90%",
                    textAlign: "center",
                    boxShadow: '5px 5px 20px #000000',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100%',
                }}
            >
                <Box sx={{ marginTop: "10px", marginBottom: "10px" }}>
                    <Link to="/">
                        <img src={logoNonCollapsed} height="25%" width="25%" alt="BraudeOverflow Logo" />
                    </Link>
                </Box>
                <Divider style={{ fontSize: "clamp(18px, 1.9vw, 22px)" }}>{translate("register-page.signup","Register")}</Divider>
                {verificationMessage !== '' ? (
                    <Box mt={2} mb={2}>
                        <Typography fontSize={18} fontWeight={500} color="#11142D">{verificationMessage}</Typography>
                        {!verificationMessage.includes('error') ? <CheckCircle sx={{ color: "#2EB700", fontSize: ["2rem", "3rem"], paddingTop: "1rem" }}/> : ''}
                    </Box>
                ) : (
                    <Box>
                        <Form form={form} onFinish={onFinish}>
                            <Row justify="space-between" style={{ marginTop: 40 }}>
                                <Col xs={24} sm={24} md={11} lg={11} xl={11}>
                                    <Form.Item
                                        name="email"
                                        label={translate('register-page.email', 'Email')}
                                        rules={[
                                            { required: true, message: translate("register-page.emailerr", "Please enter your email address") },
                                            { validator: validateEmail },
                                        ]}
                                    >
                                        <Input placeholder={translate('register-page.email', 'Email')} />
                                    </Form.Item>

                                    <Form.Item
                                        name="password"
                                        label={translate("login-page.pass","Password")}
                                        rules={[{ required: true, message: translate("register-page.passerr", "Please enter your password") }]}
                                    >
                                        <Input.Password placeholder={translate("login-page.pass","Password")} />
                                    </Form.Item>
                                    <Form.Item
                                        name="firstName"
                                        label={translate("register-page.firstname","First Name")}
                                        rules={[{ required: true, message: translate("register-page.firstnameerr", "Please enter your first name") }]}
                                    >
                                        <Input placeholder={translate("register-page.lastname","Last Name")} />
                                    </Form.Item>
                                    <Form.Item
                                        name="lastName"
                                        label={translate("register-page.lastname","Last Name")}
                                        rules={[{ required: true, message: translate("register-page.lastnameerr","Please enter your last name") }]}
                                    >
                                        <Input placeholder={translate("register-page.lastname","Last Name")} />
                                    </Form.Item>
                                    {error && (
                                        <Typography style={{ display: "block", fontSize: 15, fontWeight: 400, color: "#B30000"}}>
                                            {error}
                                        </Typography>
                                    )}
                                </Col>
                                <Col xs={24} sm={24} md={11} lg={11} xl={11}>
                                    <Form.Item name="dateOfBirth" label={translate("register-page.dateofbirth","Date of Birth")} initialValue="">
                                        <DatePicker style={{ width: "100%" }} />
                                    </Form.Item>
                                    <Form.Item
                                        name="phone"
                                        label={translate("register-page.phonenum","Phone Number")}
                                        initialValue=""
                                        rules={[{
                                            pattern: /^0(5[^7]|[2-4]|[8-9]|7[0-9])[0-9]{7}$/,
                                            message: translate("register-page.invalidisrphone","Invalid Israeli phone number")
                                        }]}
                                    >
                                        <Input placeholder={translate("register-page.phonenum","Phone Number")} />
                                    </Form.Item>
                                    <Form.Item name="gender" label={translate("register-page.gender","Gender")} initialValue="">
                                        <Select placeholder="Select a gender">
                                            <Option value="Male">{translate("register-page.male","Male")}</Option>
                                            <Option value="Female">{translate("register-page.female","Female")}</Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item name="degreeDepartment" label={translate("register-page.dep","Department")} initialValue="">
                                        <Select placeholder="Select a department">
                                            <Option value="Software Engineering">{translate("register-page.depsoft","Software Engineering")}</Option>
                                            <Option value="Data Engineering">{translate("register-page.depdata","Data Systems Engineering")}</Option>
                                            <Option value="Mechanical Engineering">{translate("register-page.depmech","Mechanical Engineering")}</Option>
                                            <Option value="Electrical Engineering">{translate("register-page.depelec","Electrical Engineering")}</Option>
                                            <Option value="Bio-technology">{translate("register-page.depbio","Bio-technology Engineering")}</Option>
                                            <Option value="Industrial Engineering">{translate("register-page.depindus","Industrial Engineering")}</Option>
                                            <Option value="Civil Engineering">{translate("register-page.depcivil","Civil Engineering")}</Option>
                                            <Option value="Optical Engineering">{translate("register-page.depopt","Optical Engineering")}</Option>
                                            <Option value="Applied Mathematics">{translate("register-page.depmath","Applied Mathematics")}</Option>
                                            <Option value="Applied Mathematics And Software Engineering">{translate("register-page.depsoftmath","Applied Mathematics & Software Engineering")}</Option>
                                            <Option value="Applied Mathematics And Electrical Engineering">{translate("register-page.depelecmath","Applied Mathematics & Electrical Engineering")}</Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item name="avatar" label={translate("register-page.profilepic","Profile Picture")}>
                                        <Box>
                                            <MUIButton
                                                sx = {{
                                                    width: "160px",
                                                    color: "#FCFCFC",
                                                    textTransform: "capitalize",
                                                    fontSize: 16,
                                                    backgroundColor: "#475BE8",
                                                    "&:hover": {
                                                        backgroundColor: "#1E36E8"
                                                    },
                                                }}
                                            >
                                                <label htmlFor="avatar-upload" style={{ cursor: "pointer", display: "flex", flexDirection: "row" }}>
                                                    <UploadFile />
                                                    <Typography>
                                                        {translate("register-page.upload","Upload")}
                                                    </Typography>
                                                </label>
                                                <input
                                                    ref={fileInputRef}
                                                    id="avatar-upload"
                                                    style={{ display: "none" }}
                                                    accept="image/*"
                                                    type="file"
                                                    onChange={(e) => {
                                                        handleAvatarChange(e.target.files![0])
                                                    }}
                                                />
                                            </MUIButton>
                                            <MUIButton
                                                sx = {{
                                                    width: "180px",
                                                    color: "#808191",
                                                    textTransform: "capitalize",
                                                    fontSize: 16,
                                                    backgroundColor: "inherit",
                                                    margin: "5px auto",
                                                    "&:hover": {
                                                        opacity: 0.7,
                                                        backgroundColor: "inherit",
                                                        transition: "all 0.3s ease-in-out",
                                                    },
                                                }}
                                                onClick={handleResetAvatar}
                                            >
                                                {translate("register-page.resetimg","Reset Image")}
                                            </MUIButton>
                                            {/*If there is an image, then display the file's name.*/}
                                            {avatar?.name && (
                                                <Typography
                                                    fontSize={14}
                                                    color="#808191"
                                                    sx={{ wordBreak: "break-all" }}
                                                >
                                                    {avatar.name}
                                                </Typography>
                                            )}
                                        </Box>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item>
                                <Button
                                    title="register"
                                    type="primary"
                                    size="large"
                                    htmlType="submit"
                                    block
                                    style={{
                                        borderRadius: "20px"
                                    }}
                                >
                                    {translate("header.signup","Register")}
                                </Button>
                            </Form.Item>
                        </Form>
                        <Divider>{translate("login-page.or","or")}</Divider>
                        <Button
                            type="default"
                            block
                            icon={<GoogleButton />}
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "25%",
                                margin: "15px auto 8px",
                            }}
                        >
                        </Button>
                    </Box>
                )}
            </Card>
            {canFooterFit && (
                <Box
                    sx={{
                        position: "absolute",
                        bottom: 0,
                        width: "100%",
                        backgroundColor: "transparent",
                        color: "#fff",
                        textAlign: "center",
                    }}
                >
                    <LandingFooter />
                </Box>
            )}
        </Box>
    );
};
