import React, { ChangeEvent, useEffect, useState } from 'react';
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    MenuItem,
    Stack,
    TextField,
    Typography
} from '@mui/material';

import { Check, Diversity3, Grading, RateReview, Sell, Send, VideoSettings } from '@mui/icons-material';
import { CustomButton } from '../components';
import { UserStorage } from '../interfaces/common';
import { parseJwt } from '../utils/parse-jwt';
import { useForm } from '@refinedev/react-hook-form';
import { FieldValues } from 'react-hook-form';
import { axios } from '../App';
import {
  UpdatePasswordFormTypes,
  useDataProvider,
  useGetIdentity,
  useGetLocale,
  useGo,
  useTranslate,
  useUpdatePassword
} from '@refinedev/core';
import InputAdornment from '@mui/material/InputAdornment';

interface UpdatePasswordTypes extends UpdatePasswordFormTypes {
    currentPassword: string;
}

function UserSettings() {
    // To verify token and prevent CSRF/token tampering.
    const token = localStorage.getItem('googleToken');
    const decodedToken = token ? parseJwt(token) : null;
    const issuerClaim = 'https://accounts.google.com';

    // Account termination states.
    const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
    const [email, setEmail] = useState<string>('');
    const [areCredentialsValid, setAreCredentialsValid] = useState<boolean>(false);

    // Update password states.
    const [passError, setPassError] = useState<string>('');

    const { data } = useGetIdentity<UserStorage>();

    const myProfile: any = data ?? [];
    const dataProvider = useDataProvider();
    const locale = useGetLocale();
    const currentLocale = locale();
    const currDir = currentLocale === 'en' ? 'ltr' : 'rtl';
    const go = useGo();

    // Form default settings useStates.
    const [grade, setGrade] = useState<number | null>(() => {
        const formSettingsData = localStorage.getItem("tutorFormSettings");
        if (formSettingsData) {
            return JSON.parse(formSettingsData).grade;
        }
        return null;
    });
    const [platform, setPlatform] = useState<string>(() => {
        const formSettingsData = localStorage.getItem("tutorFormSettings");
        if (formSettingsData) {
            return JSON.parse(formSettingsData).platform;
        }
        return "";
    });
    const [price, setPrice] = useState<number | null>(() => {
        const formSettingsData = localStorage.getItem("tutorFormSettings");
        if (formSettingsData) {
            return JSON.parse(formSettingsData).price;
        }
        return null;
    });
    const [method, setMethod] = useState<string>(() => {
        const formSettingsData = localStorage.getItem("tutorFormSettings");
        if (formSettingsData) {
            return JSON.parse(formSettingsData).method;
        }
        return "";
    });
    const [tablet, setTablet] = useState<string>(() => {
        const formSettingsData = localStorage.getItem("tutorFormSettings");
        if (formSettingsData) {
            return JSON.parse(formSettingsData).tablet;
        }
        return "false";
    });

    const translate = useTranslate();
    // Listen for changes in the settings and save them.
    useEffect(() => {
        localStorage.setItem(
            "tutorFormSettings",
            JSON.stringify({ grade, platform, price, method, tablet })
        );
    }, [grade, platform, price, method, tablet]);

    // Account termination
    const handleDialogPopUp = () => {
        setEmail('');
        setAreCredentialsValid(false);
        setConfirmDelete(!confirmDelete);
    }

    const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
        setAreCredentialsValid(event.target.value.toLowerCase() === myProfile.email);
    };

    const handleDeleteAccount = async () => {
        handleDialogPopUp();
        try {
            await axios.delete(`${dataProvider().getApiUrl()}/users/`);
            const googleToken = localStorage.getItem('googleToken');

            if (googleToken && typeof window !== 'undefined') {
                axios.defaults.headers.common = {};
                window.google?.accounts.id.revoke(googleToken, () => {
                    return {};
                });
                localStorage.removeItem('googleToken');
            }

            localStorage.removeItem('user');
            localStorage.removeItem('token');

            go({
                to: '/login',
            })
        } catch (err) {
            console.error(err);
        }
    }

    const { refineCore: { formLoading }, register, handleSubmit, watch } = useForm();
    const { mutate: updatePassword } = useUpdatePassword<UpdatePasswordTypes>();
    const onFinishHandler = async (data: FieldValues) => {
        updatePassword({
            password: data.newPassword,
            confirmPassword: data.newPasswordConfirmation,
            currentPassword: data.currentPassword,
        }, {
            onSuccess: (response) => {
                if (!response.success) {
                    if (response.error?.message.includes('Invalid password')) {
                        setPassError('Invalid password');
                    } else {
                        setPassError('Something went wrong, please try again later');
                    }
                    return;
                }
            }
        });
    };

    const handleSendVerificationEmail = async () => {
        await axios.post(`${dataProvider().getApiUrl()}/auth/verification`, {
            email: myProfile.email,
        });
        go({
            to: "/dashboard?verification=true"
        })
    }

    return (
        <Box
            maxWidth={{ xs: '300px', md: '768px', lg: '100%'}}
        >
            <Typography mt={0.75} fontSize={25} fontWeight={700} color="#11142D" dir={currDir}>
                {translate("settingpage.accsettings", "Account settings")}
            </Typography>

            <Box mt="1px" borderRadius="15px" padding="10px" bgcolor="inherit" dir ={currDir}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: { xs: "column", md: "row" }
                    }}
                >
                    <Box
                        flex={1}
                        sx={{
                            marginTop: { md: "58px" },
                            marginLeft: { xs: "5px", md: "0px" },
                        }}
                    >
                        <Box
                            flex={1}
                            display="flex"
                            flexDirection={{ xs: "column", md: "row" }}
                        >
                            <Box
                                flex={1}
                                display="flex"
                                flexDirection={{ xs: "column", md: "row" }}
                                gap={5}
                            >
                                <Stack direction='column' pb={1} flexWrap="wrap">
                                    <Typography
                                        fontSize={22}
                                        fontWeight={600}
                                        color="#11142D"
                                    >
                                        {translate("settingpage.defaulttutsett", "Default Tutor Settings")}
                                    </Typography>
                                    <Stack
                                        direction="column"
                                        flexWrap="wrap"
                                        gap="25px"
                                        pb={4}
                                    >
                                        <Stack flex={1} gap="15px">
                                            <Box mt={5}>
                                                <Box
                                                    pl={{ xs: 0, md: 3}}
                                                    sx={{
                                                        flexDirection: "column",
                                                    }}
                                                    maxWidth={{ xs: "260px" }}
                                                >
                                                    <Box
                                                        flex={1}
                                                        display="flex"
                                                        flexDirection={{ xs: "column", md: "row" }}
                                                        gap="20px"
                                                        mb="5px"
                                                    >
                                                        <FormControl>
                                                            <Typography
                                                                sx={{
                                                                    fontWeight: 500,
                                                                    fontSize: 14,
                                                                    color: "#808191",
                                                                }}
                                                            >{translate("settingpage.grade", "Grade")}</Typography>
                                                            <Box
                                                                display="flex"
                                                                flexDirection="row"
                                                                alignItems="center"
                                                                gap="10px"
                                                                mt={2}
                                                                maxWidth={{ xs: "240px", md: "360px", lg: "360px" }}
                                                                sx={{
                                                                    width: "260px",
                                                                }}
                                                            >
                                                                <Grading sx={{ color: "#11142D" }} />
                                                                <Typography
                                                                    fontSize={17}
                                                                    color="#11142D"
                                                                    noWrap
                                                                    sx={{
                                                                        width: "200px",
                                                                    }}
                                                                >
                                                                    {translate("settingpage.defgrade", "Default grade:")}
                                                                </Typography>
                                                                <TextField
                                                                    required
                                                                    id="outlined-number"
                                                                    placeholder={translate("settingpage.grade", "Grade")}
                                                                    size="small"
                                                                    color="info"
                                                                    type="number"
                                                                    sx={{
                                                                        width: "110px",
                                                                        maxWidth: "150px",
                                                                    }}
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    inputProps={{
                                                                        min: 55,
                                                                        max: 100,
                                                                    }}
                                                                    value={grade}
                                                                    onChange={(e) => setGrade(parseInt(e.target.value))}
                                                                />
                                                            </Box>
                                                        </FormControl>
                                                    </Box>
                                                </Box>
                                                <Box
                                                    pl={{ xs: 0, md: 3}}
                                                    flex={1}
                                                    display="flex"
                                                    flexDirection={{ xs: "column", md: "row" }}
                                                    gap="40px"
                                                    mb="15px"
                                                    maxWidth={{ xs: "240px", md: "360px", lg: "360px" }}
                                                >
                                                    <FormControl>
                                                        <FormHelperText
                                                            sx={{
                                                                fontWeight: 500,
                                                                fontSize: 14,
                                                                color: "#808191",
                                                                marginLeft: "0",
                                                            }}
                                                        >{translate("settingpage.details", "Details")}</FormHelperText>
                                                        <Box
                                                            display="flex"
                                                            flexDirection="row"
                                                            alignItems="center"
                                                            gap="10px"
                                                            mt={2}
                                                        >
                                                            <VideoSettings sx={{ color: "#11142D" }} />
                                                            <Typography
                                                                fontSize={17}
                                                                color="#11142D"
                                                                noWrap
                                                            >
                                                                {translate("settingpage.deftutplat", "Default tutelage platform:")}
                                                            </Typography>
                                                            <Box
                                                                flex={1}
                                                                display="flex"
                                                                flexDirection={{ xs: "column", md: "row" }}
                                                                gap="10px"
                                                            >
                                                                <TextField
                                                                    required
                                                                    id="standard-select-currency"
                                                                    select
                                                                    variant="standard"
                                                                    color="info"
                                                                    value={platform}
                                                                    onChange={(e) => setPlatform(e.target.value)}
                                                                >
                                                                    <MenuItem value="Zoom">{translate("settingpage.zoom" ,"Zoom")}</MenuItem>
                                                                    <MenuItem value="Frontal">{translate("settingpage.frontal", "Frontal")}</MenuItem>
                                                                    <MenuItem value="Both">{translate("settingpage.both", "Both")}</MenuItem>
                                                                </TextField>
                                                            </Box>

                                                        </Box>
                                                        <Box
                                                            display="flex"
                                                            flexDirection="row"
                                                            alignItems="center"
                                                            gap="10px"
                                                            mt={2}
                                                        >
                                                            <RateReview sx={{ color: "#11142D" }} />
                                                            <Typography
                                                                fontSize={17}
                                                                color="#11142D"
                                                                noWrap
                                                            >
                                                                {translate("settingpage.tutortablet", "Do you tutor with a tablet?")}
                                                            </Typography>
                                                            <Box
                                                                flex={1}
                                                                display="flex"
                                                                flexDirection={{ xs: "column", md: "row" }}
                                                                gap="10px"
                                                            >
                                                                <TextField
                                                                    required
                                                                    id="standard-select-currency"
                                                                    select
                                                                    variant="standard"
                                                                    color="info"
                                                                    value={tablet}
                                                                    onChange={(e) => setTablet(e.target.value)}
                                                                >
                                                                    <MenuItem value="true">{translate("settingpage.yes", "Yes")}</MenuItem>
                                                                    <MenuItem value="false">{translate("settingpage.no", "No")}</MenuItem>
                                                                </TextField>
                                                            </Box>
                                                        </Box>
                                                        <Box
                                                            display="flex"
                                                            flexDirection="row"
                                                            alignItems="center"
                                                            gap="10px"
                                                            mt={2}
                                                        >
                                                            <Diversity3 sx={{ color: "#11142D" }} />
                                                            <Typography
                                                                fontSize={17}
                                                                color="#11142D"
                                                                noWrap
                                                            >
                                                                {translate("settingpage.tutorgroups", "Do you tutor in groups or 1on1?")}
                                                            </Typography>
                                                            <Box
                                                                flex={1}
                                                                display="flex"
                                                                flexDirection={{ xs: "column", md: "row" }}
                                                                gap="10px"
                                                            >
                                                                <Typography>
                                                                    <TextField
                                                                        required
                                                                        id="standard-select-currency"
                                                                        select
                                                                        variant="standard"
                                                                        color="info"
                                                                        value={method}
                                                                        onChange={(e) => setMethod(e.target.value)}
                                                                        sx={{
                                                                            maxWidth: "60px",
                                                                        }}
                                                                    >
                                                                        <MenuItem value="Groups">{translate("settingpage.groups", "Groups")}</MenuItem>
                                                                        <MenuItem value="One">{translate("settingpage.one", "1on1")}</MenuItem>
                                                                        <MenuItem value="Both">{translate("settingpage.both", "Both")}</MenuItem>
                                                                    </TextField>
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    </FormControl>
                                                </Box>
                                                <Box
                                                    pl={{ xs: 0, md: 3}}
                                                    flex={1}
                                                    display="flex"
                                                    flexDirection={{ xs: "column", md: "row" }}
                                                    gap="40px"
                                                    mb="15px"
                                                    maxWidth={{ xs: "240px", md: "360px", lg: "360px" }}
                                                >
                                                    <FormControl>
                                                        <FormHelperText
                                                            sx={{
                                                                fontWeight: 500,
                                                                fontSize: 14,
                                                                color: "#808191",
                                                                marginLeft: "0",
                                                            }}
                                                        >{translate("settingpage.price", "Price")}</FormHelperText>
                                                        <Box
                                                            display="flex"
                                                            flexDirection="row"
                                                            alignItems="center"
                                                            gap="10px"
                                                            mt={2}
                                                        >
                                                            <Sell sx={{ color: "#11142D" }} />
                                                            <Typography
                                                                fontSize={17}
                                                                color="#11142D"
                                                                noWrap
                                                                sx={{
                                                                    minWidth: "125px",
                                                                }}
                                                            >
                                                                {translate("settingpage.defprice", "Default price per hour:")}
                                                            </Typography>
                                                            <TextField
                                                                id="outlined-number"
                                                                placeholder={translate("settingpage.price","Price")}
                                                                size ="small"
                                                                color="info"
                                                                type="number"
                                                                sx={{
                                                                    maxWidth: "100px",
                                                                }}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                InputProps={{
                                                                    endAdornment: <InputAdornment position="start">₪</InputAdornment>,
                                                                }}
                                                                value={price}
                                                                onChange={(e) => setPrice(parseInt(e.target.value))}
                                                            />
                                                        </Box>
                                                    </FormControl>
                                                </Box>
                                            </Box>
                                        </Stack>
                                    </Stack>
                                </Stack>
                                {/*For non Google authenticated users, either no token or a tampered token.*/}
                                {((!decodedToken) || (decodedToken && decodedToken.iss !== issuerClaim && myProfile.registrationType === 'BraudeOverflow')) &&  (
                                    <>
                                        <Stack direction="column" pb={1} flexWrap="wrap">
                                        <form onSubmit={handleSubmit(onFinishHandler)}>
                                            <Box mb={5}>
                                                <Typography fontSize={22} fontWeight={600}
                                                            color="#11142D">{translate("settingpage.security", "Security")}</Typography>
                                            </Box>
                                            <Box mb={4} pl={1}>
                                                <Typography fontSize={18} fontWeight={550}
                                                            color="#11142D">{translate("settingpage.updatepass", "Update Password")}</Typography>
                                            </Box>
                                            {/* Only for non Google Users. */}
                                            <Box pl={3}>
                                                <Stack direction="column" gap={3}>
                                                    <Stack direction="row" alignItems="center" gap={7}>
                                                        <Typography fontSize={16} fontWeight={500} color="#11142D">{translate("settingpage.currentpass", "Current password:")} </Typography>
                                                        <TextField type="password" label= {translate("settingpage.currentpass", "Current password:")}
                                                                   color="info" {...register("currentPassword", {required: true})} />
                                                    </Stack>
                                                    <Stack direction="row" alignItems="center" gap={currentLocale === "en" ? 10.5 : 6}>
                                                        <Typography fontSize={16} fontWeight={500} color="#11142D">{translate("settingpage.newpass", "New password:")} </Typography>
                                                        <TextField type="password" label={translate("settingpage.newpass", "New password:")}
                                                                   color="info" {...register("newPassword", {required: true})} />
                                                    </Stack>
                                                    <Stack direction="row" alignItems="center" gap={2}>
                                                        <Typography fontSize={16} fontWeight={500} color="#11142D">{translate("settingpage.confirmpass", "Confirm new password:")} </Typography>
                                                        <TextField type="password" label={translate("settingpage.confirmpass", "Confirm new password:")}
                                                                   color="info" {...register("newPasswordConfirmation", {required: true})} />
                                                    </Stack>
                                                    {watch("newPassword") !== watch("newPasswordConfirmation") && (
                                                        <Typography fontSize={16} fontWeight={500} color="#FF6A69">{translate("settingpage.passmatch", "Passwords do not match.")}</Typography>
                                                    )}
                                                    <Typography fontSize={16} fontWeight={500} color="#FF6A69">{passError}</Typography>
                                                    <Stack direction="row" justifyContent="flex-end" mt={3}>
                                                        <CustomButton
                                                            type="submit"
                                                            title={formLoading ? translate("settingpage.updateprop", "Updating Password "): translate("settingpage.change", "Change")}
                                                            disabled={watch("newPassword") !== watch("newPasswordConfirmation")}
                                                            backgroundColor="#475BE8"
                                                            color="#FCFCFC"
                                                            icon=<Check/>
                                                        />
                                                    </Stack>
                                                </Stack>
                                            </Box>
                                        </form>
                                            {myProfile.verified === false && (
                                                <Box pl={1} mt={5}>
                                                    <Typography fontSize={18} fontWeight={550}
                                                                color="#11142D">{translate("settingpage.sendverifiemail", "Send Verification Email")}
                                                    </Typography>
                                                    <Box mt={3} pl={3}>
                                                        <Stack direction="row" alignItems="center" gap={2}>
                                                            <Typography  fontSize={16} fontWeight={500} color="#11142D" >
                                                            {translate("settingpage.unlockfunc", "Unlock the full functionality of the applications.")}
                                                            </Typography>
                                                        </Stack>
                                                        <Stack direction="row" justifyContent="flex-end" mt={3} >
                                                            <CustomButton
                                                                type="button"
                                                                title={formLoading ? translate("settingpage.verificationButtonLoading", "Sending...") : translate("settingpage.verificationButton", "Send Email")}
                                                                backgroundColor="#475BE8"
                                                                color="#FCFCFC"
                                                                icon=<Send/>
                                                                handleClick={handleSendVerificationEmail} // Handle send verification email.
                                                            />
                                                        </Stack>
                                                    </Box>
                                                </Box>
                                            )}
                                    </Stack>
                                    <Stack
                                        direction="column"
                                        flexWrap="wrap"
                                        pb={4}
                                        pl={5}
                                    >
                                        <Box mb={10}>
                                            <Typography fontSize={22} fontWeight={600}
                                                        color="#11142D">{translate("settingpage.dangerzone", "Danger Zone")}</Typography>
                                        </Box>
                                        <Box>

                                        </Box>
                                        <Stack
                                            direction="column"
                                            flexWrap="wrap"
                                            gap="25px"
                                        >
                                            <Stack direction="row" flex={1} mb={1}>
                                                <CustomButton
                                                    type="button"
                                                    subtype="terminate"
                                                    title={translate("settingpage.teminate", "Terminate Account")}
                                                    backgroundColor="#272B33"
                                                    color="#FF6A69"
                                                    handleClick={handleDialogPopUp}/>
                                            </Stack>
                                            {confirmDelete && (
                                                <Box>
                                                    <Dialog
                                                        open={confirmDelete}
                                                        onClose={handleDialogPopUp}
                                                        PaperProps={{
                                                            style: {
                                                                backgroundColor: "#272B33",
                                                            },
                                                        }}
                                                    >
                                                        <DialogTitle
                                                            color="#FF6A69"
                                                            sx={{
                                                                textAlign: 'center'
                                                            }}
                                                        >
                                                            {translate("settingpage.confirmtermination", "Confirm Termination")}
                                                        </DialogTitle>
                                                        <DialogContent sx={{paddingTop: '0px'}}>
                                                            <Box
                                                                mb={2}
                                                            >
                                                                <Typography fontSize={16} fontWeight={400}
                                                                            color="#F0F3F6" mb={2}>
                                                                    {translate("settingpage.termimsg", " Are you sure you want to terminate this account? If so, please enter your email address and then click on the confirm button.")}
                                                                </Typography>
                                                                <Typography fontSize={18} fontWeight={600}
                                                                            color="#F0F3F6">
                                                                    {translate("settingpage.termimsg2", "Be Warned! This action cannot be undone.")}
                                                                </Typography>
                                                            </Box>
                                                            <TextField
                                                                label={translate("settingpage.enteremail","Enter your Email address")}
                                                                fullWidth
                                                                margin="normal"
                                                                value={email}
                                                                onChange={handleEmailChange}
                                                                inputProps={{
                                                                    style: {
                                                                        color: '#F0F3F6',
                                                                        fontSize: '16px',
                                                                        fontWeight: '400'
                                                                    },
                                                                }}
                                                                sx={{
                                                                    '& .MuiInputBase-input:focus': {
                                                                        color: '#FF6A69',
                                                                    },
                                                                    '& .MuiInputLabel-root.Mui-focused': {
                                                                        color: '#FF6A69',
                                                                    },
                                                                    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                                        borderColor: '#FF6A69',
                                                                    },
                                                                }}/>
                                                        </DialogContent>
                                                        <DialogActions>
                                                            <CustomButton
                                                                disabled={!areCredentialsValid}
                                                                type="button"
                                                                subtype="terminate"
                                                                title={translate("settingpage.confirmdelete", "Delete")}
                                                                backgroundColor="#272B33"
                                                                color="#FF6A69"
                                                                handleClick={handleDeleteAccount}/>
                                                        </DialogActions>
                                                    </Dialog>
                                                </Box>
                                            )}
                                        </Stack>
                                    </Stack>
                                    </>
                                )}
                            </Box>
                        </Box>
                        <Box mt={10} textAlign="center">
                            <Stack direction="column" pb={1} flexWrap="wrap">
                                <Typography
                                    fontSize={22}
                                    fontWeight={600}
                                    color="#11142D"
                                    display="flex"
                                    justifyContent="center"
                                >
                                    {translate("settingpage.privacyinfotos", "Privacy, Information & ToS")}
                                </Typography>
                                <Stack
                                    direction="column"
                                    flexWrap="wrap"
                                    pb={4}
                                    mt={2}
                                    dir={currDir}
                                >
                                    <Typography color="#11142D" fontSize={16} fontWeight={400} >
                                        {translate("settingpage.promp1", "Please be advised, we have no association with Braude. This is our personal project, our mission was to deliver services to students as students.")}
                                    </Typography>
                                    <Typography color="#11142D" fontSize={16} fontWeight={400}>
                                        {translate("settingpage.promp2", "The user is responsible for the information that they choose to make public. Most notably, you are responsible for your contact information that you advertise. We hold or take no responsibility over your intellectual property if it was shared by you.")}
                                    </Typography>
                                    <Typography color="#11142D" fontSize={16} fontWeight={400}>
                                        {translate("settingpage.promp3", "It's against our policy and interests as students for our users and private tutors to falsify the information that they advertise. If you see users and private tutors that did so, please contact us and we will take action given evidence.")}
                                    </Typography><Typography color="#11142D" fontSize={16} fontWeight={400}>
                                        {translate("settingpage.promp4", "Moreover, we expect the users to respect the services provided and the hardwork that went into developing them. Any users caught attempting illegal actions and/or inappropriate actions, will be permanently banned from our network.")}
                                    </Typography>
                                    <Typography color="#11142D" fontSize={16} fontWeight={400}>
                                        {translate("settingpage.promp5", "If you have any feedback or issues, please feel free to contact us. Click on the support email below in the footer and send us a message.")}
                                    </Typography>
                                    <Typography
                                        fontSize={22}
                                        fontWeight={600}
                                        color="#11142D"
                                        mt={4}
                                        mb={2}
                                        display="flex"
                                        justifyContent="center"
                                    >
                                        {translate("settingpage.credit", "Credits")}
                                    </Typography>
                                    <Typography color="#11142D" fontSize={16} fontWeight={400}>
                                        {translate("settingpage.promp6", "This project was created and developed by Avishay Bar & Eyal Maklada.")}
                                    </Typography>
                                    <Typography color="#11142D" fontSize={16} fontWeight={400}>
                                        {translate("settingpage.promp7", "The Scheduler was brought to you by Shay Bistrizky.")}
                                    </Typography>
                                    <Typography color="#11142D" fontSize={16} fontWeight={400}>
                                        {translate("settingpage.promp8", "Special thanks to Ori Avital.")}
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default UserSettings;

