import React, { createRef, useEffect, useState } from 'react';
import { CourseObjectProps, GroupProps, SchedulerData, TimeRangeProps } from '../../interfaces/apps';
import { Scheduler, View } from 'devextreme-react/scheduler';
import moment from 'moment';
import '../../assets/scheduler-styles.scss';
import { FaTrashAlt } from 'react-icons/fa';

export const dayOfWeekNames = [
    'ראשון',
    'שני',
    'שלישי',
    'רביעי',
    'חמישי',
    'שישי',
    'שבת',
];

export const groupTypeToColor: { [key: string]: string } = {
    הרצאה: '#03d3fc',
    תרגיל: '#3dfc03',
    'שו"ת': '#fca503',
    מעבדה: '#F5FF09',
    'הנחיית פרוייקט': '#BC71F1',
};

function createDate(
    dayOfWeek: number,
    startHour: string,
    endHour: string
): TimeRangeProps {
    const date = moment().day(dayOfWeek).format('YYYY-MM-DD');
    return {
        startDate: new Date(`${date}T${startHour}`),
        endDate: new Date(`${date}T${endHour}`),
    };
}

interface SchedulerProps {
    groups: GroupProps[];
    unselect: (groupId: string) => void;
    courses: CourseObjectProps[];
    isExpanded: boolean;
}

const SchedulerComponent = (props: SchedulerProps) => {
  const [hoveredGroupId, setHoveredGroupId] = useState<string>('');
  const scheduler = createRef<Scheduler>();
  const data = props.groups
    .filter(g => g.selected)
    .flatMap(g =>
      g.classes.map(c => ({
        courseName: props.courses.find(course => course.id === g.courseId)
          ?.name,
        ...createDate(c.day, c.startHour, c.endHour),
        ...g,
        ...c,
      }))
    );

  const animDuration = 500;
  const refreshRate = props.isExpanded ? 120 : 750;

  useEffect(() => {
    let x = 0;
    const repaint = setInterval(() => {
      if (++x === Math.ceil(animDuration / refreshRate)) {
        clearInterval(repaint);
      } else {
        scheduler.current?.instance.repaint();
      }
    }, refreshRate);
    return () => clearInterval(repaint);
  }, [props.isExpanded, scheduler, refreshRate]);

  const deleteAppointment = (appointmentData: SchedulerData) => {
    const g = data?.filter(s => s._id === appointmentData._id);
    g?.forEach(g => scheduler.current?.instance.deleteAppointment(g));
    props.unselect(appointmentData._id);
  };

  return (
    <div
      className={`scheduler-container ${
        props.isExpanded ? 'scheduler-container-hidden' : ''
      }`}
    >
      <Scheduler
        ref={scheduler}
        dataSource={data}
        onCellClick={e => {
          e.cancel = true;
        }}
        showAllDayPanel={false}
        currentDate={moment().day(0).toDate()}
        currentView={'day'}
        startDayHour={8}
        endDayHour={22}
        editing={{
          allowResizing: false,
          allowDragging: false,
          allowUpdating: false,
          allowAdding: false,
        }}
        maxAppointmentsPerCell={3}
        onAppointmentTooltipShowing={e => {
          e.cancel = true;
        }}
        showCurrentTimeIndicator={false}
        onAppointmentClick={e => {
          e.cancel = true;
        }}
        onAppointmentDblClick={e => {
          e.cancel = true;
        }}
        dateCellRender={dateData => (
          <div>{dayOfWeekNames[dateData.date.getDay()]}</div>
        )}
        timeCellRender={timeData => (
          <div>
            {timeData.text
              ? moment(timeData.text, 'hh:mm a').format('HH:mm')
              : ''}
          </div>
        )}
        appointmentRender={({
                              appointmentData,
                            }: {
          appointmentData: SchedulerData;
        }) => (
          <div
            className="appointment"
            onMouseEnter={_ => setHoveredGroupId(appointmentData._id)}
            onMouseLeave={_ => setHoveredGroupId('')}
            onClick={e => {
              if (e.detail !== 2) return;
              deleteAppointment(appointmentData);
            }}
            style={{
              backgroundColor: `${
                appointmentData.type in groupTypeToColor
                  ? groupTypeToColor[appointmentData.type]
                  : '#ffffff'
              }${hoveredGroupId === appointmentData._id ? '99' : 'ff'}`,
              border: `${
                hoveredGroupId === appointmentData._id
                  ? '2px dashed white'
                  : 'none'
              }`,
            }}
          >
            <span>
              {appointmentData.courseName} ({appointmentData.type})
            </span>
            <span>{appointmentData.lecturerName}</span>
            <div>
              <span>
                {appointmentData.classroom.split(' ').slice(0, 2).join(' ')}
              </span>
              <span>
                {appointmentData.startHour} - {appointmentData.endHour}
              </span>
            </div>
            <FaTrashAlt
              className="deleteAppointment"
              onClick={_ => deleteAppointment(appointmentData)}
            />
          </div>
        )}
      >
        <View type={'day'} intervalCount={6} cellDuration={60} />
      </Scheduler>
    </div>
  );
};

export default SchedulerComponent;
