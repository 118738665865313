import { CalendarMonth, Edit, Email, PersonPin, Phone, School } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import { CustomButton } from 'components/index';
import { ProfileProps, ProfileRoleProps } from 'interfaces/profile';
import dayjs from 'dayjs';
import { useGetLocale, useGo, useTranslate } from '@refinedev/core';

function Profile( { firstName, lastName, email, dateOfBirth, phone, avatar, gender, degreeDepartment, role, deleteTutorID, tutorHook } : ProfileProps & ProfileRoleProps) {
    const handleRevokeTutor = async () => {
        // Revoke function
        await deleteTutorID();
    }
    const go = useGo();
    const locale = useGetLocale();
    const currentLocale = locale();
    const translate = useTranslate();
    const baseTitle: string = translate("myprofilepage.profiletitle", "'s Profile").toLowerCase();
    const departments = {
        [translate("register-page.depsoft", "Software Engineering")]: "Software Engineering",
        [translate("register-page.depdata", "Data Systems Engineering")]: "Data Engineering",
        [translate("register-page.depmech", "Mechanical Engineering")]: "Mechanical Engineering",
        [translate("register-page.depelec", "Electrical Engineering")]: "Electrical Engineering",
        [translate("register-page.depbio", "Bio-technology Engineering")]: "Bio-technology",
        [translate("register-page.depindus", "Industrial Engineering")]: "Industrial Engineering",
        [translate("register-page.depcivil", "Civil Engineering")]: "Civil Engineering",
        [translate("register-page.depopt", "Optical Engineering")]: "Optical Engineering",
        [translate("register-page.depmath", "Applied Mathematics")]: "Applied Mathematics",
        [translate("register-page.depsoftmath", "Applied Mathematics & Software Engineering")]: "Applied Mathematics And Software Engineering",
        [translate("register-page.depelecmath", "Applied Mathematics & Electrical Engineering")]: "Applied Mathematics And Electrical Engineering",
    };

    const genders = {
        [translate("register-page.male", "Male")]: "Male",
        [translate("register-page.female", "Female")]: "Female"
    };

    const getFieldTranslation = (object, value) => {
        for (let key in object) {
            if (object[key] === value) {
                return key;
            }
        }
        return null; // Value not found
    };

    return (
        <Box
            flex={1}
            flexDirection={"column"}>
            <Typography mt={0.75} fontSize={25} fontWeight={700} color="#11142D" textTransform="capitalize">
                {(role === 2 || role === 3) && (
                    "Administrator: "
                )}
                {currentLocale === "he" ?  `${baseTitle} ${firstName} ${lastName}` : `${firstName} ${lastName + baseTitle}` }
            </Typography>

            <Box mt="1px" borderRadius="15px" padding="10px" bgcolor="inherit">
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: { xs: "column", md: "row" },
                        gap: 2.5,
                    }}
                >
                    <img
                        src={avatar}
                        width={300}
                        height={270}
                        style={{ marginTop: "40px", borderRadius: "15px" }}
                        alt="Profile Avatar"
                    />
                    <Box
                        flex={1}
                        sx={{
                            marginTop: { md: "58px" },
                            marginLeft: { xs: "20px", md: "0px" },
                        }}
                    >
                        <Box
                            flex={1}
                            display="flex"
                            flexDirection={{ xs: "column", md: "row" }}
                            gap="20px"
                        >
                            <Box
                                flex={1}
                                display="flex"
                                flexDirection="column"
                                justifyContent="space-between"
                                gap="15px"
                            >
                                <Stack direction="column" pb={1}>
                                    <Typography
                                        fontSize={22}
                                        fontWeight={600}
                                        color="#11142D"
                                    >
                                        {translate("myprofilepage.information", "Information")}
                                    </Typography>
                                </Stack>
                                <Stack
                                    flex={1}
                                    direction="row"
                                    flexWrap="wrap"
                                    pb={4}
                                >
                                    <Stack
                                        direction="column"
                                        flexWrap="wrap"
                                        gap="20px"
                                        mr={20}
                                        pb={4}
                                    >
                                        <Stack flex ={1} gap="20px">
                                            <Typography
                                                fontSize={14}
                                                fontWeight={500}
                                                color="#808191"
                                            >
                                                {translate("myprofilepage.emailaddress", "Email")}
                                            </Typography>
                                            <Box
                                                display="flex"
                                                flexDirection="row"
                                                alignItems="center"
                                                gap="10px"
                                            >
                                                <Email sx={{ color: "#11142D" }} />
                                                <Typography
                                                    fontSize={14}
                                                    color="#11142D"
                                                    noWrap
                                                >
                                                    {email}
                                                </Typography>
                                            </Box>
                                        </Stack>
                                        <Stack flex={1} gap="15px">
                                            <Typography
                                                fontSize={14}
                                                fontWeight={500}
                                                color="#808191"
                                            >
                                                {translate("myprofilepage.department", "Department")}
                                            </Typography>
                                            <Box
                                                display="flex"
                                                flexDirection="row"
                                                alignItems="center"
                                                gap="10px"
                                            >
                                                <School sx={{ color: "#11142D" }} />
                                                <Typography
                                                    fontSize={14}
                                                    color="#11142D"
                                                    noWrap
                                                >
                                                    {degreeDepartment !== '' ? getFieldTranslation(departments, degreeDepartment) : translate("myprofilepage.notAvailable", "N/A")}
                                                </Typography>
                                            </Box>
                                        </Stack>
                                    </Stack>
                                    <Stack
                                        direction="column"
                                        flexWrap="wrap"
                                        gap="25px"
                                        mr={20}
                                        pb={4}
                                    >
                                        <Stack flex={1} gap="15px">
                                            <Typography
                                                fontSize={14}
                                                fontWeight={500}
                                                color="#808191"
                                            >
                                                {translate("myprofilepage.gender", "Gender")}
                                            </Typography>
                                            <Box
                                                display="flex"
                                                flexDirection="row"
                                                alignItems="center"
                                                gap="10px"
                                            >
                                                <PersonPin sx={{ color: "#11142D" }} />
                                                <Typography
                                                    fontSize={14}
                                                    color="#11142D"
                                                >
                                                    {gender !== '' ? getFieldTranslation(genders, gender) : translate("myprofilepage.notAvailable", "N/A")}
                                                </Typography>
                                            </Box>
                                        </Stack>
                                        <Stack flex={1} gap="15px">
                                            <Typography
                                                fontSize={14}
                                                fontWeight={500}
                                                color="#808191"
                                            >
                                                {translate("myprofilepage.phone", "Phone Number")}
                                            </Typography>
                                            <Box
                                                display="flex"
                                                flexDirection="row"
                                                alignItems="center"
                                                gap="10px"
                                            >
                                                <Phone sx={{ color: "#11142D" }} />
                                                <Typography
                                                    fontSize={14}
                                                    color="#11142D"
                                                    noWrap
                                                >
                                                    {phone !== '' ? phone : translate("myprofilepage.notAvailable", "N/A")}
                                                </Typography>
                                            </Box>
                                        </Stack>
                                    </Stack>
                                    <Stack
                                        direction="column"
                                        flexWrap="wrap"
                                        gap="25px"
                                        mr={20}
                                        pb={4}
                                    >
                                        <Stack flex={1} gap="15px">
                                            <Typography
                                                fontSize={14}
                                                fontWeight={500}
                                                color="#808191"
                                            >
                                                {translate("myprofilepage.dob", "Date of Birth")}
                                            </Typography>
                                            <Box
                                                display="flex"
                                                flexDirection="row"
                                                alignItems="center"
                                                gap="10px"
                                            >
                                                <CalendarMonth sx={{ color: "#11142D" }} />
                                                <Typography
                                                    fontSize={14}
                                                    color="#11142D"
                                                >
                                                    {dateOfBirth !== null ? dayjs(new Date(dateOfBirth).toDateString()).format("DD-MM-YYYY") : translate("myprofilepage.notAvailable", "N/A")}
                                                </Typography>
                                            </Box>
                                        </Stack>
                                        <Stack flex={1} mt={4}>
                                            <CustomButton
                                                type="button"
                                                title={translate("myprofilepage.editButton", "Edit")}
                                                backgroundColor="inherit"
                                                color="#475BE8"
                                                icon = <Edit/>
                                                handleClick={() => {
                                                    go({
                                                        to: `/my-profile/edit`
                                                    })
                                                }}
                                            />
                                        </Stack>
                                    </Stack>

                                    {(tutorHook === 1 || tutorHook === 3) && (
                                        <Stack
                                            direction="column"
                                            flexWrap="wrap"
                                            gap="25px"
                                        >
                                            <Stack flex={1} gap="15px"></Stack>
                                            <Stack flex={1} mb={1}>
                                                <CustomButton
                                                    type = "button"
                                                    title={translate("myprofilepage.revokeButton", "Revoke Tutor Role")}
                                                    backgroundColor="#B30000"
                                                    color="#FCFCFC"
                                                    handleClick={handleRevokeTutor}
                                                />
                                            </Stack>
                                        </Stack>
                                    )}
                                </Stack>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
export default Profile;
