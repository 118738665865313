import React, { useEffect, useState } from 'react';
import { Sider as DefaultSider } from '@refinedev/mui';

import {
  Box,
  Button,
  Collapse,
  Drawer,
  IconButton,
  List as MuiList,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip
} from '@mui/material';
import {
  AdminPanelSettingsOutlined,
  AssessmentOutlined,
  BlockOutlined,
  ChevronLeft,
  ChevronRight,
  Dashboard,
  ExpandLess,
  ExpandMore,
  HandymanOutlined,
  ListOutlined,
  Logout,
  MenuRounded
} from '@mui/icons-material';

import {
  CanAccess,
  ITreeMenu,
  useDataProvider,
  useGo,
  useIsExistAuthentication,
  useLink,
  useLogout,
  useMenu,
  useTitle,
  useTranslate
} from '@refinedev/core';

import { Title as DefaultTitle } from '../title';
import { Divider } from 'antd';
import { loggedInUserRole } from '../../../App';

export const Sider: typeof DefaultSider = ({ render }) => {
  const dataProvider = useDataProvider();
  const [collapsed, setCollapsed] = useState(false);
  const [opened, setOpened] = useState(false);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  const drawerWidth = () => {
    if (collapsed) return 64;
    return 200;
  };

  const t = useTranslate();
  const Link = useLink();
  const hasDashboard = useLink();
  const translate = useTranslate();
  const go = useGo();


  useEffect(() => {
    if (loggedInUserRole >= 2)
      setIsAdmin(true);
  }, [dataProvider]);

  const { menuItems, selectedKey, defaultOpenKeys } = useMenu();
  const isExistAuthentication = useIsExistAuthentication();
  const { mutate: mutateLogout } = useLogout();
  const Title = useTitle();

  const [open, setOpen] = useState<{ [k: string]: any }>({});

  React.useEffect(() => {
    setOpen((previousOpen) => {
      const previousOpenKeys: string[] = Object.keys(previousOpen);
      const uniqueKeys = new Set([...previousOpenKeys, ...defaultOpenKeys]);
      const uniqueKeysRecord = Object.fromEntries(
        Array.from(uniqueKeys.values()).map((key) => [key, true])
      );
      return uniqueKeysRecord;
    });
  }, [defaultOpenKeys]);

  const RenderToTitle = Title ?? DefaultTitle;

  const handleClick = (key: string) => {
    setOpen({ ...open, [key]: !open[key] });
  };

  const renderTreeView = (tree: ITreeMenu[], selectedKey: string) => {
    return tree.map((item: ITreeMenu) => {
        const {
            meta,
            name,
            children,
            route,
        } = item;
        const isOpen = open[item.key || ""] || false;

        const isSelected = item.key === selectedKey;
        const isNested = !(
            meta?.parent ===
            undefined
        );

      if (children.length > 0) {
        return (
          <CanAccess
            key={item.key}
            resource={name.toLowerCase()}
            action="list"
            params={{
              resource: item,
            }}
          >
            <div key={item.key}>
              <Tooltip
                title={meta?.label ?? name}
                placement="right"
                disableHoverListener={!collapsed}
                arrow
              >
                <ListItemButton
                  onClick={() => {
                    if (collapsed) {
                      setCollapsed(false);
                      if (!isOpen) {
                        handleClick(meta?.route || "");
                      }
                    } else {
                      handleClick(meta?.route || "");
                    }
                  }}
                  sx={{
                    pl: isNested ? 4 : 2,
                    justifyContent: "center",
                    "&.Mui-selected": {
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                      backgroundColor: "transparent",
                    },
                  }}
                >
                  <ListItemIcon
                    sx={{
                      justifyContent: "center",
                      minWidth: 36,
                      color: "primary.contrastText",
                    }}
                  >
                    {meta?.icon ?? <ListOutlined />}
                  </ListItemIcon>
                  <ListItemText
                    primary={meta?.label}
                    primaryTypographyProps={{
                      noWrap: true,
                      fontSize: "16px",
                      fontWeight: isSelected ? "bold" : "normal",
                        color: isSelected ? "#FFF" : "#808191",
                    }}
                  />
                  {!collapsed && (isOpen ? <ExpandLess /> : <ExpandMore />)}
                </ListItemButton>
              </Tooltip>
              {!collapsed && (
                <Collapse in={open[item.key || ""]} timeout="auto" unmountOnExit>
                  <MuiList component="div" disablePadding>
                    {renderTreeView(children, selectedKey)}
                  </MuiList>
                </Collapse>
              )}
            </div>
          </CanAccess>
        );
      }

      return (
        <CanAccess
          key={item.key}
          resource={name.toLowerCase()}
          action="list"
          params={{ resource: item }}
        >
          <Tooltip
            title={meta?.label ?? name}
            placement="right"
            disableHoverListener={!collapsed}
            arrow
          >
            <ListItemButton
              component={Link}
              to={route}
              selected={isSelected}
              onClick={() => {
                setOpened(false);
              }}
              sx={{
                pl: isNested ? 4 : 2,
                py: isNested ? 1.25 : 1,
                "&.Mui-selected": {
                  "&:hover": {
                    backgroundColor: isSelected ? "#1E36E8" : "transparent",
                  },
                  backgroundColor: isSelected ? "#475BE8" : "transparent",
                },
                justifyContent: "center",
                  margin: '10px auto',
                  borderRadius: '12px',
                  minHeight: '56px',
                  width: '90%'
              }}
            >
              <ListItemIcon
                sx={{
                  justifyContent: "center",
                  minWidth: 36,
                }}
              >
                {meta?.icon ?? <ListOutlined />}
              </ListItemIcon>
              <ListItemText
                primary={meta?.label}
                primaryTypographyProps={{
                  noWrap: true,
                  fontSize: "14px",
                  fontWeight: isSelected ? "bold" : "normal",
                    color: isSelected? "#FFF" : "#808191",
                }}
              />
            </ListItemButton>
          </Tooltip>
        </CanAccess>
      );
    });
  };
  const languageDir = translate("header.lng", "he") === "he" ? "rtl" : "ltr";
  const dashboard = hasDashboard ? (
    <CanAccess resource="dashboard" action="list">
      <Tooltip
        title={translate("sider.dashboard", "Dashboard")}
        placement="right"
        disableHoverListener={!collapsed}
        arrow
      >
        <ListItemButton
          component={Link}
          to="/"
          selected={selectedKey === "/"}
          onClick={() => {
            setOpened(false);
          }}
          sx={{

            pl: 2,
            py: 1,
            "&.Mui-selected": {
              "&:hover": {
                  backgroundColor: selectedKey === "/" ? "#1E36E8" : "transparent",
              },
                backgroundColor: selectedKey === "/" ? "#475BE8" : "transparent",
            },
            justifyContent: "center",
              margin: '10px auto',
              borderRadius: '12px',
              minHeight: '56px',
              width: '90%',

          }}
        >
          <ListItemIcon
            sx={{
              justifyContent: "center",
              minWidth: 36,
              color: "#808191",

            }}
          >
            <Dashboard />
          </ListItemIcon>
          <ListItemText
            primary={translate("sider.dashboard", "Dashboard")}
            primaryTypographyProps={{
              noWrap: true,
              fontSize: "16px",
              fontWeight: selectedKey === "/" ? "bold" : "normal",
                color: selectedKey === "/"? "#FFF" : "#808191",
            }}
          />
        </ListItemButton>
      </Tooltip>
    </CanAccess>
  ) : null;

  const logout = isExistAuthentication && (
    <Tooltip
      title={translate("sider.logout", "Logout")}
      placement="right"
      disableHoverListener={!collapsed}
      arrow
    >
      <ListItemButton
        key="logout"
        onClick={() => mutateLogout()}
        sx={{ justifyContent: "center",
                margin: '10px auto',
                borderRadius: '12px',
                minHeight: '56px',
                width: '90%',
        }}
      >
        <ListItemIcon
          sx={{
            justifyContent: "center",
            minWidth: 36,
            color: "#808191",
          }}
        >
          <Logout />
        </ListItemIcon>
        <ListItemText
          primary={translate("sider.logout", "Logout")}
          primaryTypographyProps={{
              noWrap: true,
              fontSize: "14px",
          }}
        />
      </ListItemButton>
    </Tooltip>
  );

    const allUsers = isExistAuthentication && isAdmin && (
        <Tooltip
            title={translate("sider.getallusers", "All Users")}
            placement="right"
            disableHoverListener={!collapsed}
            arrow
        >
            <ListItemButton
                key="getAllUsers"
                sx={{
                    justifyContent: "center",
                    margin: '10px auto',
                    borderRadius: '12px',
                    minHeight: '56px',
                    width: '100%',
                    direction: languageDir
                }}
                onClick={() => {
                    go({
                        to: `/admin-control/users`
                    })
                }}
            >
                <ListItemIcon
                    sx={{
                        justifyContent: "center",
                        minWidth: 36,
                        color: "#808191",
                    }}
                >
                    <AdminPanelSettingsOutlined />
                </ListItemIcon>
                <ListItemText
                    primary={translate("sider.getallusers", "All Users")}
                    primaryTypographyProps={{
                        noWrap: true,
                        fontSize: "14px",
                    }}
                />
            </ListItemButton>
        </Tooltip>
    );

    const adminTools = isExistAuthentication && isAdmin && (
        <Tooltip
            title={translate("sider.admintools", "Admin Tools")}
            placement="right"
            disableHoverListener={!collapsed}
            arrow
        >
            <ListItemButton
                key="adminTools"
                sx={{
                    justifyContent: "center",
                    margin: '10px auto',
                    borderRadius: '12px',
                    minHeight: '56px',
                    width: '100%',
                    direction: languageDir
                }}
                onClick={() => {
                    go({
                        to: `/admin-control/admin-tools`
                    })
                }}
            >
                <ListItemIcon
                    sx={{
                        justifyContent: "center",
                        minWidth: 36,
                        color: "#808191",
                    }}
                >
                    <HandymanOutlined />
                </ListItemIcon>
                <ListItemText
                    primary={translate("sider.admintools", "Admin Tools")}
                    primaryTypographyProps={{
                        noWrap: true,
                        fontSize: "14px",
                    }}
                />
            </ListItemButton>
        </Tooltip>
    );

    const allReports = isExistAuthentication && isAdmin && (
        <Tooltip
            title={translate("sider.allreports", "All Reports")}
            placement="right"
            disableHoverListener={!collapsed}
            arrow
        >
            <>
                <ListItemButton
                    key="getAllReports"
                    sx={{
                        justifyContent: "center",
                        margin: '10px auto',
                        borderRadius: '12px',
                        minHeight: '56px',
                        width: '100%',
                        direction: languageDir
                    }}
                    onClick={() => {
                        go({
                            to: `/admin-control/escans-reports`
                        })
                    }}
                >
                    <ListItemIcon
                        sx={{
                            justifyContent: "center",
                            minWidth: 36,
                            color: "#808191",
                        }}
                    >
                        <AssessmentOutlined />
                    </ListItemIcon>
                    <ListItemText
                        primary={translate("sider.allreports", "All Reports")}
                        primaryTypographyProps={{
                            noWrap: true,
                            fontSize: "14px",
                        }}
                    />
                </ListItemButton>
            </>
        </Tooltip>
    );

    const bannedUsers = isExistAuthentication && isAdmin && (
        <Tooltip
            title={translate("sider.bannedusers", "Banned Users")}
            placement="right"
            disableHoverListener={!collapsed}
            arrow
        >
            <>
                <ListItemButton
                    key="getBannedUsers"
                    sx={{
                        justifyContent: "center",
                        margin: '10px auto',
                        borderRadius: '12px',
                        minHeight: '56px',
                        width: '100%',
                        direction:languageDir
                    }}
                    onClick={() => {
                        go({
                            to: `/admin-control/banned-users`
                        })
                    }}
                >
                    <ListItemIcon
                        sx={{
                            justifyContent: "center",
                            minWidth: 36,
                            color: "#808191",
                        }}
                    >
                        <BlockOutlined />
                    </ListItemIcon>
                    <ListItemText
                        primary={translate("sider.bannedusers", "Banned Users")}
                        primaryTypographyProps={{
                            noWrap: true,
                            fontSize: "14px",
                        }}
                    />
                </ListItemButton>
                <Divider></Divider>
            </>
        </Tooltip>
    );

  const items = renderTreeView(menuItems, selectedKey);

  const renderSider = () => {
    if (render) {
      return render({
        dashboard,
        logout,
        items,
        collapsed,
      });
    }
    return (
      <>
        {adminTools}
        {allUsers}
        {allReports}
        {bannedUsers}
        {items}
        {logout}
      </>
    );
  };

  const drawer = (
    <MuiList disablePadding sx={{ mt: 1, color: "#808191", padding: '10px' }}>
      {renderSider()}
    </MuiList>
  );

  return (
    <>
      <Box
        sx={{
          width: { xs: drawerWidth() },
          display: {
            xs: "none",
            md: "block",
          },
          transition: "width 0.3s ease",
        }}
      />
      <Box
        component="nav"
        sx={{
          position: "fixed",
          zIndex: 1101,
          width: { sm: drawerWidth() },
          display: "flex",
        }}
      >
        <Drawer
          variant="temporary"
          open={opened}
          onClose={() => setOpened(false)}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { sm: "block", md: "none" },
            "& .MuiDrawer-paper": {
              width: 256,
              bgcolor: "#FCFCFC",
            },
          }}
        >
          <Box
            sx={{
              height: 64,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <RenderToTitle collapsed={false} />
          </Box>
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          PaperProps={{ elevation: 1 }}
          sx={{
            display: { xs: "none", md: "block" },
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              bgcolor: "#FCFCFC",
              overflow: "hidden",
              transition: "width 200ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
            },
          }}
          open
        >
          <Box
            sx={{
              height: 64,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <RenderToTitle collapsed={collapsed} />
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              overflowX: "hidden",
              overflowY: "auto",
            }}
          >
            {drawer}
          </Box>
          <Button
            sx={{
              background: "#475BE8",
              color: "primary.contrastText",
              textAlign: "center",
              borderRadius: 0,
              borderTop: "1px solid #ffffff1a",
                '&:hover': {
                  background: "#1E36E8"
                }
            }}
            fullWidth
            size="large"
            onClick={() => setCollapsed((prev) => !prev)}
          >
            {collapsed ? <ChevronRight /> : <ChevronLeft />}
          </Button>
        </Drawer>
        <Box
          sx={{
            display: { xs: "block", md: "none" },
            position: "fixed",
            top: "64px",
            left: "0px",
            borderRadius: "0 6px 6px 0",
            bgcolor: "#475BE8",
            zIndex: 1199,
            width: "36px",
          }}
        >
          <IconButton
            sx={{ color: "#fff", width: "36px" }}
            onClick={() => setOpened((prev) => !prev)}
          >
            <MenuRounded />
          </IconButton>
        </Box>
      </Box>
    </>
  );
};
