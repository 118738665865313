import React from 'react';
import { Box, Link, Stack, styled, Typography, useMediaQuery } from '@mui/material';
import { Email } from '@mui/icons-material';
import { logoCollapsed } from '../../../assets';
import { useTranslate } from '@refinedev/core';

const StyledLink = styled('a')({
    textDecoration: 'none',
    color: '#11142D',
    '&:visited': {
        color: 'inherit', // Visited state
    },
});

export const Footer = () => {
    const translate = useTranslate();
    return (
        <Box bgcolor="#FFF">
            <Box display="flex" justifyContent="space-between" alignItems="center" p={0.5}>
                {useMediaQuery('(min-width: 550px)') && (
                    <Stack direction="row" alignItems="center" gap={1}>
                        <Email />
                        <Typography fontSize={14} fontWeight={500} color="#11142D" sx={{ '&:hover': { fontWeight: "600" } }}>
                            <a href="mailto:support@braudeoverflow.live" style={{ textDecoration: "none", color: "inherit" }}>
                                {translate("footer.supportemail", "Support Email")}
                            </a>
                        </Typography>
                    </Stack>
                )}
                <Stack direction="row" alignItems="center" gap={1} mr={20}>
                    <Link href="/">
                        <img src={logoCollapsed} alt="BraudeOverflow" width="24px" />
                    </Link>
                    <Typography fontSize={14} fontWeight={500} color="#11142D" pb={0.75 }>
                        BraudeOverflow &#169; {new Date().getFullYear()}
                    </Typography>
                </Stack>
                <Stack justifyContent="center" alignItems="center" paddingRight={3}>
                    <Typography
                        fontSize={14}
                        fontWeight={500}
                        color="#11142D"
                        sx={{
                            '&:hover': {
                                fontWeight: "600",
                                cursor: "pointer"
                            }
                        }}
                    >
                        <StyledLink
                            href="https://form.jotform.com/231245018982052"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {translate("footer.reportbug", "Report A Bug")}
                        </StyledLink>
                    </Typography>
                </Stack>
            </Box>
        </Box>
    );
}

export const LandingFooter = () => {
    const translate = useTranslate();
    return (
        <Box bgcolor="inherit">
            <Box display="flex" justifyContent="space-between" alignItems="center">
                {useMediaQuery('(min-width: 600px)') && (
                    <Stack direction="row" alignItems="center" gap={1}>
                        <Typography fontSize={14} fontWeight={500} color="#FFFFFF" pb={0.75}>
                            {translate("footer.braudeassociation", "This website has no association with Braude and is a personal project.")}
                        </Typography>
                    </Stack>
                )}
                <Stack direction="row" alignItems="center" gap={1} mr={30}>
                    <Link href="/">
                        <img src={logoCollapsed} alt="BraudeOverflow" width="24px" />
                    </Link>
                    <Typography fontSize={14} fontWeight={500} color="#FFFFFF" pb={0.75}>
                        BraudeOverflow &#169; {new Date().getFullYear()}
                    </Typography>
                </Stack>
                <Stack justifyContent="center" alignItems="center" paddingRight={3}>
                    <Typography
                        fontSize={14}
                        fontWeight={500}
                        color="#11142D"
                        sx={{
                            '&:hover': {
                                fontWeight: "600",
                                cursor: "pointer"
                            }
                        }}
                    >
                        <StyledLink
                            href="https://form.jotform.com/231245018982052"
                            style={{ color: "#FFFFFF" }}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {translate("footer.reportbug", "Report A Bug")}
                        </StyledLink>
                    </Typography>
                </Stack>
            </Box>
        </Box>
    );
}
