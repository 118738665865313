import React, { useState } from 'react';
import { useDataProvider, useLink, useGetIdentity } from '@refinedev/core';
import {
    AddLink,
    AddTask,
    Close,
    Delete,
    Edit,
    RotateLeft,
    TaskAlt,
} from '@mui/icons-material';
import { Box, Stack, IconButton, Typography, Divider, TextField } from '@mui/material';
import HUserButton from "../common/HUserButton";
import SubTaskItem from "./SubTaskItem";
import CollaboratorsItem from "./CollaboratorsItem";
import { axios } from "../../App";
import { AddSubTaskForm, CustomButton } from "../index";

function ExpandedTaskItem({ task, handleCompleteTask, handleRevertTaskStatus, handleDeleteTask, handleEditTask, handleEditTaskForm, setToEdit, updatedTask, setUpdatedTask }: any) {
    const [collaborators, setCollaborators] = useState<any>(task.collaborators ?? []);
    const [subTasks, setSubTasks] = useState<any>(task.subtasks ?? []);
    const [subtaskToAdd, setSubtaskToAdd] = useState<any>({ subTitle: '', subDescription: '', subUrlAttachment: '' });
    const [urlAttachments, setUrlAttachments] = useState<any>(task.urlAttachments ?? []);
    const [urlToAdd, setUrlToAdd] = useState<string>('');
    const [subtaskError, setSubtaskError] = useState<string>('');
    const [updateSubTaskError, setUpdateSubTaskError] = useState<string>('');
    const { data } = useGetIdentity();
    const profile: any = data ?? [];
    const Link = useLink();
    const dataProvider = useDataProvider();

    const handleAddCollaborator = (collaborator: any) => {
        const addCollab = async () => {
            await axios.post(`${dataProvider().getApiUrl()}/applications/todo/${task._id}/invite/${collaborator._id}`);
            const collabToAdd = {
                user: collaborator,
                pendingInvite: true
            };
            setCollaborators([...collaborators, collabToAdd]);
        };
        if (profile.userId === collaborator._id) return;
        if (profile.userId !== task.taskOwner._id) return;
        addCollab();
    };

    const handleRemoveCollaborator = (collaborator: any) => {
        const removeCollab = async () => {
            await axios.delete(`${dataProvider().getApiUrl()}/applications/todo/${task._id}/remove/${collaborator.user._id}`);
            setCollaborators(collaborators.filter((collab) => collab.user._id !== collaborator.user._id));
        };

        removeCollab();
    };

    const handleAddSubTask = () => {
        const addSubtask = async () => {
            const response = await axios.post(`${dataProvider().getApiUrl()}/applications/todo/subtask/${task._id}`, {
                subtask: subtaskToAdd
            });
            setUpdatedTask({...updatedTask, subtasks: [...subTasks, response.data]});
            setSubTasks(subTasks => [...subTasks, response.data]);
        };

        if (subtaskToAdd.subTitle === '') {
            setSubtaskError('Please enter a title for the subtask');
            return;
        }
        setSubtaskError('');
        setSubtaskToAdd({ subTitle: '', subDescription: '', subUrlAttachment: '' });

        addSubtask();
    };

    const handleEditSubTask = (updatedSubTask, index) => {
        if (updatedSubTask.subTitle === '') {
            setUpdateSubTaskError('Please enter a title for the subtask');
            return;
        }

        const updateSubTask = async () => {
            const response = await axios.patch(`${dataProvider().getApiUrl()}/applications/todo/${task._id}/subtask/${subTasks[index]._id}`, {
                updatedSubTask
            });

            setUpdateSubTaskError('');
            const newSubTasks = [...subTasks];
            newSubTasks[index] = response.data;
            setSubTasks(newSubTasks);
        };

        updateSubTask();
    };

    const handleDeleteSubTask = (subtask) => {
        const deleteSubTask = async () => {
            await axios.delete(`${dataProvider().getApiUrl()}/applications/todo/${task._id}/subtask/${subtask._id}`);
            setSubTasks(subTasks.filter((st) => st._id !== subtask._id));
        };

        deleteSubTask();
    };

    const handleCompleteST = (subtask) => {
        const completeSubTask = async () => {
            await axios.post(`${dataProvider().getApiUrl()}/applications/todo/${task._id}/subtask/${subtask._id}`);
            setSubTasks((prevSubTasks) => {
                return prevSubTasks?.map(st =>
                    st._id === subtask._id
                        ? { ...st, subCompleted: true }
                        : st
                );
            });
        };

        completeSubTask();
    };

    const handleUncompleteST = (subtask) => {
        const uncompleteSubTask = async () => {
            await axios.post(`${dataProvider().getApiUrl()}/applications/todo/${task._id}/subtask/revert/${subtask._id}`);
            setSubTasks((prevSubTasks) => {
                return prevSubTasks?.map(st =>
                    st._id === subtask._id
                        ? { ...st, subCompleted: false }
                        : st
                );
            });
        };
        uncompleteSubTask();
    };

    return (
        <Box
            width="100%"
            minHeight="30vh"
            border="1px solid #E0E0E0"
            display="flex"
            justifyContent="space-between"
            gap={3}
            pt={1}
        >
            <Box width="35%" pl={1} pr={1}>
                <Stack direction="row" flexDirection="row" flexWrap="nowrap" gap={1} alignItems="center">
                    <Typography fontSize={16} fontWeight={500} color="#11142D">
                        Task Issuer:
                    </Typography>
                    <HUserButton fullName={task.taskOwner.fullName} avatar={task.taskOwner.avatar} />
                </Stack>
                <Stack direction="column" mt={3} gap={1}>
                    <Typography fontSize={16} fontWeight={500} color="#11142D">
                        Description:
                    </Typography>
                    {setToEdit ? (
                        <TextField
                            id="outlined-multiline-static"
                            type="text"
                            placeholder="Enter task description..."
                            style={{
                                width: "100%",
                                border: "none",
                                outline: "none",
                                backgroundColor: "transparent",
                                color: "#11142D",
                                fontSize: "16px",
                                fontWeight: 500,
                                pointerEvents: "auto",
                            }}
                            multiline
                            color="info"
                            rows={3}
                            defaultValue={task.description}
                            value={updatedTask.description}
                            onChange={(e) => setUpdatedTask({...updatedTask, description: e.target.value})}
                            onKeyDown={(e) => {
                                e.stopPropagation()
                            }}
                            onClick={(e) => {
                                e.stopPropagation()
                            }}
                        />
                    ) : (
                        <Typography fontSize={14} color="#11142D">{task.description}</Typography>
                    )}
                </Stack>
                <Stack direction="row" flexDirection="row" flexWrap="nowrap" mt={3} alignItems="center" gap={1}>
                    <Typography fontSize={16} fontWeight={500} color="#11142D">
                        Created At:
                    </Typography>
                    <Typography fontSize={14} color="#11142D">{new Date(task.createdAt).toDateString()}</Typography>
                </Stack>
                <Stack direction="row" flexDirection="row" flexWrap="wrap" mt={4} alignItems="center" gap={1}>
                    <Typography fontSize={16} fontWeight={500} color="#11142D">
                        Link Attachments:
                    </Typography>
                    <Stack direction="column" flexDirection="column" flexWrap="wrap" gap={2} width="100%">
                        {urlAttachments?.map((url) =>
                            <Typography
                                sx={{
                                    '&:hover': {
                                        textDecoration: 'underline',
                                    }
                                }}
                            >
                                {setToEdit && (
                                    <IconButton onClick={() => {
                                        urlAttachments?.splice(urlAttachments.indexOf(url), 1);
                                        setUrlAttachments(urlAttachments);
                                        setUpdatedTask({...updatedTask, urlAttachments: urlAttachments});
                                    }}>
                                        <Delete />
                                    </IconButton>
                                )}
                                <Link
                                    to={url}
                                    target="_blank"
                                    style={{
                                        textDecoration: 'none',
                                        color: '#11142D',
                                        marginLeft: "7px",
                                    }}
                                >
                                    {url}
                                </Link>
                            </Typography>
                        )}
                    </Stack>
                    {setToEdit && (
                        <Stack direction="row" flexDirection="row" flexWrap="nowrap">
                            <TextField
                                color="info"
                                id="standard-basic"
                                variant="standard"
                                type="text"
                                placeholder="Enter Link Attachment"
                                value={urlToAdd}
                                onChange={(e) => setUrlToAdd(e.target.value)}
                                onKeyDown={(e) => {
                                    e.stopPropagation()
                                }}
                            />
                            <IconButton onClick={() => {
                                if (urlToAdd === '') return;
                                urlAttachments?.push(urlToAdd);
                                setUrlAttachments(urlAttachments);
                                setUpdatedTask({...updatedTask, urlAttachments: urlAttachments});
                                setUrlToAdd('');
                            }}>
                                <AddLink />
                            </IconButton>
                        </Stack>
                    )}
                </Stack>
            </Box>
            <Divider orientation="vertical" flexItem style={{ backgroundColor: "#E0E0E0" }} />
            <Box width="65%">
                <Stack direction="row" flexDirection="row" flexWrap="nowrap" justifyContent="space-between" alignItems="center" width="100%">
                    <CollaboratorsItem
                        collaborators={collaborators}
                        isTaskOwner={profile.userId === task.taskOwner._id}
                        addCollaborator={handleAddCollaborator}
                        removeCollaborator={handleRemoveCollaborator}
                    />
                    <Stack direction="row" flexDirection="row" flexWrap="nowrap" gap={2} alignItems="center" display="flex" justifyContent="flex-end" pr={2} minWidth="60%">
                        {!setToEdit && (
                            <IconButton onClick={handleDeleteTask}>
                                <Delete />
                            </IconButton>
                        )}
                        <Stack direction="row" flexDirection="row" flexWrap="wrap" alignItems="center" display="flex" justifyContent="flex-end" pr={2}>
                            {!task.completed && (
                                <>
                                    <IconButton disableRipple onClick={() => handleEditTaskForm(false)} sx={{ marginRight: "10px" }}>
                                        {setToEdit ? <Close /> : <Edit />}
                                    </IconButton>
                                    {setToEdit && (
                                        <CustomButton
                                            type = "submit"
                                            title="Save"
                                            backgroundColor="#475BE8"
                                            color="#FCFCFC"
                                            handleClick={() => handleEditTask(updatedTask)}
                                            icon = {<AddTask />}
                                        />
                                    )}
                                </>
                            )}
                            {task.completed ? (
                                <IconButton onClick={handleRevertTaskStatus}>
                                    <RotateLeft/>
                                </IconButton>
                            ) : (
                                !setToEdit && (
                                    <IconButton onClick={handleCompleteTask}>
                                        <TaskAlt/>
                                    </IconButton>
                                )
                            )}
                        </Stack>
                    </Stack>
                </Stack>
                <Divider orientation="horizontal" flexItem style={{ backgroundColor: "#E0E0E0", marginTop: "10px" }} />
                <Stack direction="column" flexDirection="column" flexWrap="nowrap" gap={2} alignItems="center" pr={2} width="100%">
                    <Stack direction="row" flexWrap="nowrap" gap={2} alignItems="center" justifyContent="flex-start" width="100%" mt={2}>
                        <Box flexGrow={1} />
                        <Typography fontSize={20} fontWeight={500} color="#11142D">
                            Sub Tasks
                        </Typography>
                        <Box flexGrow={1} />
                    </Stack>
                    <Stack direction="row" flexDirection="row" flexWrap="wrap" justifyContent="flex-start" width="100%" mb={2} gap={3}>
                        {subTasks.map((subtask: any, index: number) => (
                            <SubTaskItem
                                subtask={subtask}
                                index={index + 1}
                                stream="expandedTask"
                                handleEditSubTask={handleEditSubTask}
                                updateSubTaskError={updateSubTaskError}
                                handleDeleteSubTask={handleDeleteSubTask}
                                handleCompleteST={handleCompleteST}
                                handleUncompleteST={handleUncompleteST}
                            />
                        ))}
                        <AddSubTaskForm
                            subtaskToAdd={subtaskToAdd}
                            subtaskError={subtaskError}
                            setSubtaskToAdd={setSubtaskToAdd}
                            handleAddSubTask={handleAddSubTask}
                        />
                    </Stack>
                </Stack>
            </Box>
        </Box>
    );
}

export default ExpandedTaskItem;
