import React, { useEffect, useState } from 'react';
import { TitleProps, useDataProvider, useLink, useTranslate } from '@refinedev/core';
import { Box, Button, Typography } from '@mui/material';
import { logoCollapsed, logoNonCollapsed } from '../../../assets/';
import { SupervisorAccount } from '@mui/icons-material';
import { loggedInUserRole } from '../../../App';

export const Title: React.FC<TitleProps> = ({ collapsed }) => {
  const Link = useLink();
  const dataProvider = useDataProvider();
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  useEffect(() => {
    if (loggedInUserRole >= 2)
      setIsAdmin(true);
  }, [dataProvider]);

  const translate = useTranslate();

  return (
    <Button fullWidth variant="text" disableRipple>
        {!isAdmin ? (
            <Link to="/dashboard">
                {collapsed ? (
                    <img src={ logoCollapsed } alt="BraudeOverflow" width="28px" />
                ) : (
                    <Box mt={2} sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                    }}>
                        <img src={ logoNonCollapsed } alt="BraudeOverflow" height="70px" width="140px" />
                    </Box>
                )}
            </Link>
        ) : (
            <Link to="/dashboard">
              {collapsed ? (
                  <SupervisorAccount sx={{ height: "60px", width: "130px", textDecoration: "none", color: "#8C0D0D" }} />
              ) : (
                  <Box
                      mt={2}
                      sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                      }}
                  >
                      <>
                        <SupervisorAccount sx={{ height: "70px", width: "140px", textDecoration: "none", color: "#8C0D0D" }} />
                        <Typography fontSize={16} fontWeight={600} color="#11142D"> {translate("sider.admindashboard", "Admin Dashboard")} </Typography>
                      </>
                  </Box>
              )}
            </Link>
        )}

    </Button>
  );
};
