import React, { useMemo, useState } from 'react';
import { useGetIdentity, useGetLocale, useTable, useTranslate } from '@refinedev/core';
import { Autocomplete, Box, Button, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import { CustomButton, TutorDetailsCard } from '../components';
import { useNavigate } from 'react-router-dom';
import { UserStorage } from '../interfaces/common';
import { CourseObjectProps } from '../interfaces/apps';

function PrivateTutors() {
    const [courses] = useState<CourseObjectProps[]>(
        JSON.parse(localStorage.getItem('courses') || '[]')
    );
    const { data } = useGetIdentity<UserStorage>();

    const userProfile: any = data ?? [];
    const navigate = useNavigate();
    const translate = useTranslate();
    const locale = useGetLocale();
    const currDir = locale() === 'en' ? 'ltr' : 'rtl';

    const {
        tableQueryResult: { data: tableData },
        current,
        setCurrent,
        setPageSize,
        pageCount,
        sorters,
        setSorters,
        filters,
        setFilters,
    } = useTable({
        resource: `applications/private-tutors/`,
        syncWithLocation: false,
        pagination: {
            pageSize: 5
        }
    });

    const tutorsToDisplay = tableData?.data ?? [];

    // const currentPrice = sorters.find((item) => item.field === 'price')?.order;

    const currentFilterValues = useMemo(() => {
        const logicalFilters = filters.flatMap((item) => (
            'field' in item ? item : []
        ));

        return {
            name: logicalFilters.find((item) => item.field === 'name')?.value || '',
            course: logicalFilters.find((item) => item.field === 'course')?.value || '',
            department: logicalFilters.find((item) => item.field === 'department')?.value || '',
            gender: logicalFilters.find((item) => item.field === 'gender')?.value || '',
        }
    }, [filters]);

    // const toggleSort = (field: string) => {
    //     setSorters([{ field, order: currentPrice === 'asc' ? 'desc' : 'asc' }]);
    // }
    const departments = {
        [translate("register-page.depsoft", "Software Engineering")]: "Software Engineering",
        [translate("register-page.depdata", "Data Systems Engineering")]: "Data Engineering",
        [translate("register-page.depmech", "Mechanical Engineering")]: "Mechanical Engineering",
        [translate("register-page.depelec", "Electrical Engineering")]: "Electrical Engineering",
        [translate("register-page.depbio", "Bio-technology Engineering")]: "Bio-technology",
        [translate("register-page.depindus", "Industrial Engineering")]: "Industrial Engineering",
        [translate("register-page.depcivil", "Civil Engineering")]: "Civil Engineering",
        [translate("register-page.depopt", "Optical Engineering")]: "Optical Engineering",
        [translate("register-page.depmath", "Applied Mathematics")]: "Applied Mathematics",
        [translate("register-page.depsoftmath", "Applied Mathematics & Software Engineering")]: "Applied Mathematics And Software Engineering",
        [translate("register-page.depelecmath", "Applied Mathematics & Electrical Engineering")]: "Applied Mathematics And Electrical Engineering",
    };

    const genders = {
        [translate("register-page.male", "Male")]: "Male",
        [translate("register-page.female", "Female")]: "Female"
    };

    const departmentKeys = Object.keys(departments);
    const genderKeys = Object.keys(genders);

    return (
        <Box>
            {userProfile.role % 2 === 0 && (
                <Box
                    sx={{
                        backgroundColor: "secondary.main",
                        color: "common.white",
                        p: 2,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                    dir={currDir}
                >
                    <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                        {translate("privatetutors-page.interested", "Are you interested in listing yourself as a tutor?")}
                    </Typography>
                    <Button
                        variant="contained"
                        sx = {{
                            color: "#FCFCFC",
                            backgroundColor: "#475BE8",
                            '&:hover': {
                                opacity: 0.8,
                                transition: 'opacity 0.2s ease-in-out',
                                backgroundColor: '#475BE8',
                            }
                        }}
                        onClick={() => {
                            navigate("/my-profile?tutorGuide=true");
                        }}
                    >
                        {translate("privatetutors-page.tutorButton", "Become A Tutor")}
                    </Button>
                </Box>
            )}
            <Box mt="20px" sx={{ display: "flex", flexWrap: "wrap", gap: 3}} mb={5}>
                <Stack direction="column" width="100%" dir={currDir}>
                    <Typography fontSize={25} fontWeight={700} color="#11142D">
                        {!tutorsToDisplay.length ? translate("privatetutors-page.noTutors", "No Tutors Found") : translate("privatetutors-page.allTutors", "All Available Tutors")}
                    </Typography>
                    <Box mb={2} mt={3} flexWrap="wrap" alignItems="center">
                        <Box display="flex" gap={2} alignItems="center" flexWrap="wrap" mb={{ xs: '20px', sm: 0}}>
                            {/*<CustomButton*/}
                            {/*    title={`Sort by price ${currentPrice === 'asc' ? '↑' : '↓'}`}*/}
                            {/*    handleClick={() => toggleSort('price')}*/}
                            {/*    backgroundColor="#475BE8"*/}
                            {/*    color="#FCFCFC"*/}
                            {/*/>*/}
                            <TextField
                                variant="outlined"
                                color="info"
                                sx={{ width: '18%', minWidth: { xs: '75%', md: '18%', lg: '18%' } }}
                                placeholder={translate("privatetutors-page.tutorName", "Search by tutor name")}
                                value={currentFilterValues.name}
                                onChange={(e) => {
                                    setFilters([
                                        {
                                            field: 'name',
                                            operator: 'contains',
                                            value: e.currentTarget.value ? e.currentTarget.value : undefined
                                        }
                                    ])
                                }}
                            />
                            <Autocomplete
                                id="combo-box"
                                options={courses}
                                getOptionLabel={(course: CourseObjectProps) => `${course.name} ${course.id}`}
                                onChange={(event, selectedCourse) => {
                                    setFilters([
                                        {
                                            field: 'course_like',
                                            operator: 'eq',
                                            value: selectedCourse?._id || undefined
                                        }
                                    ])
                                }}
                                sx={{ width: '18%', minWidth: { xs: '75%', md: '18%', lg: '18%' } }}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        color="info"
                                        placeholder={translate("privatetutors-page.coursePlaceholder", "Search by course name or ID")}
                                        size="medium"
                                    />
                                }
                            />
                            <Autocomplete
                                id="combo-box"
                                options={departmentKeys}
                                getOptionLabel={(department: string) => department}
                                onChange={(event, selectedOption) => {
                                    const selectedDepartment = selectedOption ? departments[selectedOption] : '';
                                    setFilters([
                                        {
                                            field: 'department_like',
                                            operator: 'eq',
                                            value: selectedDepartment
                                        }
                                    ])
                                }}
                                sx={{ width: '18%', minWidth: { xs: '75%', md: '18%', lg: '18%' } }}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        color="info"
                                        placeholder={translate("privatetutors-page.departmentPlaceholder", "Filter by department")}
                                        size="medium"
                                    />
                                }
                            />
                            <Autocomplete
                                id="combo-box"
                                options={genderKeys}
                                getOptionLabel={(gender: string) => `${gender}`}
                                onChange={(event, selectedOption) => {
                                    const selectedGender = selectedOption ? genders[selectedOption] : '';
                                    setFilters([
                                        {
                                            field: 'gender_like',
                                            operator: 'eq',
                                            value: selectedGender
                                        }
                                    ])
                                }}
                                sx={{ width: '18%', minWidth: { xs: '75%', md: '18%', lg: '18%' } }}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        color="info"
                                        placeholder={translate("privatetutors-page.genderPlaceholder", "Filter by gender")}
                                        size="medium"
                                    />
                                }
                            />
                        </Box>
                    </Box>
                </Stack>
            </Box>
            <Box display="flex" flexWrap="wrap" gap={10} dir={currDir}>
                <Stack direction="row" flexWrap="wrap">
                    {tutorsToDisplay.map((tutor) => (
                        <Box mb={5} key={tutor.user.tutorId}>
                            <TutorDetailsCard
                                description={tutor.description}
                                coursesTaught={tutor.coursesTaught}
                                tutorProfile={tutor.user}
                            />
                        </Box>
                    ))}
                </Stack>
            </Box>
            {tutorsToDisplay.length > 0 && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'flex-end',
                        minHeight: userProfile.role === 0 ? '20vh' : '27vh',
                        flexWrap: 'wrap',
                        gap: 2,
                    }}
                    dir={locale() === "he" ? "rtl" : "ltr"}
                >
                    <CustomButton
                        title={translate("privatetutors-page.prevButton", "Previous")}
                        handleClick={() => setCurrent((prev) => prev - 1)}
                        backgroundColor="#475BE8"
                        color="#FCFCFC"
                        disabled={!(current > 1)}
                    />
                    <Box
                        sx={{
                            display: { xs: 'none', sm: 'flex' },
                            gap: 1,
                            marginBottom: 1.5,
                        }}
                        dir={locale() === "he" ? "rtl" : "ltr"}
                    >
                        {translate("privatetutors-page.page", "Page")}{' '}<strong>{current} {translate("privatetutors-page.ofP", "of")} {pageCount}</strong>
                    </Box>
                    <CustomButton
                        title={translate("privatetutors-page.nextButton", "Next")}
                        handleClick={() => setCurrent((prev) => prev + 1)}
                        backgroundColor="#475BE8"
                        color="#FCFCFC"
                        disabled={current === pageCount}
                    />
                    <Box
                        sx={{
                            display: { xs: 'none', sm: 'flex' },
                            gap: 1,
                        }}
                    >
                        <Select
                            variant="outlined"
                            color="info"
                            displayEmpty
                            required
                            inputProps={{ 'aria-label': 'Without label' }}
                            defaultValue={5}
                            onChange={(e) => {
                                setPageSize(e.target.value ? Number(e.target.value) : 5)
                            }}
                        >
                            {[5, 10, 15, 20, 25].map((size) => (
                                <MenuItem key={size} value={size}>{translate("privatetutors-page.show", "Show")} {size}</MenuItem>
                            ))}
                        </Select>
                    </Box>
                </Box>
            )}
        </Box>
    );
}

export default PrivateTutors;
