import React from 'react';
import { useGetLocale, useLink, useTranslate } from '@refinedev/core';
import { Box, Stack, Typography } from '@mui/material';

function MaintenancePage() {
    const translate = useTranslate();
    const locale = useGetLocale();
    const currentLocale = locale();
    const Link = useLink();
    const currDir = currentLocale === 'en' ? 'ltr' : 'rtl';
    return (
        <Box style={{ display: "flex" , flexDirection : "column", direction : currDir}}>
            <Stack direction="row" display="flex" justifyContent="center">
                <Typography fontSize={24} fontWeight={500} color="#11142D">{translate("maintenance")}</Typography>
            </Stack>
            <Typography fontSize={22} fontWeight={500} color="#11142D">{translate("maintenance2")}</Typography>
            <Typography fontSize={22} fontWeight={500} color="#11142D">{translate("maintenance3")}</Typography>
            <Typography fontSize={22} fontWeight={600} color="#11142">
                <Link to={"mailto:staff@braudeoverflow.live"} style={{ textDecoration: "none", color: "inherit" }}>
                  Staff@BraudeOverflow.live
                </Link>
            </Typography>
        </Box>
    );
}

export default MaintenancePage;
