import React from 'react';
import { AccessControlProvider, AuthBindings, Authenticated, CanAccess, Refine } from '@refinedev/core';
import { CssBaseline, GlobalStyles } from '@mui/material';
import routerProvider, {
  CatchAllNavigate,
  NavigateToResource,
  UnsavedChangesNotifier
} from '@refinedev/react-router-v6';

import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import { notificationProvider, RefineSnackbarProvider, ThemedLayoutV2 } from '@refinedev/mui';

/*i18n Provider*/
import { useTranslation } from 'react-i18next';

import { AccountCircleOutlined, Settings } from '@mui/icons-material';

import dataProvider from '@refinedev/simple-rest';
import axios, { AxiosInstance } from 'axios';
import { ColorModeContextProvider } from 'contexts';
import { parseJwt } from 'utils/parse-jwt';
import { Footer, Header, Sider, Title } from './components/layout';
import {
  AdminToolsPage,
  AllReportsPage,
  AllUsersPage,
  BannedUsersPage,
  Dashboard,
  EditProfile,
  ForgotPassword,
  IndexPage,
  Login,
  TaskManager,
  MainScheduler,
  MaintenancePage,
  ErrorPage,
  MyProfile,
  PrivateTutors,
  Register,
  ResetForgottenPassword,
  UserSettings,
  VerificationPage
} from './pages';
import { CredentialResponse } from './interfaces/google';

let userRole = 0;
let hasCheckedPatch = false;
const endpointAPI =
  process.env.NODE_ENV !== 'production'
    ? `http://localhost:${process.env.REACT_APP_HTTP_PORT}`
    : `${process.env.REACT_APP_ENDPOINT_URL}`;

const axiosInstance: AxiosInstance = axios.create({
  baseURL: endpointAPI,
  withCredentials: true
});

const refreshToken = async () => {
    const response = await axiosInstance.post(`${endpointAPI}/auth/refresh`);
    localStorage.setItem('token', response.data.token);
};

const forceLogOut = async () => {
  const googleToken = localStorage.getItem('googleToken');
  const user = localStorage.getItem('user');
  if (user) {
    await axiosInstance.post(`${endpointAPI}/auth/logout/${JSON.parse(user).userId}`);
  }

  if (googleToken && typeof window !== 'undefined') {
    axiosInstance.defaults.headers.common = {};
    window.google?.accounts.id.revoke(googleToken, () => {
      return {};
    });
    localStorage.removeItem('googleToken');
  }

  localStorage.removeItem('token');
  localStorage.removeItem('user');

  window.location.href = '/login';
}

const isAuthRelatedError = (error: any) => {
  const authPaths = ['auth/authenticate', 'auth/google-auth', 'auth/register', 'auth/reset-password', 'auth/logout'];
  const isAuth = (authPaths.some(path => error.response.request.responseURL.includes(path)))
  const passPath = error.response.request.responseURL === `${endpointAPI}/users` && error.config.method === 'patch';
  return isAuth || passPath;
};

axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  config.headers.Authorization =  token ? `Bearer ${token}` : '';
  return config;
});

axiosInstance.interceptors.response.use(
  (response) =>
    response,
  async (error) => {
    if (error.response) {
      if (isAuthRelatedError(error)) {
        return Promise.resolve(error.response);
      }
    }

    const config = error?.config;
    if (error?.response?.data?.message === 'Token expired' && !config?.sent) {
      config.sent = true;
      let retryCount = 0;

      while (retryCount < 3) {
        try {
          await refreshToken();
          return axiosInstance(config);
        } catch (error) {
          retryCount++;
        }
      }

      await forceLogOut();
    }
    return Promise.reject(error);
  }
);

async function fetchUserRole() {
  try {
    const response = await axiosInstance.get(`${endpointAPI}/users/role`);
    return response.data.role;
  } catch (err) {
    console.error(err);
    return 0;
  }
}

async function onStart() {
  hasCheckedPatch = true;
  const patch = JSON.parse(localStorage.getItem('patch') ?? JSON.stringify('0'));
  if (!patch || patch !== process.env.REACT_APP_PATCH) {
    await forceLogOut();
    localStorage.setItem('patch', JSON.stringify(process.env.REACT_APP_PATCH));
    return;
  }
}

const accessControlProvider: AccessControlProvider = {
  can: async ({ action, resource, params }) => {

    if (
      resource !== 'admin-control' ||
      userRole >= 2
    ) {
      return {
        can: true,
      };
    }

    return {
      can: false,
      reason: "Unauthorized",
    };
  },
};

function App() {
  const { t, i18n } = useTranslation();
  if (!hasCheckedPatch)
    onStart();

  const authProvider: AuthBindings = {
    login: async ({ providerName, credential, email, password }: CredentialResponse) => {
      if (providerName === 'Google') {
        // Google authentication via JWT token.
        const profileObj = credential ? parseJwt(credential) : null;
        if (profileObj) {
          const response = await axiosInstance.post(`${endpointAPI}/auth/google-auth`, {
            firstName: profileObj.given_name,
            lastName: profileObj.family_name,
            email: profileObj.email.toLowerCase(),
            avatar: profileObj.picture,
            registrationType: providerName,
            verified: true,
          });
          const data = response.data;

          if (response.status === 200) {
            localStorage.setItem(
              'user',
              JSON.stringify({
                originalAvatar: profileObj.picture,
                ...data.user
              })
            );
            localStorage.setItem('googleToken', `${credential}`);
            localStorage.setItem('token', `${data.token}`);
            userRole = data.user.role;
            return {
              success: true,
              redirectTo: "/dashboard",
            };
          } else {
            if (response.status === 403 && response.data.message.includes('Banned')) {
              return {
                success: false,
                error: new Error(response.data.message),
              }
            }
            return {
              success: false,
              error: new Error("Failed to authenticate Google account."),
            }
          }
        }
        // Website authentication.
      } else if (providerName === 'BraudeOverflow') {
        if (email && password) {
          const response = await axiosInstance.post(
            `${endpointAPI}/auth/authenticate`,
            {
              email: email.toLowerCase(),
              password: password,
            }
          );
          const data = response.data;

          if (response.status === 200) {
            localStorage.setItem(
              'user',
              JSON.stringify({
                originalAvatar: data.user.avatar,
                ...data.user,
              })
            );
            userRole = data.user.role;
          } else {
            if (response.status === 403 && response.data.message.includes('Banned')) {
              return {
                success: false,
                error: new Error("Banned"),
              }
            }
            return {
              success: false,
              error: new Error("Invalid Credentials"),
            }
          }

          localStorage.setItem('token', `${data.token}`);
          return {
            success: true,
            redirectTo: "/dashboard",
          }
        }
      }
      return {
        success: false,
        error: new Error("Failed to authenticate."),
      };
    },
    logout: async () => {
      const googleToken = localStorage.getItem('googleToken');
      const user = localStorage.getItem('user');
      if (user) {
        await axiosInstance.post(`${endpointAPI}/auth/logout/${JSON.parse(user).userId}`);
      }

      if (googleToken && typeof window !== 'undefined') {
        axiosInstance.defaults.headers.common = {};
        window.google?.accounts.id.revoke(googleToken, () => {
          return {};
        });
        localStorage.removeItem('googleToken');
      }

      localStorage.removeItem('user');
      localStorage.removeItem('token');

      return {
        success: true,
        redirectTo: "/login",
      };
    },
    register: async ({
      firstName,
      lastName,
      email,
      avatar,
      password,
      gender,
      phone,
      dateOfBirth,
      degreeDepartment,
    }) => {
      if (email && firstName && lastName && password) {
        const response = await axiosInstance.post(`${endpointAPI}/auth/register`, {
          firstName,
          lastName,
          email: email.toLowerCase(),
          password,
          avatar: avatar || '',
          gender: gender ?? '',
          phone: phone ?? '',
          dateOfBirth: dateOfBirth ?? '',
          degreeDepartment: degreeDepartment ?? '',
          registrationType: 'BraudeOverflow',
          verified: false,
        });
        if (response.status === 200) {
          return {
            success: true,
          };
        } else if (response.status === 404 && response.data.message.includes('already exists')) {
          return {
            success: false,
            error: new Error("User already exists"),
          }
        }
        return {
          success: false,
          error: new Error("Failed to create user."),
        };
      }
      return {
        success: false,
        error: new Error("Requirements for user creation missing."),
      };
    },
    updatePassword: async ({ currentPassword, password, confirmPassword }) => {
      const response = await axiosInstance.patch(`${endpointAPI}/users`, {
        currentPassword: currentPassword,
        newPassword: password,
        newPasswordConfirmation: confirmPassword,
      });
      if (response.status === 200) {
        return {
          success: true,
          redirectTo: "/dashboard",
        };
      } else if (response.status === 401 && response.data.message.includes('Incorrect password')) {
        return {
          success: false,
          error: new Error("Invalid password"),
        }
      }
      return {
        success: false,
      };
    },
    forgotPassword: async ({ email }) => {
      const response = await axiosInstance.post(`${endpointAPI}/auth/reset-password`, {
        email: email.toLowerCase(),
      });

      if (response.status === 200)
        return {
          success: true,
        };

      return {
        success: false,
        error: {
          name: "Forgot Password Error",
          message: "Invalid email address",
        },
      };
    },
    onError: async (error) => {
      console.error(error);
      return { error };
    },
    check: async () => {
      const googleToken = localStorage.getItem("googleToken");
      const token = localStorage.getItem("token");

      if (googleToken || token) {
        userRole = await fetchUserRole();
        return {
          authenticated: true,
        };
      }
      return {
        authenticated: false,
        redirectTo: "/login",
        logout: true,
        error: new Error("Not authenticated."),
      };
    },

    getPermissions: async () => null,
    getIdentity: async () => {
      const user = localStorage.getItem("user");
      if (user) return JSON.parse(user);
      return null;
    },
  };

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  const resourcesList = [
    {
      name: 'admin-control',
      list: '/admin-control/users',
      show: '/admin-control/banned-users',
      clone: '/admin-control/admin-tools',
      edit: '/admin-control/escans-reports',
      meta: {
        label: "Admin Panel",
        hide: true,
      },
    },
    {
      name: 'dashboard',
      list: '/dashboard',
      meta: {
        label: t('sider.dashboard', 'Dashboard'),
      },
    },
    {
      name: 'my-profile',
      list: '/my-profile',
      edit: '/my-profile/edit',
      meta: {
        label: t('sider.myprofile', 'My Profile'),
        icon: <AccountCircleOutlined />,
      },
    },
    {
      name: 'private-tutors',
      list: '/private-tutors',
      meta: {
        hide: true,
      },
    },
    {
      name: 'settings',
      list: '/settings',
      meta: {
        label: t('sider.settings', 'Settings'),
        icon: <Settings />,
      },
    },
  ];

  return (
    <BrowserRouter>
      <ColorModeContextProvider>
        <CssBaseline />
        <GlobalStyles styles={{ html: { WebkitFontSmoothing: 'auto' } }} />
        <RefineSnackbarProvider>
            <Refine
              dataProvider={dataProvider(endpointAPI, axiosInstance)}
              notificationProvider={notificationProvider}
              routerProvider={routerProvider}
              authProvider={authProvider}
              accessControlProvider={accessControlProvider}
              i18nProvider={i18nProvider}
              resources={resourcesList}
              options={{
                syncWithLocation: true,
                warnWhenUnsavedChanges: true
              }}
            >
              <Routes>
                {/* Landing Page */}
                <Route>
                  <Route index path="/" element={<IndexPage />} />
                </Route>

                {/* Register Page */}
                <Route path="/register">
                  <Route index element={<Register />} />
                  <Route path="verification" element={<VerificationPage />} />
                </Route>

                {/* Forgot Password Page */}
                <Route path="/reset-password">
                  <Route index element={<ForgotPassword />} />
                  <Route path="update" element={<ResetForgottenPassword />} />
                </Route>

                {/* Admin Control Routes */}
                <Route
                  element={
                    <Authenticated
                      key="admin-authenticated-routes"
                      fallback={<CatchAllNavigate to="/login" />}
                     v3LegacyAuthProviderCompatible
                    >
                      <CanAccess
                          resource="admin-control"
                          action="list"
                          fallback={<div>Unauthorized access!</div>}>
                        <ThemedLayoutV2
                          Header={Header}
                          Title={Title}
                          Footer={Footer}
                          Sider={Sider}
                        >
                            <Outlet />
                        </ThemedLayoutV2>
                      </CanAccess>
                    </Authenticated>
                  }
                >
                  <Route path="/admin-control">
                      <Route
                          index
                          path="users"
                          element={<AllUsersPage />}
                      />
                      <Route
                          path="admin-tools"
                          element={<AdminToolsPage />}
                      />
                      <Route
                          path="escans-reports"
                          element={<AllReportsPage />}
                      />
                      <Route
                          path="banned-users"
                          element={<BannedUsersPage />}
                      />
                    </Route>
                  </Route>
                {/*Routes that require authentication.*/}
                {/* Scheduler */}
                <Route
                  element={
                    <Authenticated
                      key="scheduler-authenticated-routes"
                      fallback={<CatchAllNavigate to="/login" />}
                      v3LegacyAuthProviderCompatible
                    >
                      <Outlet />
                    </Authenticated>
                  }
                >
                  <Route path="/scheduler">
                    <Route index element={<MainScheduler />} />
                  </Route>
                </Route>
                <Route
                  element={
                    <Authenticated
                      key="dashboard-authenticated-routes"
                      fallback={<CatchAllNavigate to="/login" />}
                      v3LegacyAuthProviderCompatible
                    >
                      <ThemedLayoutV2
                        Header={Header}
                        Title={Title}
                        Footer={Footer}
                        Sider={Sider}
                      >
                        <Outlet />
                      </ThemedLayoutV2>
                    </Authenticated>
                  }
                >
                  <Route
                    index
                    element={<NavigateToResource resource="dashboard" />}
                  />
                  <Route path="/dashboard">
                    <Route index element={<Dashboard />} />
                  </Route>
                  <Route path="/my-profile">
                    <Route index element={<MyProfile />} />
                    <Route path="edit" element={<EditProfile />} />
                  </Route>
                  <Route path="/private-tutors">
                    <Route index element={<PrivateTutors />} />
                  </Route>
                  <Route path="/escans">
                    <Route index element={<MaintenancePage />} />
                  </Route>
                  <Route path="/task-manager">
                    <Route index element={<TaskManager />} />
                  </Route>
                  <Route path="/settings">
                    <Route index element={<UserSettings />} />
                  </Route>
                </Route>


                {/* Login Page*/}
                <Route
                  element={
                    <Authenticated
                      key="login-authenticated-route"
                      fallback={<Outlet />}
                      v3LegacyAuthProviderCompatible
                    >
                      <NavigateToResource resource="dashboard" />
                    </Authenticated>
                  }
                >
                  <Route path="/login" element={<Login />} />
                </Route>

                {/*Authenticated rendering of children routes with error component.*/}
                <Route
                    element={
                      <Authenticated
                          key="error-authenticated-routes"
                          fallback={<CatchAllNavigate to="/login" />}
                          v3LegacyAuthProviderCompatible
                      >
                        <ThemedLayoutV2
                            Header={Header}
                            Title={Title}
                            Footer={Footer}
                            Sider={Sider}
                        >
                          <Outlet />
                        </ThemedLayoutV2>
                      </Authenticated>
                    }
                >
                  <Route path="*" element={<ErrorPage />} />
                </Route>
              </Routes>
              <UnsavedChangesNotifier />
            </Refine>
        </RefineSnackbarProvider>
      </ColorModeContextProvider>
    </BrowserRouter>
  );
}

export {
  App,
  axiosInstance as axios,
  userRole as loggedInUserRole,
};
