import { useState } from 'react';
import { useDataProvider, useGetIdentity } from '@refinedev/core';
import { axios } from '../App';
import { useForm } from '@refinedev/react-hook-form';
import { FieldValues } from 'react-hook-form';
import EditProfileForm from '../components/profile/EditProfileForm';
import dayjs, { Dayjs } from 'dayjs';
import { UserStorage } from '../interfaces/common';

function EditProfile() {
    const dataProvider = useDataProvider();
    const { data } = useGetIdentity<UserStorage>();
    const profile: any = data ?? [];

    const [BirthDate, setBirthDate] = useState<Dayjs | null>(dayjs(profile?.dateOfBirth ?? null))
    const [avatar, setAvatar] = useState({ name: profile.avatar.name, url: profile.avatar });

    const { refineCore: { onFinish, formLoading }, register, handleSubmit } = useForm({
        refineCoreProps: {
            resource: 'my-profile',
            action: 'edit',
        }
    });

    const handleAvatarChange = (file: File) => {
        const reader = (readFile: File) => new Promise<string>((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.onload = () => resolve(fileReader.result as string);
            fileReader.readAsDataURL(readFile);
        });

        reader(file).then((result: string) => setAvatar({ name: file?.name, url: result }));
    };

    const handleResetAvatar = () => {
        if (profile && profile.originalAvatar) {
            setAvatar({ name: 'DefaultAvatar', url: profile.originalAvatar });
        }
    }

    const handleDateChange = (date: Dayjs | null) => {
        setBirthDate(date);
    };

    const onFinishHandler = async (data: FieldValues) => {
        if (profile) {
            await onFinish({ ...data, dateOfBirth: dayjs(BirthDate).toDate(), avatar: avatar.url, email: profile.email });
        }
        const response = await axios.get(`${dataProvider().getApiUrl()}/users/`);
        const oldUserInfo = JSON.parse(localStorage.getItem('user') ?? '');
        let newUser = response.data;
        if (oldUserInfo?.originalAvatar) {
            newUser = {
                ...newUser,
                userId: oldUserInfo.userId,
                originalAvatar: oldUserInfo.originalAvatar
            }
        }
        localStorage.setItem('user', JSON.stringify(newUser));
    };

    return (
        <EditProfileForm
            register={register}
            onFinish={onFinish}
            formLoading={formLoading}
            handleSubmit={handleSubmit}
            handleAvatarChange={handleAvatarChange}
            handleResetAvatar={handleResetAvatar}
            handleDateChange={handleDateChange}
            dateToPass={BirthDate}
            onFinishHandler={onFinishHandler}
            avatar={avatar}
        />
    );
}

export default EditProfile;
