import React from 'react';
import { useDataProvider, useGetIdentity, useTranslate } from '@refinedev/core';
import { Box, Menu, MenuItem, Stack } from '@mui/material';
import { Delete, Edit, Report } from '@mui/icons-material';
import { axios } from '../../App';
import { UserStorage } from '../../interfaces/common';

function ExamSettingsMenu({ anchorEl, handleClose, handleReportForm, handleEditForm, isUsersExam, row, fetchExams }) {
    const dataProvider = useDataProvider();
    const translate = useTranslate();
    const { data } = useGetIdentity<UserStorage>();
    const profile: any = data ?? [];
    const isAdmin = profile.role > 1;

    const handleDeleteRow = async () => {
        try {
            handleClose();
            await axios.delete(`${dataProvider().getApiUrl()}/applications/escans/${row._id}`);
            await fetchExams();
            window.location.reload();
        } catch (err) {
            console.error(err);
        }
    }

    const handleReportProcess = () => {
        handleClose();
        handleReportForm();
    }

    const menuOpts = isUsersExam ? [
        <MenuItem
            onClick={handleEditForm}
            sx={{ display: "flex", alignItems: "center", gap: "5px" }}
        >
            <Edit fontSize="small" />
            {translate("escans-page.editButton", "Edit")}
        </MenuItem>,
        <MenuItem
            onClick={handleDeleteRow}
            sx={{ display: "flex", alignItems: "center", gap: "5px" }}
        >
            <Delete fontSize="small" />
            {translate("escans-page.deleteButton", "Delete")}
        </MenuItem>
    ] : isAdmin ? [
        <MenuItem
            sx={{ display: "flex", alignItems: "center" }}
            onClick={handleReportProcess}
        >
            <Stack direction="row" justifyContent="center" alignItems="center" gap={1}>
                    <Report fontSize="small" />
                {translate("escans-page.reportButton", "Report")}
            </Stack>
        </MenuItem>,
        <MenuItem
            sx={{ display: "flex", alignItems: "center" }}
            onClick={handleDeleteRow}
        >
            <Stack direction="row" justifyContent="center" alignItems="center" gap={1}>
                <Delete fontSize="small" />
                {translate("escans-page.deleteButton", "Delete")}
            </Stack>
        </MenuItem>
    ] : [
        <MenuItem
            sx={{ display: "flex", alignItems: "center" }}
            onClick={handleReportProcess}
        >
            <Stack direction="row" justifyContent="center" alignItems="center" gap={1}>
                <Report fontSize="small" />
                {translate("escans-page.reportButton", "Report")}
            </Stack>
        </MenuItem>
    ];

    return (
        <Box>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                sx={{
                    maxHeight: 48 * 4.5,
                    width: "20ch",
                }}
            >
                {menuOpts.map((menu, index) => (
                    <Box key={index}>
                        {menu}
                    </Box>
                ))}
            </Menu>
        </Box>
    );
}

export default ExamSettingsMenu;
