import { Button } from '@mui/material';

import { CustomButtonProps } from 'interfaces/common';

const CustomButton = ({  type,
                          subtype,
                          title,
                          backgroundColor,
                          color,
                          fullWidth,
                          icon,
                          handleClick,
                          disabled,
                      } : CustomButtonProps) => {
    const isTerminate = subtype === 'terminate';
    return (
        <>
            {isTerminate ? (
                <Button
                    disabled={disabled}
                    type="button"
                    sx={{
                        flex: "unset",
                        padding: "10px 12px",
                        width: "fit-content",
                        minWidth: 130,
                        backgroundColor,
                        color,
                        fontSize: 16,
                        fontWeight: 600,
                        border: 1.5,
                        borderRadius: '5px',
                        borderColor: disabled ? '#626161' : color,
                        textTransform: "capitalize",
                        "&:hover": {
                            backgroundColor: color,
                            color: backgroundColor,
                            borderColor: backgroundColor,
                        },
                    }}
                    onClick={handleClick}
                >
                    {icon}
                    {title}
                </Button>
                ) : (
                <Button
                    disabled={disabled}
                    type={type === "submit" ? "submit" : "button"}
                    sx={{
                        flex: fullWidth ? 1 : "unset",
                        padding: backgroundColor === "inherit" ? "0" : "10px 15px", // Profile Edit Button is the only button that has inherited background color.
                        width: fullWidth ? "100%" : "fit-content",
                        minWidth: backgroundColor === "inherit" ? 50 : 130, // Profile Edit Button is the only button that has inherited background color.
                        backgroundColor,
                        color,
                        fontSize: 16,
                        fontWeight: 600,
                        gap: "10px",
                        textTransform: "capitalize",
                        "&:hover": {
                            opacity: 0.9,
                            backgroundColor,
                        },
                    }}
                    onClick={handleClick}
                >
                    {icon}
                    {title}
                </Button>
                )}
        </>
    );
};

export default CustomButton;
