import { Box, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useDataProvider, useTranslate } from '@refinedev/core';
import { AppCard } from '../components';
import React, { useEffect, useState } from 'react';
import { fileScan, privateTutor, schedule, todoManager } from '../assets';
import { useLocation } from 'react-router-dom';
import { CheckCircle } from '@mui/icons-material';
import { Divider } from 'antd';
import { axios } from '../App';


function Dashboard() {
    const translate = useTranslate();
    const location = useLocation();
    const dataProvider = useDataProvider();
    const searchParams = new URLSearchParams(location.search);
    const verificationPopUp = searchParams.get('verification');
    const collabInvitation = searchParams.get('token');
    const showedNotification = sessionStorage.getItem('showNotification');
    const [openVerified, setOpenVerified] = useState<boolean>(verificationPopUp === 'true');
    const [openSuccessInvitation, setOpenSuccessInvitation] = useState<boolean>(false);
    const [openUpdatedPopUp, setOpenUpdated] = useState<boolean>(true);
    const [activeCardIndex, setActiveCardIndex] = useState(0);
    const [isHovered, setIsHovered] = useState(false);

    const cards = [
        {
            title: translate("dashboard.scheduler", "Scheduler"),
            pageLink: "scheduler",
            image: schedule,
            description:
                translate("dashboard.schedes", "This powerful scheduling tool allows you to plan your semester with ease and comfort in robustness. Build the best schedule you possibly can!"),
        },
        {
            title: translate("dashboard.privatetutors","Private Tutors"),
            pageLink: "private-tutors",
            image: privateTutor,
            description:
                translate("dashboard.prtutdes", "Connect with experienced and qualified tutors in your area. Whether you're a student looking for extra help, or a teacher looking to offer your services, this app has everything you need to succeed."),
        },
        {
            title: translate("dashboard.escans", "EScans"),
            pageLink: "escans",
            image: fileScan,
            description:
                translate("dashboard.escandes", "Learn from others' scanned exams in any course! Maximize your learning efficiency and inspect the detailed solutions to any exams."),
        },
        {
            title: translate("dashboard.todo", "Task List Manager"),
            pageLink: "task-manager",
            image: todoManager,
            description:
                translate("dashboard.tododesc", "Manage your tasks and to-do lists with ease. Invite fellow students as collaborators and complete your tasks together!"),
        },
    ];

    useEffect(() => {
        const isPatchAvailable = process.env.REACT_APP_COURSES_PATCH_AVAILABLE === 'true';
        async function fetchCourses() {
            const response = await axios.get(`${dataProvider().getApiUrl()}/courses/`);
            localStorage.setItem('courses', JSON.stringify(response.data));
        }

        async function fetchLecturers() {
            const response = await axios.get(`${dataProvider().getApiUrl()}/applications/escans/info`);
            localStorage.setItem('lecturers', JSON.stringify(response.data));
        }

        async function notificationPopUp() {
            if (!showedNotification) {
                sessionStorage.setItem('showNotification', "false");
            }
        }

        async function acceptCollab() {
            if (collabInvitation) {
                try {
                    const response = await axios.post(`${dataProvider().getApiUrl()}/applications/todo/accept/${collabInvitation}`);
                    if (response.status === 200) {
                        setOpenSuccessInvitation(true);
                    } else {
                        console.error('Invitation acceptance failed:', response.data.message);
                    }
                } catch (error) {
                    console.error('Error in acceptCollab:', error);
                }
            }
        }

        acceptCollab();

        notificationPopUp();

        if (!localStorage.getItem('courses') || isPatchAvailable) {
            fetchCourses();
        }

        if (!localStorage.getItem('lecturers') || isPatchAvailable) {
            fetchLecturers();
        }

        const intervalId = setInterval(() => {
            if (!isHovered) {
                setActiveCardIndex((prevIndex) =>
                    prevIndex === cards.length - 1 ? 0 : prevIndex + 1
                );
            }
        }, 10000);

        return () => clearInterval(intervalId);

    }, [dataProvider, isHovered, cards.length, showedNotification, collabInvitation]);

    const handleVerificationPopUp = () => {
        setOpenVerified(!openVerified);
    }
    const handleUpdatedPopUp = () => {
        sessionStorage.setItem('showNotification', "true");
        setOpenUpdated(!openUpdatedPopUp);
    }

    const languageDir = translate("header.lng", "he") === "he" ? "rtl" : "ltr";
    return (
        <Box>
            {/* Show notification only till November 2023, and once per session. */}
            <Dialog open={showedNotification === 'false' && openUpdatedPopUp && new Date().getMonth() < 10 && new Date().getFullYear() === 2023} onClose={handleUpdatedPopUp}>
                <DialogTitle>
                    <Typography display="flex" justifyContent="center" color="#11142D" fontSize={18} fontWeight={500}>
                        {translate("dashboard.updatetitle", "Update Notice")}
                    </Typography>
                </DialogTitle>
                <Divider style={{ margin: 0 }}></Divider>
                <DialogContent>
                    <Box mb={2} dir={languageDir}>
                        <Typography fontSize={18} fontWeight={500} color="#11142D">{translate("dashboard.updatecoursebody", "Schedule and courses have been updated for fall semester of 2024.")}</Typography>
                    </Box>
                </DialogContent>
            </Dialog>
            {/* Success Collaboration Pop up */}
            <Dialog open={openSuccessInvitation} onClose={() => setOpenSuccessInvitation(false)}>
                <DialogTitle>
                    <Typography display="flex" justifyContent="center" color="#11142D" fontSize={18} fontWeight={500}>
                        Collaboration Invitation
                    </Typography>
                </DialogTitle>
                <Divider style={{ margin: 0 }}></Divider>
                <DialogContent>
                    <Box mb={2} dir={languageDir}>
                        <Typography fontSize={18} fontWeight={500} color="#11142D">You are now a collaborator on the task you were invited to.</Typography>
                    </Box>
                </DialogContent>
            </Dialog>
            <Typography fontSize={25} fontWeight={700} color="#11142D" dir={languageDir}>
                {translate("header.dashboard", "Dashboard")}
            </Typography>
            <Box
                flex={1}
                display="flex"
                alignItems="center"
                flexDirection="column"
                minWidth="10%"
                mt="25px"
            >
                <Typography fontSize={22} fontWeight={600} color="#11142D">{translate("dashboard.apps", "Applications")}</Typography>
            </Box>
            <Box
                mt={4}
                pl={5}
                pr={5}
                gap={5}
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                    flexDirection: "row",
                }}
            >
                {cards.map((card, index) => (
                    <Box
                        key={card.pageLink}
                        sx={{
                            minWidth: "150px",
                            minHeight: "210px",
                            transition: "all 1s",
                            opacity: activeCardIndex === index ? 1 : 0.8,
                            transform:
                                activeCardIndex === index ? "scale(1.1)" : "scale(1)",
                        }}
                        onMouseEnter={() => {
                            setIsHovered(true);
                            setActiveCardIndex(index);
                        }}
                        onMouseLeave={() => setIsHovered(false)}
                    >
                        <AppCard
                            title={card.title}
                            pageLink={card.pageLink}
                            image={card.image}
                        />
                    </Box>
                ))}
            </Box>
            <Box display="flex" flexDirection="column" mt={10}>
                <Typography fontSize={22} fontWeight={400} color="#11142D" sx={{ transition: "all 1s" }} dir = {languageDir}>{cards[activeCardIndex].description}</Typography>
            </Box>
            {openVerified && (
                <Dialog open={openVerified} onClose={handleVerificationPopUp}>
                    <DialogTitle>
                        <Typography display="flex" justifyContent="center" color="#11142D" fontSize={18} fontWeight={500}>
                            {translate("dashboard.verifytitle", "Verification Email Sent")}
                        </Typography>
                    </DialogTitle>
                    <Divider style={{ margin: 0 }}></Divider>
                    <DialogContent>
                        <Box mt={2} mb={2}>
                            <Typography fontSize={18} fontWeight={500} color="#11142D">{translate("dashboard.verifybody", "A verification email with more instructions has been sent to your email address")}
                                <CheckCircle sx={{ color: "#2EB700", fontSize: ["2rem", "3rem"], paddingTop: "1.5rem" }}/>
                            </Typography>
                        </Box>
                    </DialogContent>
                </Dialog>
            )}
        </Box>
    );
}

export default Dashboard;
