import React, { useEffect, useState } from 'react';
import {
  ForgotPasswordFormTypes,
  useDataProvider,
  useForgotPassword,
  useGo,
  useLink,
  useTranslate
} from '@refinedev/core';
import { logoNonCollapsed } from '../assets';
import { Box, Card, FormControl, FormHelperText, Stack, TextField, Typography } from '@mui/material';
import { Check, CheckCircle, Error } from '@mui/icons-material';
import { Divider } from 'antd';
import { LandingFooter, LandingHeader } from '../components/layout';
import { useForm } from '@refinedev/react-hook-form';
import { CustomBackground, CustomButton } from '../components';
import { axios } from '../App';
import { FieldValues } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { allowedDomains, emailRegex } from '../web-config';

export function ResetForgottenPassword() {
    const { refineCore: { formLoading }, register, handleSubmit, watch } = useForm();
    const [userId, setUserId] = useState<string | null>(null);
    const [error, setError] = useState<string>('');
    const [passError, setPassError] = useState<string>('');
    const Link = useLink();
    const dataProvider = useDataProvider();
    const translate = useTranslate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    const go = useGo();

    useEffect(() => {
        async function fetchResponseData() {
            const response = await axios.get(`${dataProvider().getApiUrl()}/auth/reset-password/${token}`);
            if (response.status !== 200) {
                setError(translate("forgot-page.errorLink", "Invalid link. Please try resetting your password again."));
                return;
            }

            const data = response.data;
            setUserId(data.userId);
        }

        fetchResponseData();
    }, [dataProvider, token, translate]);

    const onFinishHandler = async (data: FieldValues) => {
        try {
            const response = await axios.patch(`${dataProvider().getApiUrl()}/auth/reset-password`, {
                id: userId,
                newPassword: data.newPassword,
                newPasswordConfirmation: data.newPasswordConfirmation
            })
            if (response.status !== 200) {
              setPassError(translate("forgot-page.errorPassword", 'Error resetting password. Please try again.'));
            }
            go({
                to: "/login",
            })
        } catch (err) {
            console.error(err);
        }
    };

    return(
        <>
            <CustomBackground />
            <LandingHeader landingPage={false} />
            <Card style={{
                maxWidth: 550,
                width: "100%",
                margin: "auto",
                marginTop: 60,
                textAlign: "center",
                boxShadow: '5px 5px 20px #000000',
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100%',
            }}
            >
                <Box sx={{ marginTop: "10px", marginBottom: "10px" }}>
                    <Link to="/">
                        <img src={logoNonCollapsed} height="35%" width="35%" alt="BraudeOverflow Logo" />
                    </Link>
                </Box>
                <Divider style={{ fontSize: "clamp(18px, 1.9vw, 22px)" }}>{translate("forgot-page.divText", "Reset your password")}</Divider>
                {error !== '' ? (
                    <Box mt={2} mb={2}>
                        <Typography fontSize={18} fontWeight={500} color="#11142D">{error}</Typography>
                    </Box>
                ) : (
                    <Stack direction="column" pb={1} flexWrap="wrap">
                        <form onSubmit={handleSubmit(onFinishHandler)}>
                            <Box ml={2}>
                                <Stack direction="column" gap={3}>
                                    <Stack direction="row" alignItems="center" gap={10.5}>
                                        <Typography fontSize={16} fontWeight={500} color="#11142D">{translate("forgot-page.passLabel1", "New password")}: </Typography>
                                        <TextField type="password" label={translate("forgot-page.passLabel1", "New password")}
                                                   color="info" {...register("newPassword", {required: true})} />
                                    </Stack>
                                    <Stack direction="row" alignItems="center" gap={2}>
                                        <Typography fontSize={16} fontWeight={500} color="#11142D">{translate("forgot-page.passLabel2", "Confirm new password")}: </Typography>
                                        <TextField type="password" label={translate("forgot-page.passLabel2", "Confirm new password")}
                                                   color="info" {...register("newPasswordConfirmation", {required: true})} />
                                    </Stack>
                                </Stack>
                            </Box>
                            <Stack direction="row" justifyContent="center" mt={3} mb={3}>
                                <CustomButton
                                    type="submit"
                                    title={formLoading ? translate("forgot-page.updateButton2", "Updating Password...") : translate("forgot-page.updateButton", "Update Password")}
                                    disabled={watch("newPassword") !== watch("newPasswordConfirmation")}
                                    backgroundColor="#475BE8"
                                    color="#FCFCFC"
                                    icon=<Check/>
                                />
                            </Stack>
                            {watch("newPassword") !== watch("newPasswordConfirmation") && (
                              <Typography fontSize={16} fontWeight={500} color="#FF6A69">{translate("forgot-page.passmatch", "Passwords do not match.")}</Typography>
                            )}
                            <Typography fontSize={16} fontWeight={500} color="#FF6A69">{passError}</Typography>
                        </form>
                    </Stack>
                )}
            </Card>
            <Box
                sx={{
                    position: "absolute",
                    bottom: 0,
                    width: "100%",
                    backgroundColor: "transparent",
                    color: "#fff",
                    textAlign: "center",
                }}
            >
                <LandingFooter />
            </Box>
        </>
    );
}

function ForgotPassword() {
    const Link = useLink();
    const [submittedForm, setSubmittedForm] = useState<boolean>(false);
    const [displayError, setDisplayError] = useState<string>("");
    const { refineCore: { formLoading }, register, handleSubmit } = useForm();
    const { mutate: forgotPassword } = useForgotPassword<ForgotPasswordFormTypes>();
    const dataProvider = useDataProvider();
    const translate = useTranslate();

    const emailExists = async (values: ForgotPasswordFormTypes) => {
        const response = await axios.get(`${dataProvider().getApiUrl()}/auth/reset-password/validate`,{
            params: {
                email: values.email?.toLowerCase(),
            }
        });

        if (response.status === 404) {
            return false;
        }

        return response.data.registrationType === "BraudeOverflow" && response.status === 200;
    }

  const validateEmail = (value: string) => {
    if (!emailRegex.test(value)) {
      setDisplayError(translate("register-page.emailerr", "Please enter your email address"));
      return;
    }
    const domain = value?.split('@')[1];
    if (allowedDomains.indexOf(domain) === -1) {
      setDisplayError(translate("register-page.invaliddomain", "Email domain is not allowed"));
      return;
    }

    setDisplayError("");
  };

    const onFinishHandler = async (values: ForgotPasswordFormTypes) => {
        if (displayError !== '') return;
        // Before calling this function, check if the email address exists.
        if (await emailExists(values)) {
            const args: ForgotPasswordFormTypes = { email: values.email?.toLowerCase() }
            forgotPassword(args);
            setDisplayError("");
            setSubmittedForm(true);
        } else {
            setDisplayError(translate("forgot-page.errorEmail", "This email address doesn't belong to any registered users."));
        }
    };

    return (
        <>
            <CustomBackground />
            <LandingHeader landingPage={false} />
            {submittedForm && displayError === "" ? (
                <>
                    <Box sx={{
                        margin: "auto",
                        width: "80%",
                        paddingTop: "2rem",
                    }}>
                        <Card style={{
                            margin: "auto",
                            padding: "0.9rem",
                            maxWidth: "450px",
                            minWidth: "270px",
                            height: "100%",
                            borderRadius: "10px",
                            textAlign: "center",
                            boxShadow: '5px 5px 20px #000000'
                        }}>
                            <Box sx={{ marginTop: "10px", marginBottom: "10px" }}>
                                <Link to="/">
                                    <img src={ logoNonCollapsed } height="42%" width="65%" alt="BraudeOverflow Logo" />
                                </Link>
                            </Box>
                            <Divider style={{ fontSize: "clamp(20px, 2vw, 24px)", }}>{translate("forgot-page.divText", "Reset your password")}</Divider>
                            <Box sx={{ marginTop: "10px", marginBottom: "10px" }}>
                                <Typography fontSize={16} fontWeight={500} color="#11142D">
                                    {translate("forgot-page.emailSent", "An email has been sent to the email address you submitted. Inside the email, you will find the details necessary to complete the process.")}
                                </Typography>
                                <CheckCircle sx={{ color: "#2EB700", fontSize: ["2rem", "3rem"], paddingTop: "1rem" }}/>
                            </Box>
                        </Card>
                    </Box>
                </>
            ) : (
                <>
                    <Box sx={{
                        margin: "auto",
                        width: "80%",
                        paddingTop: "2rem",
                    }}>
                        <Card style={{
                            margin: "auto",
                            padding: "0.9rem",
                            maxWidth: "450px",
                            minWidth: "270px",
                            height: "100%",
                            borderRadius: "10px",
                            textAlign: "center",
                            boxShadow: '5px 5px 20px #000000'
                        }}>
                            <Box sx={{ marginTop: "10px", marginBottom: "10px" }}>
                                <Link to="/">
                                    <img src={ logoNonCollapsed } height="42%" width="65%" alt="BraudeOverflow Logo" />
                                </Link>
                            </Box>
                            <Divider style={{ fontSize: "clamp(20px, 2vw, 24px)", }}>{translate("forgot-page.divText", "Reset your password")}</Divider>
                            <form onSubmit={handleSubmit(onFinishHandler)}>
                                <Box>
                                    <FormControl style={{ width: "100%", padding: "1rem" }}>
                                        <TextField
                                            color="info"
                                            placeholder="example@example.com"
                                            label={translate("forgot-page.emailLabel", "Email Address")}
                                            fullWidth
                                            {...register("email", { required: true})}
                                            inputProps={{
                                                title: translate("forgot-page.emailValid", "Enter a valid email address"),
                                            }}
                                            onChange={e => {
                                              validateEmail(e.target.value);
                                            }}
                                        >
                                        </TextField>
                                        {displayError !== "" && (
                                            <Box sx={{ marginTop: "0.5rem", display: "flex", alignItems: "center" }}>
                                                <Error sx={{ fontSize: ["1rem", "1.5rem"], color: "#B30000" }}/>
                                                <Box sx={{ flexGrow: 1 }}>
                                                    <FormHelperText
                                                        sx={{
                                                            fontWeight: 500,
                                                            fontSize: 14,
                                                            color: "#B30000",
                                                            ml: "0.5rem",
                                                        }}
                                                    >
                                                        {displayError}
                                                    </FormHelperText>
                                                </Box>
                                            </Box>
                                        )}
                                    </FormControl>
                                </Box>
                                <FormControl style={{ margin: "0.5rem 0 1rem 0" }}>
                                    <CustomButton
                                        type="submit"
                                        title={formLoading ? translate("forgot-page.resetButton2", "Sending Email...") : translate("forgot-page.resetButton", "Reset Password")}
                                        backgroundColor="#475BE8"
                                        color="#FCFCFC"
                                    />
                                </FormControl>
                            </form>
                        </Card>
                    </Box>
                </>
            )}
            <Box
                sx={{
                    position: "absolute",
                    bottom: 0,
                    width: "100%",
                    backgroundColor: "transparent",
                    color: "#fff",
                    textAlign: "center",
                }}
            >
                <LandingFooter />
            </Box>
        </>
    );
}

export default ForgotPassword;
