import React from 'react';
import { useGetLocale, useLink, useTranslate } from '@refinedev/core';
import {
    AppBar,
    Box,
    Button,
    Card,
    Dialog,
    DialogContent,
    IconButton,
    Stack,
    Toolbar,
    Typography
} from '@mui/material';
import { CalendarMonth, Close, Email, PersonPin, Phone, School, WhatsApp } from '@mui/icons-material';
import { CourseProps, TutorFullCardProps } from '../../interfaces/apps';

function TutorFullCard({ tutorProfile, tutorCourses, description, dialogStatus, handleClose }: TutorFullCardProps) {
    const translate = useTranslate();
    const Link = useLink();
    const locale = useGetLocale();
    const currDir = locale() === 'en' ? 'ltr' : 'rtl';

    const openWhats = (link: string) => {
        window.open(link, "_blank");
    }

    const whatsAppText = "היי, ראיתי את הפרסומת שלכם למורים פרטיים באתר של בראודה אווורפלו. אשמח לדבר :)";

    const translateMethod = (method: string) => {
        let translatedValue;
        switch (method) {
            case 'Frontal':
                translatedValue = translate('tutorcards.frontal', 'Frontal');
                break;
            case 'Zoom':
                translatedValue = translate('tutorcards.zoom', 'Zoom');
                break;
            default:
                translatedValue = method;
                break;
        }
        return translatedValue;
    }

    const translatePlatform = (platform: string) => {
        let translatedValue;
        switch (platform) {
            case 'One':
                translatedValue = translate('tutorcards.1on1', '1on1');
                break;
            case 'Groups':
                translatedValue = translate('tutorcards.groups', 'Groups');
                break;
            default:
                translatedValue = platform;
                break;
        }
        return translatedValue;
    }

    const departments = {
        [translate("register-page.depsoft", "Software Engineering")]: "Software Engineering",
        [translate("register-page.depdata", "Data Systems Engineering")]: "Data Engineering",
        [translate("register-page.depmech", "Mechanical Engineering")]: "Mechanical Engineering",
        [translate("register-page.depelec", "Electrical Engineering")]: "Electrical Engineering",
        [translate("register-page.depbio", "Bio-technology Engineering")]: "Bio-technology",
        [translate("register-page.depindus", "Industrial Engineering")]: "Industrial Engineering",
        [translate("register-page.depcivil", "Civil Engineering")]: "Civil Engineering",
        [translate("register-page.depopt", "Optical Engineering")]: "Optical Engineering",
        [translate("register-page.depmath", "Applied Mathematics")]: "Applied Mathematics",
        [translate("register-page.depsoftmath", "Applied Mathematics & Software Engineering")]: "Applied Mathematics And Software Engineering",
        [translate("register-page.depelecmath", "Applied Mathematics & Electrical Engineering")]: "Applied Mathematics And Electrical Engineering",
    };

    const genders = {
        [translate("register-page.male", "Male")]: "Male",
        [translate("register-page.female", "Female")]: "Female"
    };

    const getFieldTranslation = (object, value) => {
        for (let key in object) {
            if (object[key] === value) {
                return key;
            }
        }
        return null; // Value not found
    };


    return (
        <Dialog open={dialogStatus} onClose={handleClose} maxWidth="xl">
            <Box position="absolute" top={0} right={0}>
                <Button onClick={handleClose}
                        sx={{
                            position: "absolute",
                            top: "1px",
                            right: "1px",
                            color: "#000",
                            backgroundColor: "#fff",
                            "&:hover": {
                                backgroundColor: "#eee",
                            },
                        }}
                >
                    <Close />
                </Button>
            </Box>
            <DialogContent
                sx={{
                    margin: "15px",
                }}
            >
                <Box>
                    <Typography fontSize={24} fontWeight={600} color="#11142D" textTransform="capitalize">{tutorProfile.firstName} {tutorProfile.lastName}</Typography>
                    <Box mt="1px" borderRadius="15px" padding="10px" bgcolor="inherit">
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: { xs: "column", md: "row" },
                                gap: 2.5,
                            }}
                        >
                            <Box
                              component="img"
                              sx={{
                                  mt: 2,
                                  borderRadius: 2,
                                  maxWidth: '100%',
                                  maxHeight: "100%",
                                  height: "270px",
                                  width: "300px",
                              }}
                              src={tutorProfile.avatar}
                              alt="Profile Avatar"
                            />
                            <Box
                                flex={1}
                                display="flex"
                                flexDirection={{ xs: "column", md: "row" }}
                                gap="20px"
                            >
                                <Box
                                    flex={1}
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="space-between"
                                    gap="15px"
                                >
                                    <Stack direction="column" pb={1}>
                                        <Typography
                                            fontSize={22}
                                            fontWeight={600}
                                            color="#11142D"
                                        >
                                            {translate("tutorcards.info", "Information")}
                                        </Typography>
                                    </Stack>
                                    <Stack
                                        direction="row"
                                        flexWrap="wrap"
                                        pb={4}
                                    >
                                        <Stack
                                            direction="column"
                                            flexWrap="wrap"
                                            gap="20px"
                                            mr={20}
                                            pb={4}
                                        >
                                            <Stack flex ={1} gap="20px">
                                                <Typography
                                                    fontSize={14}
                                                    fontWeight={500}
                                                    color="#808191"
                                                >
                                                    {translate("tutorcards.email", "Email")}
                                                </Typography>
                                                <Box
                                                    display="flex"
                                                    flexDirection="row"
                                                    alignItems="center"
                                                    gap="10px"
                                                >
                                                    <Email sx={{ color: "#11142D" }} />
                                                    <Typography
                                                        fontSize={14}
                                                        color="#11142D"
                                                        noWrap
                                                        sx={{
                                                            '&:hover': {
                                                                fontWeight: 500
                                                            }
                                                        }}
                                                    >
                                                        <Link to={`mailto:${tutorProfile.email}`} style={{ textDecoration: "none", color: "inherit" }}>
                                                            {tutorProfile.email}
                                                        </Link>
                                                    </Typography>
                                                </Box>
                                            </Stack>
                                            <Stack flex={1} gap="15px">
                                                <Typography
                                                    fontSize={14}
                                                    fontWeight={500}
                                                    color="#808191"
                                                >
                                                    {translate("tutorcards.phone", "Phone Number")}
                                                </Typography>
                                                <Box
                                                    display="flex"
                                                    flexDirection="row"
                                                    alignItems="center"
                                                    gap="10px"
                                                >
                                                    <Phone sx={{ color: "#11142D" }} />
                                                    <Typography
                                                        fontSize={14}
                                                        color="#11142D"
                                                        noWrap
                                                    >
                                                        {tutorProfile.phone !== '' ? tutorProfile.phone : translate("tutorcards.na", "N/A")}
                                                    </Typography>
                                                    {tutorProfile.phone !== '' && (
                                                        <IconButton onClick={() => openWhats(`https://wa.me/972${tutorProfile.phone.substring(1)}?text=${whatsAppText}`)}>
                                                            <WhatsApp/>
                                                        </IconButton>
                                                    )}
                                                </Box>
                                            </Stack>
                                        </Stack>
                                        <Stack
                                            direction="column"
                                            flexWrap="wrap"
                                            gap="15px"
                                            mr={10}
                                        >
                                            {tutorProfile.degreeDepartment !== '' && (
                                                <Stack flex={1} gap="15px">
                                                    <Typography
                                                        fontSize={14}
                                                        fontWeight={500}
                                                        color="#808191"
                                                    >
                                                        {translate("tutorcards.dept", "Department")}
                                                    </Typography>
                                                    <Box
                                                        display="flex"
                                                        flexDirection="row"
                                                        alignItems="center"
                                                        gap="10px"
                                                    >
                                                        <School sx={{ color: "#11142D" }} />
                                                        <Typography
                                                            fontSize={14}
                                                            color="#11142D"
                                                            noWrap
                                                        >
                                                            {tutorProfile.degreeDepartment !== '' ? getFieldTranslation(departments, tutorProfile.degreeDepartment) : translate("tutorcards.na", "N/A")}
                                                        </Typography>
                                                    </Box>
                                                </Stack>
                                            )}
                                            {tutorProfile.gender !== '' && (
                                                <Stack flex={1} gap="15px">
                                                    <Typography
                                                        fontSize={14}
                                                        fontWeight={500}
                                                        color="#808191"
                                                    >
                                                        {translate("tutorcards.gender", "Gender")}
                                                    </Typography>
                                                    <Box
                                                        display="flex"
                                                        flexDirection="row"
                                                        alignItems="center"
                                                        gap="10px"
                                                    >
                                                        <PersonPin sx={{ color: "#11142D" }} />
                                                        <Typography
                                                            fontSize={14}
                                                            color="#11142D"
                                                        >
                                                            {tutorProfile.gender !== '' ? getFieldTranslation(genders, tutorProfile.gender) : translate("tutorcards.na", "N/A")}
                                                        </Typography>
                                                    </Box>
                                                </Stack>
                                            )}
                                            {tutorProfile.dateOfBirth !== null && (
                                                <Stack flex={1} gap="15px">
                                                    <Typography
                                                        fontSize={14}
                                                        fontWeight={500}
                                                        color="#808191"
                                                    >
                                                        {translate("tutorcards.age", "Age")}
                                                    </Typography>
                                                    <Box
                                                        display="flex"
                                                        flexDirection="row"
                                                        alignItems="center"
                                                        gap="10px"
                                                    >
                                                        <CalendarMonth sx={{ color: "#11142D" }} />
                                                        <Typography
                                                            fontSize={14}
                                                            color="#11142D"
                                                        >
                                                            {Math.floor((new Date().getTime() - new Date(tutorProfile.dateOfBirth).getTime()) / 3.15576e+10)}
                                                        </Typography>
                                                    </Box>
                                                </Stack>
                                            )}
                                        </Stack>
                                    </Stack>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    flex={1}
                    sx={{
                        gap: 2.5,
                        marginTop: { md: "5px" },
                        marginLeft: { xs: "10px", md: "10px" },
                        paddingRight: "5px",
                        overflowX: "auto",
                        "& > div": {
                            minWidth: "100%",
                        },
                    }}
                >
                    {tutorCourses.length > 0 ? (
                        <Box
                            flex={1}
                            display="flex"
                            flexDirection="column"
                            gap={2.5}
                            dir={currDir}
                        >
                            <Box mt={1}>
                                <Typography fontSize={20} fontWeight={500} color="#11142D" textAlign="center">{translate("tutorcards.about", "About")}</Typography>
                                <Typography variant="subtitle1">{description}</Typography>
                            </Box>
                            <Box mt={2} mb={1} textAlign="center">
                                <AppBar position="static" color="primary" style={{ borderRadius: '5px' }}>
                                    <Toolbar>
                                        <Typography variant="h6" sx={{ flexGrow: 1 }}>
                                            {translate("tutorcards.tutorCourses", "Tutor Courses")}
                                        </Typography>
                                        <Typography variant="subtitle1">
                                            {tutorCourses.length} {tutorCourses.length === 1 ? translate("tutorcards.coursessingular", "Course") : translate("tutorcards.coursesplural", "Courses")}
                                        </Typography>
                                    </Toolbar>
                                </AppBar>
                            </Box>
                            <Stack>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        gap: 10
                                    }}
                                    dir={currDir}
                                >
                                    {tutorCourses?.map((course: CourseProps) => (
                                        <Card
                                            style={{
                                                padding: "5px",
                                                paddingLeft: "10px",
                                                minWidth: "13vw",
                                                minHeight: "29vh",
                                                borderRadius: "10px",
                                                boxShadow: '0px 0px 5px #000000',
                                                margin: "5px 0 5px 35px",
                                            }}
                                            sx={{
                                                width: { xs: "100%", md: "20%"},
                                            }}
                                        >
                                            <Stack
                                                direction="row"
                                                flexWrap="wrap"
                                                alignItems="center"
                                                pb={3}
                                                gap={3}
                                                marginTop={2}
                                            >
                                                <Stack display="flex" justifyContent="center" alignItems="center" width="100%">
                                                    <Stack
                                                        direction="row"
                                                        textAlign="center"
                                                    >
                                                        <Typography fontSize={18} fontWeight={700} color="#11142D" style={{ fontSize: "clamp(12px, 1.9vw, 18px)" }}>
                                                            {course.courseName}
                                                        </Typography>
                                                    </Stack>
                                                    <Stack
                                                        direction="row"
                                                        textAlign="center"
                                                    >
                                                        <Typography fontSize={14} fontWeight={300} color="#11142D" style={{ fontSize: "clamp(12px, 1.9vw, 18px)" }}>
                                                            {course.courseId}
                                                        </Typography>
                                                    </Stack>
                                                </Stack>
                                                <Stack
                                                    direction="row"
                                                    flexWrap="wrap"
                                                    width="100%"
                                                >
                                                    <Typography fontSize={16} fontWeight={500} color="#11142D" pl={2} px={2}>
                                                        {translate("tutorcards.grade", "Grade")}: {course.grade}
                                                    </Typography>
                                                </Stack>
                                                <Stack
                                                    direction="row"
                                                    flexWrap="wrap"
                                                    width="100%"
                                                >
                                                    <Typography fontSize={16} fontWeight={500} color="#11142D" pl={2} px={2}>
                                                        {translate("tutorcards.platform", "Platform")}: {course.platform === "Both" ? translate("tutorcards.platformB", "Frontal & Zoom") : translateMethod(course.platform) }
                                                    </Typography>
                                                </Stack>
                                                <Stack
                                                    direction="row"
                                                    flexWrap="wrap"
                                                    width="100%"
                                                >
                                                    <Typography fontSize={16} fontWeight={500} color="#11142D" pl={2} px={2}>
                                                        {translate("tutorcards.method", "Method")}: {course.method === "Both" ? translate("tutorcards.methodB", "1on1 & Groups") : translatePlatform(course.method) }
                                                    </Typography>
                                                </Stack>
                                                <Stack
                                                    direction="row"
                                                    flexWrap="wrap"
                                                    width="100%"
                                                >
                                                    <Typography fontSize={16} fontWeight={500} color="#11142D" pl={2} px={2}>
                                                        {translate("tutorcards.tablet", "Tablet")}: {course.tablet ? translate("tutorcards.yes", "Yes") : translate("tutorcards.no", "No")}
                                                    </Typography>
                                                </Stack>
                                                <Stack
                                                    direction="row"
                                                    flexWrap="wrap"
                                                    width="100%"
                                                >
                                                    <Typography fontSize={16} fontWeight={500} color="#11142D" pl={2} px={2}>
                                                        {translate("tutorcards.price", "Price per hour")}: {course.price != null ? course.price + "₪" : translate("tutorcards.noprice", "N/A - Contact Tutor For Info")}
                                                    </Typography>
                                                </Stack>
                                            </Stack>
                                        </Card>
                                    ))}
                                </Box>
                            </Stack>
                        </Box>
                    ) : (
                        <Box mt={5}>
                            <Typography fontSize={18} fontWeight={500} color="#11142D" dir={currDir}>{translate("tutorcards.nocourses", "Tutor hasn't selected courses yet. Contact them for more information.")}</Typography>
                        </Box>
                    )}
                </Box>
            </DialogContent>
        </Dialog>
    );
}

export default TutorFullCard;
