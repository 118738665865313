import React from 'react';
import { useLink } from "@refinedev/core";
import { Box, IconButton, Stack, TextField, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";
import { useGetLocale, useTranslate } from '@refinedev/core';

function AddSubTaskForm({ subtaskToAdd, setSubtaskToAdd, subtaskError, handleAddSubTask }: any) {
    const Link = useLink();
    
    const translate = useTranslate();
    const locale = useGetLocale();
    const currDir = locale() === 'en' ? 'ltr' : 'rtl';

    return (
        <Box gap={2} width="30%" border="1px solid #E0E0E0" p={2} mb={2}>
            <Stack direction="column" flexDirection="column" flexWrap="wrap" gap={1}>
                <Stack direction="row" gap={1} alignItems="center" width="100%">
                    <Typography fontSize={16} fontWeight={500} color="#11142D">
                        {translate("taskmanager-subtask.subtask", "New Subtask")}
                    </Typography>
                    <IconButton size="small" style={{ padding: 0 }} onClick={handleAddSubTask}>
                        <Add fontSize="medium" />
                    </IconButton>
                </Stack>
                <Stack direction="row" gap={1} alignItems="center" width="50%">
                    <Typography fontSize={16} color="#11142D">{translate("taskmanager-subtask.title","Title: ")}</Typography>
                    <TextField
                        color="info"
                        id="standard-basic"
                        variant="standard"
                        size="small"
                        type="text"
                        placeholder={translate("taskmanager-subtask.titlePlaceholder","Title...")}
                        value={subtaskToAdd.subTitle}
                        onChange={(e) => {
                            setSubtaskToAdd({
                                ...subtaskToAdd,
                                subTitle: e.target.value,
                            });
                        }}
                        onKeyDown={(e) => {
                            e.stopPropagation()
                        }}
                    />
                </Stack>
                {subtaskError !== '' && (
                    <Typography fontSize={12} color="#FF0000">{subtaskError}</Typography>
                )}
                <Stack direction="row" gap={1} alignItems="center" width="100%">
                    <Typography fontSize={16} color="#11142D">{translate("taskmanager-subtask.description","Description:")} </Typography>
                    <TextField
                        id="outlined-multiline-static"
                        type="text"
                        placeholder={translate("taskmanager-subtask.descriptionPlaceholder","Enter description...")}
                        style={{
                            width: "100%",
                            border: "none",
                            outline: "none",
                            backgroundColor: "transparent",
                            color: "#11142D",
                            fontSize: "16px",
                            fontWeight: 500,
                            pointerEvents: "auto",
                        }}
                        multiline
                        color="info"
                        size="small"
                        rows={1}
                        value={subtaskToAdd.subDescription}
                        onChange={(e) => {
                            setSubtaskToAdd({
                                ...subtaskToAdd,
                                subDescription: e.target.value,
                            });
                        }}
                        onKeyDown={(e) => {
                            e.stopPropagation()
                        }}
                        onClick={(e) => {
                            e.stopPropagation()
                        }}
                    />
                </Stack>
                <Stack direction="row" gap={1} alignItems="center" flexWrap="wrap">
                    <Typography style={{ fontSize: "clamp(10px, 1.3vw, 18px)" }} color="#11142D" flexWrap="wrap">
                        {translate("taskmanager-subtask.link","Link:")}
                        <Link
                            to={subtaskToAdd.subUrlAttachment}
                            target="_blank"
                            style={{
                                textDecoration: 'none',
                                color: "#000",
                                fontSize: "12px",
                                marginRight: "5px"
                            }}
                        >
                        </Link>
                        <TextField
                            color="info"
                            id="standard-basic"
                            variant="standard"
                            size="small"
                            type="text"
                            placeholder={translate("taskmanager-subtask.linkPlaceholder","Enter Link Attachment")}
                            value={subtaskToAdd.subUrlAttachment}
                            onChange={(e) => {
                                setSubtaskToAdd({
                                    ...subtaskToAdd,
                                    subUrlAttachment: e.target.value,
                                });
                            }}
                            onKeyDown={(e) => {
                                e.stopPropagation()
                            }}
                        />
                    </Typography>
                </Stack>
            </Stack>
        </Box>
    );
}

export default AddSubTaskForm;
