import React, { createContext, PropsWithChildren, useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { LightTheme } from '@refinedev/mui';

type ColorModeContextType = {
  mode: string;
  setMode: () => void;
};

export const ColorModeContext = createContext<ColorModeContextType>(
  {} as ColorModeContextType
);

export const ColorModeContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const colorModeFromLocalStorage = localStorage.getItem("colorMode");
  const isSystemPreferenceDark = window?.matchMedia(
    "(prefers-color-scheme: dark)"
  ).matches;

  const systemPreference = isSystemPreferenceDark ? "light" : "light";
  const [mode, setMode] = useState(
    colorModeFromLocalStorage || systemPreference
  );

  const customLightTheme = {
    ...LightTheme,
    palette: {
      ...LightTheme.palette,
      background: {
        default: "#FFFFFF",
        paper: "#FFFFFF"
      },
    }
  }

  useEffect(() => {
    window.localStorage.setItem("colorMode", mode);
  }, [mode]);

  const setColorMode = () => {
    if (mode === "light") {
      setMode("light");
    } else {
      setMode("light");
    }
  };

  return (
    <ColorModeContext.Provider
      value={{
        setMode: setColorMode,
        mode,
      }}
    >
      <ThemeProvider theme={mode === "light" ? customLightTheme : customLightTheme}>
        {children}
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};
