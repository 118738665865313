import React, { useEffect, useState } from 'react';
import { useDataProvider, useTranslate } from '@refinedev/core';
import { myTutorProfile } from '../../interfaces/profile';
import { CourseProps } from '../../interfaces/apps';
import { CustomButton, DisplayCourses, EditCourseForm } from '../index';
import { Box, Stack, TextField, Typography } from '@mui/material';
import { Description, Send } from '@mui/icons-material';
import { axios } from '../../App';

function TutorProfile({ description, tutorCourses, updateSingleTutorCourse } : myTutorProfile) {
    const [edit, setEdit] = useState<boolean>(false); // viewing -> editing.
    const [courseEdit, setCourseEdit] = useState<CourseProps>();
    const [descript, setDescription] = useState<string>(description);

    useEffect(() => {
        setDescription(description);
    }, [description]);

    const dataProvider = useDataProvider();
    const translate = useTranslate();

    const handleEditState = () => {
        setEdit(!edit);
    }

    const handleCourseEdit = (course: CourseProps) => {
        handleEditState();
        setCourseEdit(course);
    }

    const handleUpdateDescription = async () => {
        if (!descript)
            return;

        try {
            await axios.patch(`${dataProvider().getApiUrl()}/my-profile/tutor/about`, {
                description: descript,
            });
        } catch (err) {
            console.error(err);
        }
    }

    return (
        edit ? (
            <EditCourseForm
                course={courseEdit}
                handleEditState={handleEditState}
                updateSingleTutorCourse={updateSingleTutorCourse}
            />
        ) : (
            <Box>
                <Box mt={2}>
                    <Typography fontSize={22} fontWeight={500} color="#11142D" textAlign="center">{translate("myprofilepage.about", "My Tutor Profile")}</Typography>
                    <Stack direction="row" flexWrap="wrap">
                        <Description/>
                        <TextField
                            id="outlined-multiline-static"
                            label={translate("myprofilepage.desc", "Description")}
                            placeholder={translate("myprofilepage.descplaceholder", "Write about yourself and your experience...")}
                            multiline
                            color="info"
                            rows={2}
                            value={descript}
                            onChange={(e) => setDescription(e.target.value)}
                            sx={{ width: "30%", marginLeft: "5px" }}
                        />
                        <Box ml={3} mt={4}>
                            <CustomButton
                                type="button"
                                title={translate("myprofilepage.saveButton", "Save")}
                                backgroundColor="#475BE8"
                                color="#FCFCFC"
                                icon=<Send/>
                                handleClick={handleUpdateDescription} // Handle update description.
                            />
                        </Box>
                    </Stack>
                </Box>
                <DisplayCourses
                    tutorCourses={tutorCourses}
                    handleCourseEdit={handleCourseEdit}
                />
            </Box>
        )
    );
}

export default TutorProfile;
