import React from 'react';

const CustomBackground = () => {
    const createCircles = (): JSX.Element[] => {
        let circles: JSX.Element[] = []; // Explicitly typing the array
        for (let i = 0; i < 250; i++) {
            const style = {
                top: `${Math.random() * 100}%`,
                left: `${Math.random() * 100}%`,
                animationDelay: `${Math.random() * 10}s`,
                background: `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.random()})`,
            };
            circles.push(<div key={i} className="circle" style={style}></div>);
        }
        return circles;
    };

    return (
        <div className="root">
            {createCircles()}
        </div>
    );
};

export default CustomBackground;
