import React, { useState, useEffect, useRef } from 'react';
import { Box, Stack, IconButton, Typography, MenuItem, Divider, TextField, Autocomplete } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp, CheckCircle, AddComment, Delete } from '@mui/icons-material';
import ExpandedTaskItem from "./ExpandedTaskItem";
import { CourseObjectProps } from "../../interfaces/apps";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

function TaskItem({ task, index, onToggleExpanded, handleRevertTaskStatus, handleCompleteTask, handleDeleteTask, handleEditTask }: any) {
    const [priorityColor, setPriorityColor] = useState<string>('#FFFFFF');
    const [expandedItem, setExpandedItem] = useState<boolean>(false);
    const [editTask, setEditTask] = useState<boolean>(false);
    const [updatedTask, setUpdatedTask] = useState<any>({...task});
    const [updateTaskError, setUpdateTaskError] = useState<string>('');
    const [tagToAdd, setTagToAdd] = useState<string>('');
    const [currentTags, setCurrentTags] = useState<string[]>(task.tags ?? []);
    const courses = JSON.parse(localStorage.getItem('courses') ?? '');

    useEffect(() => {
       setPriorityColor(task.completed ? '#11142D' : (task.priority === 1 ? '#FF0000' : task.priority === 2 ? '#FFA500' : '#00FF00'));
    }, [task.priority, task.completed]);

    const translatePriority = (value: Number) => {
      switch (value) {
          case 1:
              return 'High';
          case 2:
              return 'Medium';
          case 3:
              return 'Low';
          default:
              return 'Unknown';
      }
    };

    const handleExpandedItem = () => {
      setExpandedItem(!expandedItem);
      if (editTask) {
          handleEditTaskForm(false);
      }
      onToggleExpanded();
    };

    const handleEditTaskForm = (closedAfterSubmission) => {
        if (!closedAfterSubmission) {
            setTagToAdd('');
            setUpdatedTask({...task});
            setCurrentTags(task.tags ?? []);
        }
        setEditTask(!editTask);
    };

    const handleSubmitEditTask = () => {
        if (updatedTask.title === '') {
            setUpdateTaskError('Please enter a title');
            return;
        }
        handleEditTask(updatedTask);
        handleEditTaskForm(true);
    };

    const handleCourseChange = (_, value: CourseObjectProps | null) => {
        if (value) {
            setUpdatedTask({...updatedTask, course: value.name});
        }
    };

    const handleDateChange = (date: Dayjs | null) => {
        setUpdatedTask({...updatedTask, dueDate: dayjs(date) });
    };

    return (
        <Box>
            <Stack direction="row" flexDirection="row" gap={2} alignItems="center" width="100%">
                <MenuItem
                    disableRipple
                    key={task._id}
                    selected={false}
                    style={{ pointerEvents: 'none' }}
                    sx={{
                        border: "1px solid #E0E0E0",
                        minHeight: "6vh",
                        width: "100%",
                        '&:hover': {
                            backgroundColor: 'inherit',
                        },
                        textDecoration: task.completed ? 'line-through' : ''
                    }}
                >
                    <Stack direction="row" flexDirection="row" gap={2} alignItems="center" sx={{ width: '100%' }}>
                        <Box
                            alignItems="flex-start"
                            style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', scrollbarWidth: 'none' }}
                            flexBasis={0} flexGrow={1} flexShrink={1} maxWidth="5%"
                            display="flex" justifyContent="center"
                        >
                            {task.completed && <CheckCircle sx={{ color: "#2EB700", fontSize: ["1rem", "2rem"], paddingTop: "0.5rem" }}/>}
                            <Typography
                                fontSize={16}
                                color="#11142D"
                                onMouseOver={(e) => e.currentTarget.style.overflow = 'auto'}
                                onMouseOut={(e) => e.currentTarget.style.overflow = 'hidden'}
                            >
                                #{index}
                            </Typography>
                        </Box>
                        <Divider orientation="vertical" flexItem style={{ backgroundColor: '#b7b1b1' }} />
                        <Box
                            flexBasis={0} flexGrow={1} flexShrink={1} maxWidth="13%"
                            style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', pointerEvents: 'auto', scrollbarWidth: 'none' }}
                        >
                            {editTask ? (
                                <>
                                    <TextField
                                        color="info"
                                        id="standard-basic"
                                        variant="standard"
                                        size="small"
                                        type="text"
                                        placeholder="Title..."
                                        defaultValue={task.title}
                                        value={updatedTask.title}
                                        onChange={(e) => {
                                            setUpdatedTask({
                                                ...updatedTask,
                                                title: e.target.value,
                                            });
                                        }}
                                        onKeyDown={(e) => {
                                            e.stopPropagation()
                                        }}
                                    />
                                    {updateTaskError !== '' && (
                                        <Typography color="#FF0000">{updateTaskError}</Typography>
                                    )}
                                </>
                            ) : (
                                <Typography
                                    fontSize={16}
                                    color="#11142D"
                                    title={task.title}
                                >
                                    {task.title}
                                </Typography>
                            )}
                        </Box>
                        <Divider orientation="vertical" flexItem style={{ backgroundColor: '#b7b1b1' }} />
                        <Box
                            style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                            flexBasis={0} flexGrow={1} flexShrink={1} maxWidth="10%"
                            display="flex" justifyContent="center"
                        >
                            {editTask ? (
                                <Autocomplete
                                    id="combo-box-demo"
                                    options={courses}
                                    getOptionLabel={(course: CourseObjectProps) => `${course.name} ${course.id}`}
                                    sx={{ width: 400 }}
                                    onChange={handleCourseChange}
                                    renderInput={(params) =>
                                        <TextField
                                            color="info"
                                            sx={{ pointerEvents: 'auto' }}
                                            {...params}
                                            placeholder="Search by course name or ID"
                                            size="medium"
                                            onKeyDown={(e) => {
                                                e.stopPropagation()
                                            }}
                                        />}
                                />
                            ) : (
                                <Typography
                                    fontSize={16}
                                    color="#11142D"
                                >
                                    {task.course === '' ? 'No Course Specified' : task.course}
                                </Typography>
                            )}
                        </Box>
                        <Divider orientation="vertical" flexItem style={{ backgroundColor: '#b7b1b1' }} />
                        <Box
                            style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', scrollbarWidth: 'none' }}
                            flexBasis={0} flexGrow={1} flexShrink={1} maxWidth="12%"
                            display="flex" justifyContent="center"
                        >
                            {editTask ? (
                                <TextField
                                    sx = {{
                                        width: "200px",
                                        pointerEvents: "auto",
                                    }}
                                    id="outlined-number"
                                    select
                                    color="info"
                                    placeholder="Category"
                                    label="Category"
                                    defaultValue={task.status}
                                    margin="normal"
                                    value={updatedTask.status}
                                    onChange={(e) => setUpdatedTask({...updatedTask, status: e.target.value})}
                                    onKeyDown={(e) => {
                                        e.stopPropagation()
                                    }}
                                >
                                    <MenuItem key='notstarted' value='Not Started'>Not Started</MenuItem>
                                    <MenuItem key='inprogress' value='In Progress'>In Progress</MenuItem>
                                    <MenuItem key='completed' value='Completed'>Completed</MenuItem>
                                    <MenuItem key='onhold' value='On Hold'>On Hold</MenuItem>
                                </TextField>
                                ) : (
                                <Typography
                                    fontSize={16}
                                    color="#11142D"
                                >
                                    {task.status}
                                </Typography>
                            )}
                        </Box>
                        <Divider orientation="vertical" flexItem style={{ backgroundColor: '#b7b1b1' }} />
                        <Box
                            style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', scrollbarWidth: 'none' }}
                            flexBasis={0} flexGrow={1} flexShrink={1} maxWidth="12%"
                            display="flex" justifyContent="center"
                        >
                            {editTask ? (
                                <TextField
                                    sx = {{
                                        width: "200px",
                                        pointerEvents: "auto",
                                    }}
                                    id="outlined-number"
                                    select
                                    color="info"
                                    placeholder="Category"
                                    label="Category"
                                    defaultValue={task.category}
                                    margin="normal"
                                    value={updatedTask.category}
                                    onChange={(e) => setUpdatedTask({...updatedTask, category: e.target.value})}
                                    onKeyDown={(e) => {
                                        e.stopPropagation()
                                    }}
                                >
                                    <MenuItem key='assignment' value='Assignment'>Submission Assignment</MenuItem>
                                    <MenuItem key='lab' value='Lab'>Lab Work</MenuItem>
                                    <MenuItem key='practice' value='Practice'>Practice</MenuItem>
                                    <MenuItem key='workshop' value='Workshop'>Workshop</MenuItem>
                                    <MenuItem key='exams' value='Exam Preparation'>Exam Preparation</MenuItem>
                                    <MenuItem key='other' value='Other'>Other</MenuItem>
                                </TextField>
                                ) : (
                                <Typography
                                    fontSize={16}
                                    color="#11142D"
                                >
                                    {task.category}
                                </Typography>
                            )}
                        </Box>
                        <Divider orientation="vertical" flexItem style={{ backgroundColor: '#b7b1b1' }} />
                        <Box
                            style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', scrollbarWidth: 'none' }}
                            flexBasis={0} flexGrow={1} flexShrink={1} maxWidth="10%"
                            display="flex" justifyContent="center"
                        >
                            {editTask ? (
                                <TextField
                                    sx = {{
                                        width: "200px",
                                        pointerEvents: "auto",
                                    }}
                                    id="outlined-number"
                                    select
                                    color="info"
                                    placeholder="Category"
                                    label="Category"
                                    defaultValue={task.priority}
                                    margin="normal"
                                    value={updatedTask.priority}
                                    onChange={(e) => setUpdatedTask({...updatedTask, priority: Number(e.target.value)})}
                                    onKeyDown={(e) => {
                                        e.stopPropagation()
                                    }}
                                >
                                    <MenuItem key='1' value='1'>High</MenuItem>
                                    <MenuItem key='2' value='2'>Medium</MenuItem>
                                    <MenuItem key='3' value='3'>Low</MenuItem>
                                </TextField>
                            ) : (
                                <Typography
                                    fontSize={16}
                                    fontWeight={500}
                                    color={priorityColor}
                                >
                                    {translatePriority(task.priority)}
                                </Typography>
                            )}
                        </Box>
                        <Divider orientation="vertical" flexItem style={{ backgroundColor: '#b7b1b1' }} />
                        <Box
                            style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', scrollbarWidth: 'none' }}
                            flexBasis={0} flexGrow={1} flexShrink={1} maxWidth="13%"
                            display="flex" justifyContent="center"
                        >
                            {editTask ? (
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        disablePast
                                        openTo="day"
                                        views={['year', 'month', 'day']}
                                        inputFormat="DD-MM-YYYY"
                                        value={updatedTask.dueDate ? dayjs(updatedTask.dueDate) : null}
                                        onChange={handleDateChange}
                                        renderInput={(params) =>
                                            <TextField
                                                color="info"
                                                id="standard-basic"
                                                variant="standard"
                                                size="small"
                                                sx={{
                                                    pointerEvents: "auto",
                                                    marginRight: "10px",
                                                }}
                                                {...params}
                                                onKeyDown={(e) => {
                                                    e.stopPropagation()
                                                }}
                                            />}
                                    />
                                </LocalizationProvider>
                            ) : (
                                <Typography
                                    fontSize={16}
                                    color="#11142D"
                                >
                                    {task.dueDate ? new Date(task.dueDate).toDateString() : 'No Date Specified'}
                                </Typography>
                            )}
                        </Box>
                        <Divider orientation="vertical" flexItem style={{ backgroundColor: '#b7b1b1' }} />
                        <Box
                            display="flex" flexDirection="row" gap={2}
                            style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', scrollbarWidth: 'none' }}
                            flexBasis={0} flexGrow={1} flexShrink={1} maxWidth="15%"
                        >
                            <Stack direction="row" flexDirection="row" flexWrap="wrap" alignItems="center" display="flex" justifyContent="flex-start">
                                <Stack direction="row" flexDirection="row" flexWrap="wrap" gap={1} alignItems="center" display="flex" justifyContent="flex-start">
                                    {currentTags?.map((tag: string) => (
                                        <Stack direction="row" flexDirection="row" flexWrap="nowrap" alignItems="center" style={{ pointerEvents: 'auto' }}>
                                            {editTask && (
                                                <IconButton onClick={() => {
                                                    currentTags?.splice(currentTags?.indexOf(tag), 1);
                                                    setCurrentTags(currentTags);
                                                    setUpdatedTask({ ...updatedTask, tags: currentTags });
                                                }}>
                                                    <Delete />
                                                </IconButton>
                                            )}
                                            <Typography fontSize={16} color="#11142D" title={tag}>
                                                {tag}
                                            </Typography>
                                        </Stack>
                                    ))}
                                </Stack>
                                {editTask && (
                                    <Stack direction="row" flexDirection="row" flexWrap="nowrap" alignItems="center" style={{ pointerEvents: 'auto' }}>
                                        <TextField
                                            color="info"
                                            id="standard-basic"
                                            variant="standard"
                                            size="small"
                                            type="text"
                                            placeholder="Add Tag"
                                            sx={{
                                                width: "40%"
                                            }}
                                            value={tagToAdd}
                                            onChange={(e) => setTagToAdd(e.target.value)}
                                            onKeyDown={(e) => {
                                                e.stopPropagation()
                                            }}
                                        />
                                        <IconButton onClick={() => {
                                            if (tagToAdd === '') return;
                                            const newTags = currentTags ? [...currentTags, tagToAdd] : [tagToAdd];
                                            setCurrentTags(newTags);
                                            setUpdatedTask({ ...updatedTask, tags: newTags });
                                            setTagToAdd('');
                                        }}>
                                            <AddComment />
                                        </IconButton>
                                    </Stack>
                                )}
                            </Stack>
                        </Box>
                    </Stack>
                    <Box ml={2} alignItems="flex-end" sx={{ pointerEvents: 'auto' }}>
                        <IconButton onClick={handleExpandedItem}>
                            {expandedItem ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                        </IconButton>
                    </Box>
                </MenuItem>
            </Stack>
            {expandedItem && (
                <ExpandedTaskItem
                    task={task}
                    handleCompleteTask={handleCompleteTask}
                    handleRevertTaskStatus={handleRevertTaskStatus}
                    handleDeleteTask={handleDeleteTask}
                    setToEdit={editTask}
                    handleEditTask={handleSubmitEditTask}
                    handleEditTaskForm={handleEditTaskForm}
                    setUpdatedTask={setUpdatedTask}
                    updatedTask={updatedTask}
                />
            )}
        </Box>
    );
}

export default TaskItem;
