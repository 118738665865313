import React, { useEffect, useRef, useState } from 'react';
import { LoginFormTypes, useDataProvider, useLink, useLogin, useTranslate } from '@refinedev/core';
import { axios } from '../App';
import { logoNonCollapsed } from '../assets';
import { CredentialResponse } from '../interfaces/google';
import { Button, Card, Divider, Form, Input, Typography } from 'antd';
import { Box, useMediaQuery } from '@mui/material';
import { LandingFooter, LandingHeader } from '../components/layout';
import { CustomBackground } from '../components';
import { bannedErrorProps } from '../interfaces/apps';
import { parseJwt } from '../utils/parse-jwt';


export const Login: React.FC = () => {
    const [banned, setIsBanned] = useState<bannedErrorProps | null>(null);
    const [error, setError] = useState<string | null>(null);
    const { mutate: login } = useLogin<CredentialResponse>();

    const dataProvider = useDataProvider();
    const { Text } = Typography;
    const [form] = Form.useForm<LoginFormTypes>();
    const Link = useLink();
    const canFooterFit = useMediaQuery('(min-width: 768px)');

    const handleBannedInfo = async (email: string | undefined) => {
        if (!email) return;

        try {
            const response = await axios.get(`${dataProvider().getApiUrl()}/users/banned-info`, {
                params: {
                    email: email?.toLowerCase(),
                }
            });
            setIsBanned({
                reason: response.data.reason,
                unbanDate: response.data.unbanDate
            });
            console.log(banned);
        } catch (error: any) {
            setError(error.toString());
        }
    };

    const handleWebSignIn = async ({ email, password } : LoginFormTypes) => {
        login({
            email,
            password,
            providerName: "BraudeOverflow"
        }, {
            onSuccess: async (data) => {
                setError('');
                setIsBanned(null);
                if (!data.success && data.error.message.includes('Banned')) {
                    await handleBannedInfo(email);
                } else if (!data.success && data.error.message === 'Invalid Credentials') {
                    setError('Invalid Credentials');
                }
            }
        });
    }

    const GoogleButton = (): JSX.Element => {
        const divRef = useRef<HTMLDivElement>(null);
        useEffect(() => {
            if (typeof window === "undefined" || !window.google || !divRef.current) {
                return;
            }
            try {
                window.google.accounts.id.initialize({
                    ux_mode: "popup",
                    client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID!,
                    callback: async (res: CredentialResponse) => {
                        if (res.credential) {
                            login({
                                providerName: "Google",
                                credential: res.credential
                            }, {
                                onSuccess: async (data) => {
                                    setError('');
                                    setIsBanned(null);
                                    if (!data.success && data.error.message.includes('Banned')) {
                                        const profileObj = res.credential ? parseJwt(res.credential) : null;
                                        if (profileObj) {
                                            await handleBannedInfo(profileObj.email);
                                            return;
                                        }
                                        setError('Something went wrong, please try again later');
                                    } else if (!data.success) {
                                        setError(data.error.message);
                                    }
                                }
                            });
                        }
                    },
                });
                window.google.accounts.id.renderButton(divRef.current, {
                    theme: "filled_blue",
                    size: "medium",
                    type: "standard",
                    width : "240",
                    shape: "pill"
                });
            } catch (error: any) {
                if (error.message.includes('Banned')) {
                    setIsBanned(JSON.parse(error));
                }
                console.log(error);
            }
        }, []);

        return <div ref={divRef} />;
    };
    const translate = useTranslate();
    return (
        <Box>
            <CustomBackground />
            <LandingHeader landingPage={false} />
                <Card style={{
                    maxWidth: 450,
                    width: "90%",
                    margin: "60px auto",
                    textAlign: "center",
                    boxShadow: '5px 5px 20px #000000',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100%',
                }}
                >
                    <Box sx={{ marginTop: "10px", marginBottom: "10px" }}>
                        <Link to="/">
                            <img src={logoNonCollapsed} height="35%" width="35%" alt="BraudeOverflow Logo" />
                        </Link>
                    </Box>
                    <Divider style={{ fontSize: "clamp(18px, 1.9vw, 22px)" }}>{translate("login-page.signintoyour", "Sign in to your account")}</Divider>
                    <Form layout="vertical" form={form} onFinish={handleWebSignIn}>
                        <Form.Item
                            name="email"
                            label={translate("login-page.emailadd","Email Address")}
                            rules={[
                                { required: true },
                                {
                                    type: "email",
                                    message: translate("login-page.invalidemail", "Invalid Email Address")
                                },
                            ]}
                        >
                            <Input
                                size="large"
                                placeholder="example@example.com"
                            />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            label={translate("login-page.pass","Password")}
                            rules={[{ required: true }]}
                            style={{ marginBottom: "12px" }}
                        >
                            <Input
                                type="password"
                                placeholder="●●●●●●●●"
                                size="large"
                            />
                        </Form.Item>
                        <Form.Item style={{ marginBottom: "5px" }}>
                            <Button
                                title="Sign in"
                                type="primary"
                                size="large"
                                htmlType="submit"
                                block
                                style={{
                                    borderRadius: "20px"
                                }}
                            >
                                {translate("header.signin","Sign in")}
                            </Button>
                        </Form.Item>
                        <Box
                            display="flex"
                            justifyContent="center"
                        >
                            <Link to="/reset-password" sx={{ fontWeight: "bold" }}>
                                {translate("login-page.forgetpass", "Forgot Password?")}
                            </Link>
                        </Box>
                    </Form>
                    <Divider>{translate("login-page.or","or")}</Divider>
                    <Button
                        type="default"
                        block
                        icon={<GoogleButton />}
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "25%",
                            margin: "15px auto 8px",
                        }}
                    >
                    </Button>
                    <div style={{ marginBottom: 20 }}>
                        <Text style={{ fontSize: 15 }}>
                            {translate("login-page.donthaveacc","Don't have an account?")}{" "}
                            <Link to="/register" sx={{ fontWeight: "bold" }}>
                                {translate("header.signup", "Sign Up")}
                            </Link>
                        </Text>
                    </div>
                    <div>
                        {error && (
                            <Text style={{ display: "block", fontSize: 15, fontWeight: 400, color: "#B30000"}}>
                                {error}
                            </Text>
                        )}
                        {banned && (
                            <>
                                <Text style={{ display: "block", fontSize: 15, fontWeight: 400, color: "#B30000"}}>
                                    {translate("login-page.banned", "You have been banned from BraudeOverflow")}
                                </Text>
                                <Text style={{ display: "block", fontSize: 15, fontWeight: 400, color: "#B30000"}}>
                                    {translate("login-page.banned", "Reason: ")} {banned.reason}
                                    {"\n"}
                                </Text>
                                <Text style={{ display: "block", fontSize: 15, fontWeight: 400, color: "#B30000"}}>
                                    {banned.unbanDate ?
                                        `${translate("login-page.banned", "Unban date: ")} ${banned.unbanDate}` :
                                        translate("login-page.permaban", "This is a permanent ban")
                                    }
                                </Text>
                            </>
                        )}
                    </div>
                </Card>
            {canFooterFit && (
                <Box
                    sx={{
                        position: "absolute",
                        bottom: 0,
                        width: "100%",
                        backgroundColor: "transparent",
                        color: "#fff",
                        textAlign: "center",
                    }}
                >
                    <LandingFooter />
                </Box>
            )}
        </Box>
    );
};
