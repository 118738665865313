import React, { useMemo, useState, useEffect } from 'react';
import { useGetLocale, useTranslate, useDataProvider, useGetIdentity, useLink } from '@refinedev/core';
import {Edit, TaskAlt, Delete, Cancel, Done, RemoveDone} from '@mui/icons-material';
import { Box, Stack, IconButton, Typography, TextField } from '@mui/material';

function SubTaskItem({ subtask, index, handleEditSubTask, updateSubTaskError, handleDeleteSubTask, stream, handleCompleteST, handleUncompleteST }: any) {
    const Link = useLink();
    const translate = useTranslate();
    const [updatedSubTask, setUpdatedSubTask] = useState<any>(subtask);
    const [edit, setEdit] = useState<boolean>(false);

    const handleEditForm = () => {
        if (edit) {
            handleEditSubTask(updatedSubTask, index - 1);
        }
        setEdit(!edit);
    };

    const handleDeletion = () => {
        handleDeleteSubTask(subtask);
        setEdit(false);
    };

    const handleCompleteSubTask = () => {
        if (!subtask.subCompleted) {
            handleCompleteST(subtask);
        } else {
            handleUncompleteST(subtask);
        }
    };

    return (
        <Box gap={2} width="30%" p={2}>
            <Stack direction="column" flexDirection="column" flexWrap="wrap" gap={1}>
                <Stack direction="row" gap={1} alignItems="center" width="100%">
                    <Typography fontSize={16} fontWeight={500} color="#11142D" style={{ textDecoration: subtask.subCompleted ? 'line-through' : '', marginRight: "5px" }}>
                        Subtask #{index}
                    </Typography>
                    {!subtask.subCompleted && (
                        <>
                            <IconButton size="small" style={{ padding: 0 }} onClick={handleEditForm}>
                                {edit ? <TaskAlt fontSize="small" /> : <Edit fontSize="small" />}
                            </IconButton>
                            <IconButton size="small" style={{ padding: 0 }} onClick={() => {
                                setEdit(false);
                                setUpdatedSubTask(subtask);
                            }}>
                                {edit && <Cancel fontSize="small" />}
                            </IconButton>
                        </>
                    )}
                    <IconButton size="small" style={{ padding: 0 }} onClick={handleDeletion}>
                        <Delete fontSize="small" />
                    </IconButton>
                    {!edit && stream !== 'addTask' && (
                        <>
                            {!subtask.subCompleted ? (
                                <IconButton size="small" style={{ padding: 0 }} onClick={handleCompleteSubTask}>
                                    <Done fontSize="small" />
                                </IconButton>
                            ) : (
                                <IconButton size="small" style={{ padding: 0 }} onClick={handleCompleteSubTask}>
                                    <RemoveDone fontSize="small" />
                                </IconButton>
                            )}
                        </>

                    )}
                </Stack>
                <Stack direction="row" gap={1} alignItems="center" width="100%">
                    <Typography fontSize={16} fontWeight={500} color="#11142D" style={{ textDecoration: subtask.subCompleted ? 'line-through' : ''  }}>Title: {!edit && subtask.subTitle}</Typography>
                    {edit && (
                        <TextField
                            color="info"
                            id="standard-basic"
                            variant="standard"
                            size="small"
                            type="text"
                            placeholder="Title..."
                            defaultValue={subtask.subTitle}
                            value={updatedSubTask.subTitle}
                            onChange={(e) => {
                                setUpdatedSubTask({
                                    ...updatedSubTask,
                                    subTitle: e.target.value,
                                });
                            }}
                            onKeyDown={(e) => {
                                e.stopPropagation()
                            }}
                        />
                    )}
                </Stack>
                {updateSubTaskError !== '' && (
                    <Typography fontSize={12} color="#FF0000">{updateSubTaskError}</Typography>
                )}
                <Typography fontSize={16} color="#11142D" style={{ textDecoration: subtask.subCompleted ? 'line-through' : ''  }}>Description: {!edit && subtask.subDescription}</Typography>
                {edit && (
                    <TextField
                        id="outlined-multiline-static"
                        type="text"
                        placeholder="Enter description..."
                        style={{
                            width: "100%",
                            border: "none",
                            outline: "none",
                            backgroundColor: "transparent",
                            color: "#11142D",
                            fontSize: "16px",
                            fontWeight: 500,
                            pointerEvents: "auto",
                        }}
                        multiline
                        color="info"
                        size="small"
                        rows={1}
                        defaultValue={subtask.subDescription}
                        value={updatedSubTask.subDescription}
                        onChange={(e) => {
                            setUpdatedSubTask({
                                ...updatedSubTask,
                                subDescription: e.target.value,
                            });
                        }}
                        onKeyDown={(e) => {
                            e.stopPropagation()
                        }}
                        onClick={(e) => {
                            e.stopPropagation()
                        }}
                    />
                )}
                <Stack direction="row" gap={1} alignItems="center" flexWrap="wrap">
                    <Typography style={{ fontSize: "clamp(10px, 1.3vw, 16px)", textDecoration: subtask.subCompleted ? 'line-through' : '' }} color="#11142D" flexWrap="wrap">
                        Link:
                        {edit ? (
                            <TextField
                                color="info"
                                id="standard-basic"
                                variant="standard"
                                size="small"
                                type="text"
                                placeholder="Title..."
                                defaultValue={subtask.subUrlAttachment}
                                value={updatedSubTask.subUrlAttachment}
                                onChange={(e) => {
                                    setUpdatedSubTask({
                                        ...updatedSubTask,
                                        subUrlAttachment: e.target.value,
                                    });
                                }}
                                onKeyDown={(e) => {
                                    e.stopPropagation()
                                }}
                            />
                        ) : (
                            <Typography
                                sx={{
                                    '&:hover': {
                                        textDecoration: 'underline',
                                    }
                                }}
                            >
                                <Link
                                    to={subtask.subUrlAttachment}
                                    target="_blank"
                                    style={{
                                        textDecoration: 'none',
                                        color: "#11142D",
                                    }}
                                > {subtask.subUrlAttachment}
                                </Link>
                            </Typography>
                        )}
                    </Typography>
                </Stack>
            </Stack>
        </Box>
    );
}

export default SubTaskItem;
