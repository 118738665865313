const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const allowedDomains = [
    'gmail.com',
    'yahoo.com',
    'outlook.com',
    'hotmail.com',
    'aol.com',
    'icloud.com',
    'mail.com',
    'protonmail.com',
    'zoho.com',
    'yandex.com',
    'gmx.com',
    'live.com',
    'e.braude.ac.il',
    'braude.ac.il',
    'obu.co.il',
];

export {
    emailRegex,
    allowedDomains
}
