import { CourseProps, DisplayCoursesProps } from '../../interfaces/apps';
import React, { useEffect, useState } from 'react';
import { useDataProvider, useTranslate } from '@refinedev/core';
import { axios } from '../../App';
import { AppBar, Box, Card, Stack, Toolbar, Typography } from '@mui/material';
import CustomButton from '../common/CustomButton';
import { Delete, Edit } from '@mui/icons-material';

function DisplayCourses({ tutorCourses, handleCourseEdit }: DisplayCoursesProps) {
    const [courses, setCourses] = useState(tutorCourses);
    const dataProvider = useDataProvider();
    const translate = useTranslate();

    useEffect(() => {
        setCourses(tutorCourses);
    }, [tutorCourses]);

    const handleDeleteCourse = async (courseId: number) => {
        try {
            await axios.delete(`${dataProvider().getApiUrl()}/my-profile/tutor`, {
                data: {
                    courseId
                }
            });
            setCourses(courses.filter((course: CourseProps) => course.courseId !== courseId))
        } catch (err) {
            console.error(err);
        }
    }
    return (
        <Box
            flex={1}
            sx={{
                gap: 2.5,
                marginTop: {md: "5px"},
                marginLeft: {xs: "10px", md: "10px"},
                overflowX: "auto",
                "& > div": {
                    minWidth: "100%",
                },
            }}
        >
            {courses.length >= 0 ? (
                <Box
                    flex={1}
                    display="flex"
                    flexDirection="column"
                    gap={2.5}
                >
                    <Box mt={2} mb={2} textAlign="center" maxWidth={{ xs: '90%', md: '100%' }}>
                        <AppBar position="static" color="primary" style={{ borderRadius: '10px' }}>
                            <Toolbar>
                                <Typography variant="h6" sx={{ flexGrow: 1 }}>
                                    {translate("myprofilepage.yourcourses", "Your Courses")}
                                </Typography>
                                <Typography variant="subtitle1">
                                    {courses.length} {courses.length === 1 ? translate("myprofilepage.coursessingular", "Course") : translate("myprofilepage.coursescount", "Courses")}
                                </Typography>
                            </Toolbar>
                        </AppBar>
                    </Box>
                    <Stack>
                        <Box
                            sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 10
                            }}
                        >
                            {courses?.map((course: CourseProps) => (
                                <Card
                                    style={{
                                        padding: "5px",
                                        paddingLeft: "10px",
                                        width: "300px",
                                        borderRadius: "10px",
                                        boxShadow: '0px 0px 5px #000000',
                                        marginLeft: "35px",
                                        marginBottom: "5px",
                                        height: "455px"

                                    }}
                                >
                                    <Stack
                                        direction="row"
                                        flexWrap="wrap"
                                        alignItems="center"
                                        pb={3}
                                        gap={3}
                                        marginTop={2}
                                    >
                                        <Stack>
                                            <Stack
                                                direction="column"
                                                flexWrap="wrap"
                                                width="300px"
                                                textAlign="center"
                                            >
                                                <Typography fontSize={18} fontWeight={700} color="#11142D">
                                                    {course.courseName}
                                                </Typography>
                                            </Stack>
                                            <Stack
                                                direction="column"
                                                flexWrap="wrap"
                                                width="300px"
                                                textAlign="center"
                                            >
                                                <Typography fontSize={14} fontWeight={300} color="#11142D">
                                                    {course.courseId}
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                        <Stack
                                            direction="column"
                                            flexWrap="wrap"
                                            width="300px"
                                        >
                                            <Typography fontSize={16} fontWeight={500} color="#11142D" pl={2} px={2}>
                                                {translate("myprofilepage.grade", "Grade")}: {course.grade}
                                            </Typography>
                                        </Stack>
                                        <Stack
                                            direction="column"
                                            flexWrap="wrap"
                                            width="300px"
                                        >
                                            <Typography fontSize={16} fontWeight={500} color="#11142D" pl={2} px={2}>
                                                {translate("myprofilepage.platform", "Platform")}: {course.platform}
                                            </Typography>
                                        </Stack>
                                        <Stack
                                            direction="column"
                                            flexWrap="wrap"
                                            width="300px"
                                        >
                                            <Typography fontSize={16} fontWeight={500} color="#11142D" pl={2} px={2}>
                                                {translate("myprofilepage.method", "Method")}: {course.method}
                                            </Typography>
                                        </Stack>
                                        <Stack
                                            direction="column"
                                            flexWrap="wrap"
                                            width="300px"
                                        >
                                            <Typography fontSize={16} fontWeight={500} color="#11142D" pl={2} px={2}>
                                                {translate("myprofilepage.tablet", "Tablet")}: {course.tablet ? translate("myprofilepage.yes", "Yes") : translate("myprofilepage.no", "No")}
                                            </Typography>
                                        </Stack>
                                        <Stack
                                            direction="column"
                                            flexWrap="wrap"
                                            width="300px"
                                        >
                                            <Typography fontSize={16} fontWeight={500} color="#11142D" pl={2} px={2}>
                                                {translate("myprofilepage.price", "Price per hour")}: {course.price !== null && course.price.toString() !== '' ? course.price : translate("myprofilepage.notAvailable", "N/A")} ₪
                                            </Typography>
                                        </Stack>
                                        {/*<Stack*/}
                                        {/*    direction="column"*/}
                                        {/*    flexWrap="wrap"*/}
                                        {/*    width="300px"*/}
                                        {/*>*/}
                                        {/*    <Stack*/}
                                        {/*        direction="row"*/}
                                        {/*        spacing="1"*/}
                                        {/*        alignItems="center"*/}
                                        {/*        justifyContent="center"*/}
                                        {/*        color="#b58605"*/}
                                        {/*        fontSize={39}*/}
                                        {/*    >*/}
                                        {/*        <StarRating rating={course.rating}/>*/}
                                        {/*    </Stack>*/}
                                        {/*</Stack>*/}
                                        <Stack
                                            direction="column"
                                            flexWrap="wrap"
                                            width="140px"
                                            marginTop="60px"
                                        >
                                            <CustomButton
                                                type="button"
                                                title={translate("myprofilepage.editButton", "Edit")}
                                                backgroundColor="inherit"
                                                color="#475BE8"
                                                icon=<Edit/>
                                                handleClick={() => handleCourseEdit(course)} // Edit form + on submit, change state by calling handleEdit().
                                            />
                                        </Stack>
                                        <Stack
                                            direction="column"
                                            flexWrap="wrap"
                                            width="100px"
                                            marginTop="60px"
                                        >
                                            <CustomButton
                                                type="button"
                                                title={translate("myprofilepage.deleteButton", "Delete")}
                                                backgroundColor="inherit"
                                                color="#B30000"
                                                icon=<Delete/>
                                                handleClick={() => handleDeleteCourse(course.courseId)}
                                            />
                                        </Stack>
                                    </Stack>
                                </Card>
                            ))}
                        </Box>
                    </Stack>
                </Box>
            ) : (
                <Box mt={1}>
                    <Typography fontSize={20} fontWeight={700} color="#11142D" textAlign="center">{translate("myprofilepage.noCourses", "You currently teach no courses. Click on Add Courses to begin!")}</Typography>
                </Box>
            )}
        </Box>
    );
}
export default DisplayCourses;
