import React from 'react';
import { Box, Typography, Avatar } from '@mui/material';

function HUserButton({fullName, avatar}: any) {
    return (
        <Box display="flex" flexDirection="row" flexWrap="nowrap" gap={1} alignItems="center">
            <Avatar src={avatar} alt="UserImage"/> <Typography fontSize={16} color="#11142D">{fullName}</Typography>
        </Box>
    );
}

export default HUserButton;
