import React, { useEffect, useState } from 'react';
import { useTranslate, useGetIdentity, useLink, useGetLocale, useDataProvider } from '@refinedev/core';
import {
    Autocomplete,
    Box,
    Divider,
    IconButton,
    MenuItem,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import {
    Close,
    AddTask,
    AddLink,
    Delete,
    AddComment,
} from "@mui/icons-material";
import { CourseObjectProps } from "../../interfaces/apps";
import HUserButton from "../common/HUserButton";
import { AddSubTaskForm, CustomButton } from "../index";
import SubTaskItem from "./SubTaskItem";
import { axios } from "../../App";

// Date picker
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from "dayjs";

function AddTaskForm({ handleOpenTask, handleAddTask }: any) {
    const translate = useTranslate();
    const locale = useGetLocale();
    const currDir = locale() === 'en' ? 'ltr' : 'rtl';

    const { data } = useGetIdentity();
    const profile: any = data ?? [];
    const [courses, setCourses] = useState<CourseObjectProps[] | []>([]);
    /* Task Form States */
    const [taskItem, setTaskItem] = useState<any>({
        title: '',
        description: '',
        course: '',
        dueDate: null,
        category: translate("taskmanager-taskForm.other","Other"),
        priority: 2,
        status: translate("taskmanager-taskForm.notStarted","Not Started"),
    });
    const [collaborators, setCollaborators] = useState<any[]>([]);
    const [urlAttachments, setUrlAttachments] = useState<string[]>([]);
    const [taskError, setTaskError] = useState<string>('');
    const [urlToAdd, setUrlToAdd] = useState<string>('');
    const [tags, setTags] = useState<string[]>([]);
    const [tagToAdd, setTagToAdd] = useState<string>('');

    /* Subtask States*/
    const [subtaskToAdd, setSubtaskToAdd] = useState<any>({ subTitle: '', subDescription: '', subUrlAttachment: '' });
    const [subTasks, setSubTasks] = useState<any[]>([]);
    const [subtaskError, setSubtaskError] = useState<string>('');
    /* End of Task Form States*/

    const dataProvider = useDataProvider();
    const Link = useLink();


    useEffect(() => {
        setCourses(JSON.parse(localStorage.getItem('courses') ?? '[]'));
    });

    const handleCourseChange = (_, value: CourseObjectProps | null) => {
        if (value) {
            setTaskItem({...taskItem, course: value.name});
        }
    };

    const handleDateChange = (date: Dayjs | null) => {
        setTaskItem({...taskItem, dueDate: dayjs(date) });
    };

    const handleAddSubTask = () => {
        if (subtaskToAdd.subTitle === '') {
            setSubtaskError(translate("taskmanager-taskForm.subtaskError","Please enter a title for the subtask"));
            return;
        }
        setSubtaskError('');
        setSubtaskToAdd({ subTitle: '', subDescription: '', subUrlAttachment: '' });
        setSubTasks(subTasks => [...subTasks, subtaskToAdd]);
    };

    const handleEditSubTask = (updatedSubTask, index) => {
        subTasks[index] = updatedSubTask;
    };

    const handleDeleteSubTask = (index) => {
      setSubTasks(subTasks.filter((subtask, i) => i !== index - 1));
    };

    const handleSubmitTask = () => {
        if (taskItem.title === '') {
            setTaskError(translate("taskmanager-taskForm.taskError","Please enter a title for the task"));
            return;
        }
        setTaskError('');

        taskItem.collaborators = collaborators;
        taskItem.urlAttachments = urlAttachments;
        taskItem.tags = tags;
        taskItem.subtasks = subTasks;
        taskItem.taskOwner = {
            _id: profile.userId,
            fullName: profile.firstName + " " + profile.lastName,
            avatar: profile.avatar
        };

        const submitTask = async () => {
            const response = await axios.post(`${dataProvider().getApiUrl()}/applications/todo`, {
                taskItem
            });
            const task = {...response.data, collaborators: collaborators}
            handleAddTask(task);
            handleOpenTask();
        }

        submitTask();
    };

    return (
        <Box>
            <Stack direction="row" flexDirection="row" gap={2} alignItems="center" width="100%">
                <MenuItem
                    disableRipple
                    key="newTaskKey"
                    selected={false}
                    style={{ pointerEvents: 'none' }}
                    sx={{
                        border: "1px solid #E0E0E0",
                        minHeight: "10vh",
                        width: "100%",
                        '&:hover': {
                            backgroundColor: 'inherit',
                        },
                    }}
                >
                    <Stack direction="row" flexDirection="row" gap={2} alignItems="center" sx={{ width: '100%' }}>
                        <Box
                            alignItems="flex-start"
                            style={{ pointerEvents: 'auto' }}
                            flexBasis={0} flexGrow={1} flexShrink={1} maxWidth="4.9%"
                            display="flex" justifyContent="center"
                        >
                            <IconButton onClick={handleOpenTask}>
                                <Close />
                            </IconButton>
                        </Box>
                        <Divider orientation="vertical" flexItem style={{ backgroundColor: '#b7b1b1' }} />
                        <Box
                            flexBasis={0} flexGrow={1} flexShrink={1} maxWidth="11.6%"
                            style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                        >
                            <TextField
                                type="text"
                                placeholder={translate("taskmanager-taskForm.title","Title")}
                                color="info"
                                style={{
                                    width: "100%",
                                    border: "none",
                                    outline: "none",
                                    backgroundColor: "transparent",
                                    color: "#11142D",
                                    fontSize: "16px",
                                    fontWeight: 500,
                                    pointerEvents: "auto",
                                }}
                                onChange={(e) => setTaskItem({...taskItem, title: e.target.value})}
                                onKeyDown={(e) => {
                                    e.stopPropagation()
                                }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                            />
                            {taskError && (
                                <Typography fontSize={12} color="#FF0000">{taskError}</Typography>
                            )}
                        </Box>
                        <Divider orientation="vertical" flexItem style={{ backgroundColor: '#b7b1b1' }} />
                        <Box
                            style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', pointerEvents: 'auto' }}
                            flexBasis={0} flexGrow={1} flexShrink={1} maxWidth="9.7%"
                            display="flex" justifyContent="center"
                            onMouseOver={(e) => e.currentTarget.style.overflow = 'auto'}
                            onMouseOut={(e) => e.currentTarget.style.overflow = 'hidden'}
                        >
                            <Autocomplete
                                id="combo-box-demo"
                                options={courses}
                                getOptionLabel={(course: CourseObjectProps) => `${course.name} ${course.id}`}
                                sx={{ width: 400 }}
                                onChange={handleCourseChange}
                                renderInput={(params) =>
                                    <TextField
                                        color="info"
                                        sx={{ pointerEvents: 'auto' }}
                                        {...params}
                                        placeholder={translate("taskmanager-taskForm.coursePlaceholder","Search by course name or ID")}
                                        size="medium"
                                        onKeyDown={(e) => {
                                            e.stopPropagation()
                                        }}
                                    />}
                            />
                        </Box>
                        <Divider orientation="vertical" flexItem style={{ backgroundColor: '#b7b1b1' }} />
                        <Box
                            style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', scrollbarWidth: 'none' }}
                            flexBasis={0} flexGrow={1} flexShrink={1} maxWidth="11.6%"
                            display="flex" justifyContent="center"
                        >
                            <Typography
                                fontSize={16}
                            >
                                {translate("taskmanager-taskForm.notStarted","Not Started")}
                            </Typography>
                        </Box>
                        <Divider orientation="vertical" flexItem style={{ backgroundColor: '#b7b1b1' }} />
                        <Box
                            style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', scrollbarWidth: 'none' }}
                            flexBasis={0} flexGrow={1} flexShrink={1} maxWidth="11.6%"
                            display="flex" justifyContent="center"
                        >
                            <TextField
                                sx = {{
                                    width: "200px",
                                    pointerEvents: "auto",
                                }}
                                id="outlined-number"
                                select
                                color="info"
                                placeholder={translate("taskmanager-taskForm.categoryPlaceholder","Category")}
                                label={translate("taskmanager-taskForm.categoryPlaceholder","Category")}
                                defaultValue="Other"
                                margin="normal"
                                value={taskItem.category}
                                onChange={(e) => setTaskItem({...taskItem, category: e.target.value})}
                                onKeyDown={(e) => {
                                    e.stopPropagation()
                                }}
                            >
                                <MenuItem key='assignment' value='Assignment'>{translate("taskmanager-taskForm.assignment","Submission Assignment")}</MenuItem>
                                <MenuItem key='lab' value='Lab'>{translate("taskmanager-taskForm.lab","Lab Work")}</MenuItem>
                                <MenuItem key='practice' value='Practice'>{translate("taskmanager-taskForm.practice","Practice")}</MenuItem>
                                <MenuItem key='workshop' value='Workshop'>{translate("taskmanager-taskForm.workshop","Workshop")}</MenuItem>
                                <MenuItem key='exams' value='Exam Preparation'>{translate("taskmanager-taskForm.examPrep","Exam Preparation")}</MenuItem>
                                <MenuItem key='other' value='Other'>{translate("taskmanager-taskForm.other","Other")}</MenuItem>
                            </TextField>
                        </Box>
                        <Divider orientation="vertical" flexItem style={{ backgroundColor: '#b7b1b1' }} />
                        <Box
                            style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', scrollbarWidth: 'none' }}
                            flexBasis={0} flexGrow={1} flexShrink={1} maxWidth="9.7%"
                            display="flex" justifyContent="center"
                        >
                            <TextField
                                sx = {{
                                    width: "200px",
                                    pointerEvents: "auto",
                                }}
                                id="outlined-number"
                                select
                                color="info"
                                placeholder={translate("taskmanager-taskForm.priorityPlaceholder","Priority")}
                                label={translate("taskmanager-taskForm.priorityPlaceholder","Priority")}
                                defaultValue="2"
                                margin="normal"
                                onChange={(e) => setTaskItem({...taskItem, priority: Number(e.target.value)})}
                                onKeyDown={(e) => {
                                    e.stopPropagation()
                                }}
                            >
                                <MenuItem key='1' value='1'>{translate("taskmanager-taskForm.priorityHigh","High")}</MenuItem>
                                <MenuItem key='2' value='2'>{translate("taskmanager-taskForm.priorityMedium","Medium")}</MenuItem>
                                <MenuItem key='3' value='3'>{translate("taskmanager-taskForm.priorityLow","Low")}</MenuItem>
                            </TextField>
                        </Box>
                        <Divider orientation="vertical" flexItem style={{ backgroundColor: '#b7b1b1' }} />
                        <Box
                            style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', scrollbarWidth: 'none' }}
                            flexBasis={0} flexGrow={1} flexShrink={1} maxWidth="11.6%"
                            display="flex" justifyContent="center"
                        >
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    disablePast
                                    openTo="day"
                                    views={['year', 'month', 'day']}
                                    inputFormat="DD-MM-YYYY"
                                    value={dayjs(taskItem.dueDate)}
                                    onChange={handleDateChange}
                                    renderInput={(params) =>
                                        <TextField
                                            color="info"
                                            id="standard-basic"
                                            variant="standard"
                                            size="small"
                                            sx={{
                                                pointerEvents: "auto",
                                                marginRight: "10px",
                                            }}
                                            {...params}
                                            onKeyDown={(e) => {
                                                e.stopPropagation()
                                            }}
                                        />}
                                />
                            </LocalizationProvider>
                        </Box>
                        <Divider orientation="vertical" flexItem style={{ backgroundColor: '#b7b1b1' }} />
                        <Box
                            display="flex" flexDirection="row" gap={2}
                            style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', scrollbarWidth: 'none', pointerEvents: 'auto' }}
                            flexBasis={0} flexGrow={1} flexShrink={1} maxWidth="100%"
                        >
                            <Stack direction="row" flexDirection="row" flexWrap="wrap" alignItems="center" display="flex" justifyContent="flex-start">
                                <Stack direction="row" flexDirection="row" flexWrap="wrap" gap={1} alignItems="center" display="flex" justifyContent="flex-start">
                                    {tags.map((tag) =>
                                        <Stack direction="row" flexDirection="row" flexWrap="nowrap" alignItems="center">
                                            <IconButton onClick={() => {
                                                tags.splice(tags.indexOf(tag), 1);
                                                setTags(tags);
                                            }}>
                                                <Delete />
                                            </IconButton>
                                            <Typography fontSize={14} color="#11142D">
                                                {tag}
                                            </Typography>
                                        </Stack>
                                    )}
                                </Stack>
                                <Stack direction="row" flexDirection="row" flexWrap="nowrap" alignItems="center">
                                    <TextField
                                        color="info"
                                        id="standard-basic"
                                        variant="standard"
                                        size="small"
                                        type="text"
                                        placeholder={translate("taskmanager-taskForm.addTagPlaceholder","Add Tag")}
                                        sx={{
                                            width: "30%"
                                        }}
                                        value={tagToAdd}
                                        onChange={(e) => setTagToAdd(e.target.value)}
                                        onKeyDown={(e) => {
                                            e.stopPropagation()
                                        }}
                                    />
                                    <IconButton onClick={() => {
                                        if (tagToAdd === '') return;
                                        tags?.push(tagToAdd);
                                        setTags(tags);
                                        setTagToAdd('');
                                    }}>
                                        <AddComment />
                                    </IconButton>
                                </Stack>
                            </Stack>
                        </Box>
                    </Stack>
                </MenuItem>
            </Stack>
            <Box
                width="100%"
                minHeight="30vh"
                border="1px solid #E0E0E0"
                display="flex"
                justifyContent="space-between"
                gap={3}
                pt={1}
            >
                <Box width="40%" pl={1} pr={1}>
                    <Stack direction="row" flexDirection="row" flexWrap="nowrap" gap={1} alignItems="center">
                        <Typography fontSize={16} fontWeight={500} color="#11142D">
                            {translate("taskmanager-taskForm.taskIssuer","Task Issuer:")}
                        </Typography>
                        <HUserButton fullName={profile.firstName + " " + profile.lastName } avatar={profile.avatar} />
                    </Stack>
                    <Stack direction="column" mt={3} gap={1}>
                        <Typography fontSize={16} fontWeight={500} color="#11142D">
                            {translate("taskmanager-taskForm.description","Description:")}
                        </Typography>
                        <TextField
                            id="outlined-multiline-static"
                            type="text"
                            placeholder={translate("taskmanager-taskForm.descriptionPlaceholder","Enter task description...")}
                            style={{
                                width: "100%",
                                border: "none",
                                outline: "none",
                                backgroundColor: "transparent",
                                color: "#11142D",
                                fontSize: "16px",
                                fontWeight: 500,
                                pointerEvents: "auto",
                            }}
                            multiline
                            color="info"
                            rows={3}
                            value={taskItem.description}
                            onChange={(e) => setTaskItem({...taskItem, description: e.target.value})}
                            onKeyDown={(e) => {
                                e.stopPropagation()
                            }}
                            onClick={(e) => {
                                e.stopPropagation()
                            }}
                        />
                    </Stack>
                    <Stack direction="row" flexDirection="column" flexWrap="wrap" mt={2} gap={1}>
                        <Typography fontSize={16} fontWeight={500} color="#11142D">
                            {translate("taskmanager-taskForm.linkAttached","Link Attachments:")}
                        </Typography>
                        <Stack direction="column" flexDirection="column" flexWrap="wrap" gap={2} width="100%">
                            {urlAttachments?.map((url) =>
                                <Typography
                                    sx={{
                                        '&:hover': {
                                            textDecoration: 'underline',
                                        }
                                    }}
                                >
                                    <IconButton onClick={() => {
                                        urlAttachments?.splice(urlAttachments.indexOf(url), 1);
                                        setUrlAttachments(urlAttachments);
                                    }}>
                                        <Delete />
                                    </IconButton>
                                    <Link
                                        to={url}
                                        target="_blank"
                                        style={{
                                            textDecoration: 'none',
                                            color: '#11142D',
                                            marginLeft: "7px",
                                        }}
                                    >
                                        {url}
                                    </Link>
                                </Typography>
                            )}
                        </Stack>
                        <Stack direction="row" flexDirection="row" flexWrap="nowrap">
                            <TextField
                                color="info"
                                id="standard-basic"
                                variant="standard"
                                type="text"
                                placeholder={translate("taskmanager-taskForm.linkAttachPlaceholder","Enter Link Attachment")}
                                value={urlToAdd}
                                onChange={(e) => setUrlToAdd(e.target.value)}
                                onKeyDown={(e) => {
                                    e.stopPropagation()
                                }}
                            />
                            <IconButton onClick={() => {
                                if (urlToAdd === '') return;
                                urlAttachments?.push(urlToAdd);
                                setUrlAttachments(urlAttachments);
                                setUrlToAdd('');
                            }}>
                                <AddLink />
                            </IconButton>
                        </Stack>
                    </Stack>
                </Box>
                <Divider orientation="vertical" flexItem style={{ backgroundColor: "#E0E0E0" }} />
                <Box width="75%">
                    <Stack direction="row" flexDirection="row" flexWrap="nowrap" justifyContent="space-between" alignItems="center" width="100%">
                        <Typography fontSize={16} color="#11142D">{translate("taskmanager-taskForm.createTaskMSG","Create task to be able to invite collaborators.")}</Typography>
                        <Stack direction="row" flexDirection="row" flexWrap="wrap" alignItems="center" display="flex" justifyContent="flex-end" pr={2}>
                            <CustomButton
                                type = "submit"
                                title="Save"
                                backgroundColor="#475BE8"
                                color="#FCFCFC"
                                handleClick={handleSubmitTask}
                                icon = {<AddTask />}
                            />
                        </Stack>
                    </Stack>
                    <Divider orientation="horizontal" flexItem style={{ backgroundColor: "#E0E0E0", marginTop: "10px", marginBottom: "10px" }} />
                    <Stack direction="column" flexDirection="column" flexWrap="nowrap" gap={2} alignItems="center" pr={2} width="100%">
                        <Stack direction="row" flexWrap="nowrap" gap={2} alignItems="center" justifyContent="flex-start" width="100%">
                            <Box flexGrow={1} />
                            <Typography fontSize={20} fontWeight={500} color="#11142D">
                                {translate("taskmanager-taskForm.subtask","Sub Tasks")}
                            </Typography>
                            <Box flexGrow={1} />
                        </Stack>
                    </Stack>
                    <Stack direction="row" flexDirection="row" flexWrap="wrap" gap={2} alignItems="center" display="flex" justifyContent="flex-start" width="100%">
                        <Stack direction="row" flexDirection="row" flexWrap="wrap" justifyContent="flex-start" width="100%" mt={3} gap={3}>
                            {subTasks?.map((subtask: any, index: number) => (
                                <SubTaskItem
                                    subtask={subtask}
                                    index={index + 1}
                                    stream="addTask"
                                    handleEditSubTask={handleEditSubTask}
                                    handleDeleteSubTask={handleDeleteSubTask}
                                />
                            ))}
                            <AddSubTaskForm
                                subtaskToAdd={subtaskToAdd}
                                setSubtaskToAdd={setSubtaskToAdd}
                                subtaskError={subtaskError}
                                handleAddSubTask={handleAddSubTask}
                            />
                        </Stack>
                    </Stack>
                </Box>
            </Box>
        </Box>
    );
}

export default AddTaskForm;
