import React, { useEffect, useState } from 'react';
import { useDataProvider, useLink } from '@refinedev/core';
import 'devextreme/dist/css/dx.material.blue.dark.css';
import '../../assets/scheduler-styles.scss';
import { dayOfWeekNames, groupTypeToColor } from './scheduler';
import { CourseObjectProps, GroupProps } from '../../interfaces/apps';
import AnimateHeight from 'react-animate-height';
import domtoimage from 'dom-to-image';
import { saveAs } from 'file-saver';
import Select from 'react-select';
import { RiScreenshot2Fill } from 'react-icons/ri';
import { IoContract, IoExpand } from 'react-icons/io5';
import { logoNonCollapsed } from '../../assets/';
import { Box } from '@mui/material';
import { axios } from '../../App';

interface MenuProps {
    courses: CourseObjectProps[];
    groups: GroupProps[];
    setGroups: (g: GroupProps[]) => void;
    setIsHidden: (b: boolean) => void;
    isHidden: boolean;
    isMobile: boolean;
}

const semesterMapping = [
    { value: 1, label: 'א' },
    { value: 2, label: 'ב' },
    { value: 3, label: 'קיץ' },
];

const ScheduleMenu = (props: MenuProps) => {
    const Link = useLink();
    const dataProvider = useDataProvider();
    const [courseIds, setCourseIds] = useState<number[]>(
      JSON.parse(localStorage.getItem('courseIds') || '[]')
    );
    const [semester, setSemester] = useState<number>(1);
    const [selectedCourseId, setSelectedCourseId] = useState<number>(-1);

    useEffect(() => {
        if (!props.groups.length) {
            setCourseIds([]);
            localStorage.setItem('courseIds', '');
        }
    }, [props.groups.length]);

    const findCourse = async (cid: number) => {
        const c = props.courses.find(c => c.id === cid);
        if (c && !courseIds.includes(c.id)) {
            const res = await axios.get(`${dataProvider().getApiUrl()}/courses/groups/${c.id}`);
            const groups: GroupProps[] = res.data;
            props.setGroups(
              [...groups, ...props.groups].sort((a, b) =>
                a.type.localeCompare(b.type)
              )
            );
            setCourseIds([c.id, ...courseIds]);
            localStorage.setItem('courseIds', JSON.stringify([c.id, ...courseIds]));
            setSelectedCourseId(c.id);
        }
    };

    const renderGroups = (groups: GroupProps[]) => {
        if (!groups.length) {
            return <div className="menu-group-empty">הקורס לא נלמד בסמסטר זה</div>;
        }
        return groups.map(g => (
          <div
            style={{
                backgroundColor: g.selected ? 'red' : groupTypeToColor[g.type],
            }}
            key={g._id}
            className="menu-group"
            onClick={_ => {
                g.selected = !g.selected;
                props.setGroups([...props.groups]);
            }}
          >
        <span className="menu-group-center">
          {g.lecturerName} ({g.type})
        </span>
              {g.classes.map(c => (
                <div key={`${g._id}-${c.day}-${c.startHour}`}>
                    <span className="menu-group-right">{dayOfWeekNames[c.day]}</span>
                    <span className="menu-group-center">
              {c.startHour} - {c.endHour}
            </span>
                    <span className="menu-group-left">{c.classroom}</span>
                </div>
              ))}
          </div>
        ));
    };

    const alertMessage = (e: React.MouseEvent) => {
        e.stopPropagation();
        alert('לא נמצא סילבוס לקורס זה בתחנת המידע');
    };

    const ToggleSize = () => {
        if (props.isHidden) {
            return (
              <IoContract
                className="menu-toggle"
                onClick={() => props.setIsHidden(false)}
              />
            );
        }
        return (
          <IoExpand
            className="menu-toggle"
            onClick={() => props.setIsHidden(true)}
          />
        );
    };

    const Controls = () => (
      <div className="menu-controls">
          <Select
            placeholder="חפש קורס..."
            onChange={e => findCourse(e?.value || -1)}
            filterOption={(candidate, input) =>
              candidate.label.includes(input) ||
              candidate.label.replaceAll('"', '').includes(input)
            }
            options={props.courses.map(c => ({
                value: c.id,
                label: `${c.name} - ${c.id}`,
            }))}
          />
          <Select
            placeholder="בחר סמסטר"
            options={semesterMapping}
            onChange={e => setSemester(e?.value || 1)}
            value={semesterMapping.find(sem => sem.value === semester)}
          />
      </div>
    );

    return (
      <div
        className={`menu-container ${
          props.isHidden ? 'menu-container-hidden' : ''
        }`}
      >
          {!props.isMobile && (
            <RiScreenshot2Fill
              className="save-schedule"
              onClick={async _ => {
                  const blob = await domtoimage.toBlob(
                    document.getElementsByClassName('scheduler-container')[0]
                  );
                  saveAs(blob, 'schedule.png');
              }}
            />
          )}
          {!props.isMobile && <ToggleSize />}
          <Link to="/dashboard">
              <Box sx={{ display: "flex", justifyContent: "center", marginBottom: "20px" }}>
                  <img src={ logoNonCollapsed } style={{ width: "40%" }} alt="BraudeOverflow"/>
              </Box>
          </Link>
          <Controls />

          <div className="menu">
              {courseIds.map(cid => {
                  const course = props.courses.find(cn => cn.id === cid);
                  if (!course) return null;
                  return (
                    <div key={cid.toString()}>
                        <div
                          className="menu-course"
                          onClick={_ =>
                            setSelectedCourseId(selectedCourseId === cid ? -1 : cid)
                          }
                        >
                <span>
                  {course.name} - {cid}
                </span>
                            {course.syllabus ? (
                              <a
                                onClick={e => e.stopPropagation()}
                                href={course.syllabus}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                  סילבוס
                              </a>
                            ) : (
                              <a onClick={alertMessage}>סילבוס</a>
                            )}
                        </div>
                        <AnimateHeight
                          height={selectedCourseId === cid ? 'auto' : 0}
                          duration={500}
                        >
                            {renderGroups(
                              props.groups.filter(
                                g => g.courseId === cid && g.semester === semester
                              )
                            )}
                        </AnimateHeight>
                    </div>
                  );
              })}
          </div>
      </div>
    );
};

export default ScheduleMenu;
