import React from 'react';
import { Box, Button, FormControl, FormHelperText, MenuItem, Stack, TextField, Typography } from '@mui/material';
import {
  CalendarMonth,
  Email,
  ManageAccounts,
  PersonPin,
  Phone,
  SaveAlt,
  School,
  UploadFile
} from '@mui/icons-material';
import { CustomButton } from '../index';
import { EditProfileFormProps } from '../../interfaces/profile';
import { UserStorage } from '../../interfaces/common';
import { parseJwt } from '../../utils/parse-jwt';

import { useGetIdentity, useGetLocale, useTranslate } from '@refinedev/core';

// Date picker
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

function EditProfileForm({ register, handleSubmit, handleAvatarChange, handleResetAvatar, handleDateChange, dateToPass, formLoading, onFinishHandler, avatar } : EditProfileFormProps) {
    const { data } = useGetIdentity<UserStorage>();
    const myProfile: any = data ?? [];
    const isDefaultAvatar = avatar?.name === 'DefaultAvatar';

    // To verify token and prevent CSRF/token tampering.
    const token = localStorage.getItem('googleToken');
    const decodedToken = token ? parseJwt(token) : null;
    const issuerClaim = 'https://accounts.google.com';
    const translate = useTranslate();

    const locale = useGetLocale();
    const currentLocale = locale();
    const currDir = currentLocale === 'en' ? 'ltr' : 'rtl';

    return (
        <Box dir={currDir}>
            <Typography mt={5} fontSize={25} fontWeight={700} color="#11142D">
                    {currDir === 'ltr'
                        ? ` ${myProfile.firstName} ${myProfile.lastName + translate("myprofilepage.profiletitle", "'s Profile")}`
                        : ` ${translate("myprofilepage.profiletitle", "'s Profile")} ${myProfile.firstName} ${myProfile.lastName}`}
            </Typography>

            <Box mt="5px" borderRadius="15px" padding="10px" bgcolor="inherit">
                <form onSubmit={handleSubmit(onFinishHandler)}>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: { xs: "column", md: "row" },
                            gap: 2.5,
                        }}
                    >
                        <Stack
                            direction="column"
                            gap={1}
                            justifyContent="center"
                            mb={2}
                        >
                            {data && (
                                <img
                                    src={isDefaultAvatar ? data.originalAvatar : data.avatar}
                                    width={300}
                                    height={270}
                                    style={{ marginTop: "30px", borderRadius: "15px" }}
                                    alt="Profile Avatar"
                                />
                            )}
                            <Stack direction="column">
                                <Button
                                    component="label"
                                    sx={{
                                        width: "300px",
                                        color: "#FCFCFC",
                                        textTransform: "capitalize",
                                        fontSize: 16,
                                        backgroundColor: "#475BE8",
                                        "&:hover": {
                                            backgroundColor: "#1E36E8"
                                        },
                                    }}
                                >
                                    <UploadFile/>  {translate("myprofilepage.uploadButton", "Upload")}
                                    <input
                                        hidden
                                        accept="image/png, image/jpeg, image/jpg"
                                        type="file"
                                        onChange={(e) => {
                                            handleAvatarChange(e.target.files![0])
                                        }}
                                    />
                                </Button>
                                {/*For Google authenticated users */}
                                {decodedToken && decodedToken.iss === issuerClaim &&  (
                                    <Button
                                        component="label"
                                        sx={{
                                            width: "300px",
                                            color: "#808191",
                                            textTransform: "capitalize",
                                            fontSize: 16,
                                            backgroundColor: "inherit",
                                            "&:hover": {
                                                opacity: 0.7,
                                                transition: "all 0.3s ease-in-out",
                                            },
                                        }}
                                        onClick={handleResetAvatar}
                                    >
                                        {translate("myprofilepage.defaultButton", "Default Image")}
                                    </Button>
                                )}

                            </Stack>
                            {/*If there is an image, and it's not the Google photo, then display the file's name.*/}
                            {avatar?.name && !isDefaultAvatar && (
                                <Typography
                                    fontSize={14}
                                    color="#808191"
                                    sx={{ wordBreak: "break-all" }}
                                >
                                    {avatar.name}
                                </Typography>
                            )}
                        </Stack>
                        <Box
                            flex={1}
                            sx={{
                                marginTop: { md: "58px" },
                                marginLeft: { xs: "20px", md: "0px" },
                            }}
                        >
                            <Box
                                flex={1}
                                display="flex"
                                flexDirection={{ xs: "column", md: "row" }}
                                gap="20px"
                            >
                                <Box
                                    flex={1}
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="space-between"
                                    gap="15px"
                                >
                                    <Stack direction="column" pb={1}>
                                        <Typography
                                            fontSize={22}
                                            fontWeight={600}
                                            color="#11142D"
                                        >
                                            {translate("myprofilepage.updateInfo", "Update Information")}
                                        </Typography>
                                    </Stack>
                                    <Stack
                                        direction="row"
                                        flexWrap="wrap"
                                        pb={4}
                                    >
                                        <Stack
                                            direction="column"
                                            flexWrap="wrap"
                                            gap="20px"
                                            mr={10}
                                            pb={3}
                                        >
                                            <Stack flex ={1} gap="20px" mb = {2}>
                                                <Typography
                                                    fontSize={14}
                                                    fontWeight={500}
                                                    color="#808191"
                                                >
                                                    {translate("myprofilepage.emailaddress", "Email")}
                                                </Typography>
                                                <Box
                                                    display="flex"
                                                    flexDirection="row"
                                                    alignItems="center"
                                                    gap="10px"
                                                >
                                                    <Email sx={{ color: "#11142D" }} />
                                                    <Typography
                                                        fontSize={14}
                                                        color="#11142D"
                                                        noWrap
                                                    >
                                                        {myProfile.email}
                                                    </Typography>
                                                </Box>
                                            </Stack>
                                            <Stack flex={1} gap="20px" mt={1}>
                                                <FormControl>
                                                    <FormHelperText
                                                        sx={{
                                                            fontWeight: 500,
                                                            fontSize: 14,
                                                            color: "#808191",
                                                            marginLeft: "0",
                                                        }}
                                                        >
                                                        {translate("myprofilepage.department", "Department")}
                                                    </FormHelperText>
                                                    <Box
                                                        display="flex"
                                                        flexDirection="row"
                                                        alignItems="center"
                                                        gap="10px"
                                                        mt={2}
                                                    >
                                                        <School sx={{ color: "#11142D" }} />
                                                        <Typography
                                                            fontSize={14}
                                                            color="#11142D"
                                                            noWrap
                                                        >
                                                            <TextField
                                                                required
                                                                id="standard-select-currency"
                                                                select
                                                                defaultValue={myProfile.degreeDepartment}
                                                                helperText={translate("myprofilepage.selectDepartment", "Please select a department")}
                                                                variant="standard"
                                                                color="info"
                                                                {...register("degreeDepartment", {required: true}) }
                                                            >
                                                                <MenuItem key={myProfile.degreeDepartment} value="Software Engineering">{translate("register-page.depsoft","Software Engineering")}</MenuItem>
                                                                <MenuItem key={myProfile.degreeDepartment} value="Data Engineering">{translate("register-page.depdata","Data Systems Engineering")}</MenuItem>
                                                                <MenuItem key={myProfile.degreeDepartment} value="Mechanical Engineering">{translate("register-page.depmech","Mechanical Engineering")}</MenuItem>
                                                                <MenuItem key={myProfile.degreeDepartment} value="Electrical Engineering">{translate("register-page.depelec","Electrical Engineering")}</MenuItem>
                                                                <MenuItem key={myProfile.degreeDepartment} value="Bio-technology">{translate("register-page.depbio","Bio-technology Engineering")}</MenuItem>
                                                                <MenuItem key={myProfile.degreeDepartment} value="Industrial Engineering">{translate("register-page.depindus","Industrial Engineering")}</MenuItem>
                                                                <MenuItem key={myProfile.degreeDepartment} value="Civil Engineering">{translate("register-page.depcivil","Civil Engineering")}</MenuItem>
                                                                <MenuItem key={myProfile.degreeDepartment} value="Optical Engineering">{translate("register-page.depopt","Optical Engineering")}</MenuItem>
                                                                <MenuItem key={myProfile.degreeDepartment} value="Applied Mathematics">{translate("register-page.depmath","Applied Mathematics")}</MenuItem>
                                                                <MenuItem key={myProfile.degreeDepartment} value="Applied Mathematics And Software Engineering">{translate("register-page.depsoftmath","Applied Mathematics & Software Engineering")}</MenuItem>
                                                                <MenuItem key={myProfile.degreeDepartment} value="Applied Mathematics And Electrical Engineering">{translate("register-page.depelecmath","Applied Mathematics & Electrical Engineering")}</MenuItem>
                                                            </TextField>
                                                        </Typography>
                                                    </Box>
                                                </FormControl>
                                            </Stack>
                                        </Stack>
                                        <Stack
                                            direction="column"
                                            flexWrap="wrap"
                                            gap="25px"
                                            mr={10}
                                            pb={3}
                                        >
                                            <Stack flex={1} gap="15px">
                                                <Typography
                                                    fontSize={14}
                                                    fontWeight={500}
                                                    color="#808191"
                                                >
                                                    {translate("myprofilepage.firstName", "First Name")}
                                                </Typography>
                                                <Box
                                                    display="flex"
                                                    flexDirection="row"
                                                    alignItems="center"
                                                    gap="10px"
                                                >
                                                    <ManageAccounts sx={{ color: "#11142D", marginTop: "17px", }} />
                                                    <FormControl>
                                                        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                                            <TextField
                                                                required
                                                                id="standard-basic"
                                                                color="info"
                                                                variant="standard"
                                                                defaultValue={myProfile.firstName}
                                                                {...register("firstName", {required: true}) }
                                                            >
                                                            </TextField>
                                                        </Box>
                                                    </FormControl>
                                                </Box>
                                            </Stack>
                                            <Stack flex={1} gap="15px">
                                                <Typography
                                                    fontSize={14}
                                                    fontWeight={500}
                                                    color="#808191"
                                                >
                                                    {translate("myprofilepage.lastName", "Last Name")}
                                                </Typography>
                                                <Box
                                                    display="flex"
                                                    flexDirection="row"
                                                    alignItems="center"
                                                    gap="10px"
                                                >
                                                    <ManageAccounts sx={{ color: "#11142D", marginTop: "17px", }} />
                                                    <FormControl>
                                                        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                                            <TextField
                                                                required
                                                                id="standard-basic"
                                                                color="info"
                                                                defaultValue={myProfile.lastName}
                                                                variant="standard"
                                                                {...register("lastName", {required: true})}
                                                            >
                                                            </TextField>
                                                        </Box>
                                                    </FormControl>
                                                </Box>
                                            </Stack>
                                        </Stack>
                                        <Stack
                                            direction="column"
                                            flexWrap="wrap"
                                            gap="25px"
                                            mr={10}
                                            pb={3}
                                        >
                                            <Stack flex={1}>
                                                <Typography
                                                    fontSize={14}
                                                    fontWeight={500}
                                                    color="#808191"
                                                >
                                                    {translate("myprofilepage.gender", "Gender")}
                                                </Typography>
                                                <Box
                                                    display="flex"
                                                    flexDirection="row"
                                                    alignItems="center"
                                                    gap="10px"
                                                >
                                                    <PersonPin sx={{ color: "#11142D" }} />
                                                    <Typography
                                                        fontSize={14}
                                                        color="#11142D"
                                                        noWrap
                                                    >
                                                        <TextField
                                                            id="standard-select-currency"
                                                            select
                                                            required
                                                            label={translate("myprofilepage.selectGender", "Select Gender")}
                                                            defaultValue={myProfile.gender}
                                                            helperText={translate("myprofilepage.selectGender2", "Please select your gender")}
                                                            variant="standard"
                                                            color="info"
                                                            {...register("gender", {required: true})}
                                                        >
                                                            <MenuItem key={myProfile.gender} value="Male">{translate("register-page.male", "Male")}</MenuItem>
                                                            <MenuItem key={myProfile.gender} value="Female">{translate("register-page.female", "Female")}</MenuItem>
                                                        </TextField>
                                                    </Typography>
                                                </Box>
                                            </Stack>
                                            <Stack flex={1} gap="15px">
                                                <Typography
                                                    fontSize={14}
                                                    fontWeight={500}
                                                    color="#808191"
                                                >
                                                    {translate("myprofilepage.phone", "Phone Number")}
                                                </Typography>
                                                <Box
                                                    display="flex"
                                                    flexDirection="row"
                                                    alignItems="center"
                                                    gap="10px"
                                                >
                                                    <Phone sx={{ color: "#11142D", marginTop: "17px", }} />
                                                    <FormControl>
                                                        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                                            <TextField
                                                                id="standard-basic"
                                                                color="info"
                                                                required
                                                                variant="standard"
                                                                defaultValue={myProfile.phone}
                                                                {...register("phone", {
                                                                    pattern: {
                                                                        value: /^0(5[^7]|[2-4]|[8-9]|7[0-9])[0-9]{7}$/,
                                                                        message: "Invalid Israeli phone number"
                                                                    }
                                                                })}
                                                            >
                                                            </TextField>
                                                        </Box>
                                                    </FormControl>
                                                </Box>
                                            </Stack>
                                        </Stack>
                                        <Stack
                                            direction="column"
                                            flexWrap="wrap"
                                            gap="25px"
                                        >
                                            <Stack flex={1} gap="15px">
                                                <Typography
                                                    fontSize={14}
                                                    fontWeight={500}
                                                    color="#808191"
                                                >
                                                    {translate("myprofilepage.dob", "Date of Birth")}
                                                </Typography>
                                                <Box
                                                    display="flex"
                                                    flexDirection="row"
                                                    alignItems="center"
                                                    gap="10px"
                                                >
                                                    <CalendarMonth sx={{ color: "#11142D" }} />
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DatePicker
                                                            disableFuture
                                                            openTo="year"
                                                            views={['year', 'month', 'day']}
                                                            inputFormat="DD-MM-YYYY"
                                                            value={dateToPass}
                                                            onChange={handleDateChange}
                                                            renderInput={(params) =>
                                                                <TextField
                                                                    color="info"
                                                                    id="standard-basic"
                                                                    variant="standard"
                                                                    {...params}
                                                                />}
                                                        />
                                                    </LocalizationProvider>
                                                </Box>
                                            </Stack>
                                            <Stack flex={1} mt={5} mr={5}>
                                                <CustomButton
                                                    type="submit"
                                                    title={formLoading ? translate("myprofilepage.savingButton", "Saving...") : translate("myprofilepage.saveButton", "Save")}
                                                    backgroundColor="#475BE8"
                                                    color="#FCFCFC"
                                                    icon = <SaveAlt/>
                                                />
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </form>
            </Box>
        </Box>
    );
}

export default EditProfileForm;
