import React, { useState, useEffect } from 'react';
import { useGetLocale, useTranslate, useDataProvider } from '@refinedev/core';
import { Box, Stack, Typography, IconButton, MenuList, MenuItem, Divider } from '@mui/material';
import { Add } from '@mui/icons-material';
import { TaskItem, AddTaskForm } from "../components";
import { axios } from "../App";

function TaskManager() {
    const [expandedItemId, setExpandedItemId] = useState(null);
    const [tasks, setTasks] = useState<any[]>([]);
    const [addTaskForm, setAddTaskForm] = useState<boolean>(false);
    const translate = useTranslate();
    const locale = useGetLocale();
    const dataProvider = useDataProvider();
    const currDir = locale() === 'en' ? 'ltr' : 'rtl';

    useEffect(() => {
        const getTasks = async () => {
            const response = await axios.get(`${dataProvider().getApiUrl()}/applications/todo`);
            setTasks(response.data);
        }
        getTasks();
    }, [dataProvider]);

    const handleCompleteTask = (taskId: string) => {
        const completeTask = async () => {
            await axios.post(`${dataProvider().getApiUrl()}/applications/todo/${taskId}`);
            setTasks((prevTasks) => {
                return prevTasks?.map(task =>
                    task._id === taskId
                        ? { ...task, completed: true, subtasks: task.subtasks.map(st => ({ ...st, subCompleted: true })) }
                        : task
                );
            });
        }
        completeTask();
    };

    const handleRevertTaskStatus = (taskId: string) => {
        const uncompleteTask = async () => {
            await axios.post(`${dataProvider().getApiUrl()}/applications/todo/revert-status/${taskId}`);
            setTasks((prevTasks) => {
                return prevTasks?.map(task =>
                    task._id === taskId
                        ? { ...task, completed: false, subtasks: task.subtasks.map(st => ({ ...st, subCompleted: false })) }
                        : task
                );
            });
        }
        uncompleteTask();
    };

    const handleDeleteTask = (taskId: string) => {
      const deleteTask = async () => {
        await axios.delete(`${dataProvider().getApiUrl()}/applications/todo/${taskId}`);
        if (tasks.length > 0) {
            setTasks((prevTasks) => {
                return prevTasks.filter(task => task._id !== taskId);
            });
        } else {
            setTasks([]);
        }
      }
      deleteTask();
    };

    const handleEditTask = async (updatedTask) => {
        try {
            const response = await axios.patch(`${dataProvider().getApiUrl()}/applications/todo/${updatedTask._id}`, {
                taskItem: updatedTask
            });

            setTasks((prevTasks) => {
                return prevTasks.map((task) =>
                    task._id === updatedTask._id ? response.data : task
                );
            });
        } catch (error) {
            console.error('Error updating task -', error);
        }
    };

    const handleAddTask = (task: any) => {
        setTasks(prevTasks => {
            const updatedTasks = prevTasks ? [...prevTasks] : [];
            updatedTasks.push(task);
            return updatedTasks;
        });
    };


    const handleOpenTask = () => {
        setAddTaskForm(!addTaskForm);
    };

    return (
        <Box dir={currDir} flexDirection="row" flexWrap="nowrap" width="100%">
            <MenuList>
                <MenuItem
                    key="headers-taskmanager"
                    style={{ pointerEvents: 'none' }}
                    sx={{
                        border: "1px solid #e0e0e0",
                        height: "4vh",
                    }}
                >
                    <Stack direction="row" flexDirection="row" gap={2} alignItems="center" sx={{ width: '100%' }}>
                        <Box alignItems="flex-start" flexBasis={0} flexGrow={1} flexShrink={1} maxWidth="4.9%" display="flex" justifyContent="center">
                            <Typography fontSize={16} color="#11142D">{translate("taskmanager-page.task", "Task")}</Typography>
                        </Box>
                        <Divider orientation="vertical" flexItem style={{ width: 0, backgroundColor: '#b7b1b1' }} />
                        <Box flexBasis={0} flexGrow={1} flexShrink={1} maxWidth="11.6%" display="flex" justifyContent="center">
                            <Typography fontSize={16} color="#11142D">{translate("taskmanager-page.title", "Title")}</Typography>
                        </Box>
                        <Divider orientation="vertical" flexItem style={{ backgroundColor: '#b7b1b1' }} />
                        <Box flexBasis={0} flexGrow={1} flexShrink={1} maxWidth="9.7%" display="flex" justifyContent="center">
                            <Typography fontSize={16} color="#11142D">{translate("taskmanager-page.course", "Course")}</Typography>
                        </Box>
                        <Divider orientation="vertical" flexItem style={{ backgroundColor: '#b7b1b1' }} />
                        <Box flexBasis={0} flexGrow={1} flexShrink={1} maxWidth="11.6%" display="flex" justifyContent="center">
                            <Typography fontSize={16} color="#11142D">{translate("taskmanager-page.status", "Status")}</Typography>
                        </Box>
                        <Divider orientation="vertical" flexItem style={{ backgroundColor: '#b7b1b1' }} />
                        <Box flexBasis={0} flexGrow={1} flexShrink={1} maxWidth="11.6%" display="flex" justifyContent="center">
                            <Typography fontSize={16} color="#11142D">{translate("taskmanager-page.category", "Category")}</Typography>
                        </Box>
                        <Divider orientation="vertical" flexItem style={{ backgroundColor: '#b7b1b1' }} />
                        <Box flexBasis={0} flexGrow={1} flexShrink={1} maxWidth="9.7%" display="flex" justifyContent="center">
                            <Typography fontSize={16} color="#11142D">{translate("taskmanager-page.priority", "Priority")}</Typography>
                        </Box>
                        <Divider orientation="vertical" flexItem style={{ backgroundColor: '#b7b1b1' }} />
                        <Box flexBasis={0} flexGrow={1} flexShrink={1} maxWidth="11.6%" display="flex" justifyContent="center">
                            <Typography fontSize={16} color="#11142D">{translate("taskmanager-page.duedate", "Due Date")}</Typography>
                        </Box>
                        <Divider orientation="vertical" flexItem style={{ backgroundColor: '#b7b1b1' }} />
                        <Box flexBasis={0} flexGrow={1} flexShrink={1} maxWidth="10%" display="flex" justifyContent="center">
                            <Typography fontSize={16} color="#11142D">{translate("taskmanager-page.tags", "Tags")}</Typography>
                        </Box>
                    </Stack>
                </MenuItem>
                {tasks?.map((task, index) => (
                    <TaskItem
                        key={task._id}
                        task={task}
                        index={index + 1}
                        handleCompleteTask={() => handleCompleteTask(task._id)}
                        handleRevertTaskStatus={() => handleRevertTaskStatus(task._id)}
                        handleDeleteTask={() => handleDeleteTask(task._id)}
                        handleEditTask={handleEditTask}
                        onToggleExpanded={() => setExpandedItemId(expandedItemId === task._id ? null : task._id)}
                    />
                ))}
                {addTaskForm ? (
                    <AddTaskForm handleOpenTask={handleOpenTask} handleAddTask={handleAddTask}/>
                ) : (
                    <Stack
                        direction="row" flexDirection="row"
                        gap={2} justifyContent="center"
                        width="100%" border="1px solid #E0E0E0"
                        sx={{
                            backgroundColor: "#475BE8",
                            '&:hover': {
                                backgroundColor: "#1E36E8",
                            }
                        }}
                    >
                        <IconButton style={{ width: "100%" }} onClick={handleOpenTask}>
                            <Stack direction="row" flexDirection="row" alignItems="center">
                                <Add style={{ color: "#FCFCFC" }}/>
                                <Typography fontSize={16} color="#FCFCFC">
                                    {translate("taskmanager-page.addTask", "Add Task")}
                                </Typography>
                            </Stack>
                        </IconButton>
                    </Stack>
                )}
            </MenuList>
        </Box>
    );
}

export default TaskManager;
