import React, { useEffect, useState } from 'react';
import { Box, Typography, Avatar, Badge } from "@mui/material";
import { ForwardToInbox, Cancel } from "@mui/icons-material";

function VUserButton({ user, isCollaborator, addCollaborator, removeCollaborator, isTaskOwner }: any) {
    const [isCollab, setIsCollab] = useState<boolean>(isCollaborator);
    const [hover, setHover] = useState<boolean>(false);

    useEffect(() => {
        setIsCollab(isCollaborator);
    }, [isCollaborator]);

    const handleAddCollab = () => {
        if (!isTaskOwner) return;
        addCollaborator(user);
        setIsCollab(true);
    };

    const handleRemoveCollab = () => {
        if (!isTaskOwner) return;
        removeCollaborator(user);
        setIsCollab(false);
    };

    return (
        <Box display="flex" flexDirection="column" alignItems="center" gap={1} width="6vw" height="11vh" style={{ backgroundColor: "#FCFCFC", borderRadius: "5px" }}>
            {isTaskOwner ? (
                <Badge
                    sx={{ marginTop: "10px" }}
                    overlap="circular"
                    anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                    badgeContent={
                        hover && (
                            <Avatar sx={{ width: 24, height: 24, backgroundColor: "#FFFFF" }}>
                                {isCollab ?
                                    <Cancel sx={{ color: "#FF0000" }} onClick={handleRemoveCollab}/> :
                                    <ForwardToInbox onClick={handleAddCollab}/>
                                }
                            </Avatar>
                        )
                    }
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                >
                    <Avatar
                        alt="UserImage"
                        src={user.avatar}
                        title={user.firstName + " " + user.lastName}
                        sx={{ width: 48, height: 48 }}
                    />
                </Badge>
            ) : (
                <Avatar
                    alt="UserImage"
                    src={user.avatar}
                    title={user.firstName + " " + user.lastName}
                    sx={{ width: 48, height: 48, marginTop: "10px" }}
                />
            )}
            <Typography fontSize={14} color="#11142D" textAlign="center">
                {`${user.firstName} ${user.lastName}`}
            </Typography>
        </Box>
    );
}

export default VUserButton;
