import React, { useState } from 'react';
import {
    Avatar, Badge,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    Typography
} from "@mui/material";
import { Divider } from "antd";
import VUserButton from "../common/VUserButton";
import { Cancel, Pending } from "@mui/icons-material";

const dialogStyles = {
    dialog: {
        backgroundColor: '#FFFFFF',
        color: '#11142D',
        maxWidth: 'sm',
    },
    title: {
        textAlign: 'center',
        fontWeight: "700"
    },
    cancelButton: {
        color: '#475BE8',
    },
};

function CollaboratorsOverlay({openCollab, handleCollabOverlay, addCollaborator, removeCollaborator, collaborators, isTaskOwner }: any) {
    const [hover, setHover] = useState(false);

    const handleRemoveCollaborator = (collaborator) => {
        if (!isTaskOwner) return;
        removeCollaborator(collaborator);
    };

    return (
        <Dialog open={openCollab} onClose={handleCollabOverlay} PaperProps={{ sx: dialogStyles.dialog }} fullWidth>
            <DialogTitle sx={dialogStyles.title}>Collaborators</DialogTitle>
            <Divider style={{ marginTop: "5px", marginBottom: "5px" }}></Divider>
            <DialogContent>
                <Stack direction="row" flexDirection="row" justifyContent="center" mb={2}>
                    <Typography fontSize={16} color="#11142D">Invited Collaborators</Typography>
                </Stack>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: { xs: "column", md: "row" },
                        gap: 2.5,
                    }}
                >
                    <Stack
                        direction="row"
                        flexDirection="row"
                        flexWrap="wrap"
                        gap={1}
                        justifyContent="center"
                        mb={2}
                    >
                        {collaborators?.map((collaborator) => (
                            collaborator.pendingInvite ? (
                                <Box key={collaborator._id} display="flex" flexDirection="column" alignItems="center" gap={1} width="6vw" height="11vh" style={{ backgroundColor: "#FCFCFC", borderRadius: "5px" }}>
                                    <Badge
                                        sx={{ marginTop: "10px" }}
                                        overlap="circular"
                                        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                                        badgeContent={
                                            isTaskOwner && hover && (
                                                <Avatar sx={{ width: 16, height: 16 }}>
                                                    <Cancel sx={{ color: "#FF0000" }} onClick={() => handleRemoveCollaborator(collaborator)}/>
                                                </Avatar>
                                            )
                                        }
                                        onMouseEnter={() => setHover(true)}
                                        onMouseLeave={() => setHover(false)}
                                    >
                                        <Badge
                                            overlap="circular"
                                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                            badgeContent={
                                                <Avatar sx={{ width: 16, height: 16 }} title="Pending Invitation">
                                                    <Pending />
                                                </Avatar>
                                            }
                                        >
                                            <Avatar
                                                alt="CollaboratorImage"
                                                src={collaborator.user.avatar}
                                                title={collaborator.user.firstName + " " + collaborator.user.lastName}
                                                sx={{ width: 48, height: 48 }}
                                            />
                                        </Badge>
                                    </Badge>
                                    <Typography fontSize={16} color="#11142D">{collaborator.user.firstName + " " + collaborator.user.lastName}</Typography>
                                </Box>
                            ) : (
                                <VUserButton
                                    user={collaborator.user}
                                    isTaskOwner={isTaskOwner}
                                    isCollaborator={true}
                                    addCollaborator={addCollaborator}
                                    removeCollaborator={removeCollaborator}
                                />
                            )
                        ))}
                    </Stack>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCollabOverlay} style={dialogStyles.cancelButton}>
                    Exit
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default CollaboratorsOverlay;
