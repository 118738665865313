import SchedulerComponent from './scheduler';
import '../../assets/scheduler-styles.scss';
import 'devextreme/dist/css/dx.material.blue.dark.css';
import config from 'devextreme/core/config';
import { useDataProvider } from '@refinedev/core';
import { useEffect, useState } from 'react';
import { CourseObjectProps, GroupProps } from '../../interfaces/apps';
import ScheduleMenu from './menu';
import { axios } from "../../App";

const MainScheduler = () => {
  const [groups, setGroups] = useState<GroupProps[]>([]);
  const [courses] = useState<CourseObjectProps[]>(
    JSON.parse(localStorage.getItem('courses') || '[]')
  );
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [isHidden, setIsHidden] = useState<boolean>(false);
  const dataProvider = useDataProvider();

    useEffect(() => {
        const isPatchAvailable = process.env.REACT_APP_COURSES_PATCH_AVAILABLE === 'true';
        async function fetchCourses() {
            const response = await axios.get(`${dataProvider().getApiUrl()}/courses/`);
            localStorage.setItem('courses', JSON.stringify(response.data));
        }

        if (!localStorage.getItem('courses') || isPatchAvailable) {
            fetchCourses();
        }

    }, [dataProvider]);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  if (width > 1024 && width < 1650) {
    return (
      <div className="container">
        <ScheduleMenu
          groups={groups}
          setGroups={setGroups}
          courses={courses}
          isHidden={isHidden}
          setIsHidden={setIsHidden}
          isMobile={false}
        />
        <SchedulerComponent
          courses={courses}
          groups={groups}
          unselect={(groupId: string) => {
            const g = groups.find(g => g._id === groupId);
            if (g) {
              g.selected = false;
              setGroups([...groups]);
            }
          }}
          isExpanded={isHidden}
        />
        <div className="footer">Created by Shay Bistrizky</div>
      </div>
    );
  }
  if (width > 1650) {
    return (
      <div className="container">
        <ScheduleMenu
          groups={groups}
          setGroups={setGroups}
          courses={courses}
          isHidden={isHidden}
          setIsHidden={setIsHidden}
          isMobile={false}
        />
        <SchedulerComponent
          courses={courses}
          groups={groups}
          unselect={(groupId: string) => {
            const g = groups.find(g => g._id === groupId);
            if (g) {
              g.selected = false;
              setGroups([...groups]);
            }
          }}
          isExpanded={isHidden}
        />
        <div className="footer">Created by Shay Bistrizky</div>
      </div>
    );
  }
  return (
    <div>
      <ScheduleMenu
        groups={groups}
        setGroups={setGroups}
        courses={courses}
        isHidden={false}
        setIsHidden={() => {}}
        isMobile={true}
      />
    </div>
  );
}

config({
    rtlEnabled: true,
});

export default MainScheduler;
