import React, { useEffect, useState } from "react";
import { useGo, useLink, useResource } from "@refinedev/core";
import { RefineErrorPageProps } from "@refinedev/ui-types";
import { useTranslate } from "@refinedev/core";
import { CustomButton } from "../components";
import { Home, Info } from "@mui/icons-material";
import { Box, Stack, Tooltip, Typography, Grid } from "@mui/material";
import { logoNonCollapsed } from "../assets";

const ErrorPage: React.FC<RefineErrorPageProps> = () => {
    const [errorMessage, setErrorMessage] = useState<string>();
    const go = useGo();
    const { resource, action } = useResource();
    const translate = useTranslate();
    const Link = useLink();

    useEffect(() => {
        if (resource && action) {
            setErrorMessage(
                translate(
                    "pages.error.info",
                    {
                        action,
                        resource: resource?.name,
                    },
                    `You may have forgotten to add the "${action}" component to "${resource?.name}" resource.`,
                ),
            );
        }
    }, [action, resource]);

    return (
        <Grid
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={20}
        >
            <Grid
                container
                direction="column"
                display="flex"
                alignItems="center"
            >
                <Stack direction="row" ml={10}>
                    <Link to="/">
                        <img src={logoNonCollapsed} height="75%" width="75%" alt="BraudeOverflow Logo" />
                    </Link>
                </Stack>
                <Typography variant="h1">404</Typography>
                <Stack direction="row" spacing="2">
                    <Typography>
                        {translate(
                            "pages.error.404",
                            "Sorry, the page you visited does not exist.",
                        )}
                    </Typography>

                    {errorMessage && (
                        <Tooltip title={errorMessage}>
                            <Info data-testid="error-component-tooltip" />
                        </Tooltip>
                    )}
                </Stack>
                <Box mt={2}>
                    <CustomButton
                        type="submit"
                        title={translate("pages.error.backHome", "Back Home")}
                        backgroundColor="#475BE8"
                        color="#FCFCFC"
                        icon={<Home />}
                        handleClick={() => {
                            go({ to: "/" });
                        }}
                    />
                </Box>
            </Grid>
        </Grid>
    );
};

export default ErrorPage;
