import React from 'react';
import { Box, Card, CardContent, CardMedia, Stack, Typography } from '@mui/material';
import { AppCardProps } from '../../interfaces/common';
import { useLink } from '@refinedev/core';

function AppCard({ title, image, pageLink }  : AppCardProps ) {
    const Link = useLink();
    return (
        <Box
            marginLeft="30px"
        >
            <Stack direction="row" flexWrap="wrap" flexDirection="row">
                <Card
                    component={Link}
                    to={`/${pageLink}`}
                    sx = {{
                        width: "300px",
                        minWidth: "150px",
                        padding: "5px",
                        margin: "5px",
                        '&:hover': {
                            boxShadow: '10px 45px 45px 10px rgba(176, 176, 176, 0.2)'
                        },
                        cursor: "pointer",
                        textDecoration: "none",
                    }}
                    elevation={2}
                >
                    <CardMedia
                        component="img"
                        width="100%"
                        height={210}
                        image={image}
                        alt="Application Card"
                        sx={{
                            borderRadius: "10px",
                            textAlign: "center",
                        }}
                    />
                    <CardContent
                        sx = {{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                        }}
                    >
                        <Stack>
                            <Typography
                                fontSize={16}
                                fontWeight={500}
                                color="#11142D"
                                sx={{
                                    textDecoration: "none",
                                }}
                            >
                                {title}
                            </Typography>
                        </Stack>
                    </CardContent>
                </Card>
            </Stack>
        </Box>
    );
}

export default AppCard;
