import React from 'react';
import { useForm } from '@refinedev/react-hook-form';
import { FieldValues } from 'react-hook-form';
import { useDataProvider, useTranslate } from '@refinedev/core';

import { Box, Card, FormControl, FormHelperText, MenuItem, Stack, TextField, Typography } from '@mui/material';

import { axios } from '../../App';
import { Diversity3, Grading, RateReview, SaveAlt, Sell, VideoSettings } from '@mui/icons-material';
import { CustomButton } from '../index';
import { EditCourseFormProps } from '../../interfaces/apps';
import InputAdornment from '@mui/material/InputAdornment';

function EditCourseForm({ course, handleEditState, updateSingleTutorCourse } : EditCourseFormProps) {
    const dataProvider = useDataProvider();
    const translate = useTranslate();
    const { refineCore: { formLoading }, register, handleSubmit } = useForm();
    const onFinishHandler = async (data: FieldValues) => {
        try {
            await axios.patch(`${dataProvider().getApiUrl()}/my-profile/tutor`, {
                ...data,
                course: course.course.toString(),
                courseName: course.courseName,
                courseId: course.courseId,
            });
            updateSingleTutorCourse({
                ...data,
                course: course.course.toString(),
                courseName: course.courseName,
                courseId: course.courseId,
            });
            handleEditState();
        } catch (err) {
            console.error(err);
        }
    };

    return (

        <Card
            sx={{
                maxWidth: '500px',
                padding: '20px',
                marginTop: '30px',
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                }}
            >
            <Typography
                fontSize={20}
                fontWeight={700}
                color="#11142D"
                textAlign="center"
                pb={3}
            >
                {translate("courseforms.updatetitle", "Update Course Settings")}
            </Typography>
            <Stack direction="row" gap={1} pb={2}>
                <Typography
                    fontSize={16}
                    fontWeight={700}
                    color="#11142D"
                >
                    {translate("courseforms.course", "Course")}:
                </Typography>
                {course.courseName} - {course.courseId}
            </Stack>
            <form onSubmit={handleSubmit(onFinishHandler)}>
                <Box
                    flex={1}
                    display="flex"
                    flexDirection={{ xs: "column", md: "row" }}
                    gap="20px"
                    mt="5px"
                >
                    <FormControl>
                        <FormHelperText
                            sx={{
                                fontWeight: 500,
                                fontSize: 14,
                                color: "#808191",
                                marginLeft: "0",
                            }}
                        >{translate("courseforms.grade", "Grade")}</FormHelperText>
                        <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            gap="10px"
                        >
                            <Grading sx={{ color: "#11142D" }} />
                            <Typography
                                fontSize={17}
                                color="#11142D"
                                noWrap
                            >
                                {translate("courseforms.gradeI", "Enter your grade in the selected course")}
                            </Typography>
                            <TextField
                                required
                                id="outlined-number"
                                placeholder={translate("courseforms.grade", "Grade")}
                                size="small"
                                color="info"
                                type="number"
                                defaultValue={course.grade}
                                {...register("grade", {required: true})}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    min: 55,
                                    max: 100,
                                }}
                            />
                        </Box>
                    </FormControl>
                </Box>
                <Box
                    flex={1}
                    display="flex"
                    flexDirection={{ xs: "column", md: "row" }}
                    gap="20px"
                    mt={3}
                >
                    <FormControl>
                        <FormHelperText
                            sx={{
                                fontWeight: 500,
                                fontSize: 14,
                                color: "#808191",
                                marginLeft: "0",
                            }}
                        >{translate("courseforms.details", "Details")}</FormHelperText>
                        <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            gap="10px"
                            mb={1}
                        >
                            <VideoSettings sx={{ color: "#11142D" }} />
                            <Typography
                                fontSize={17}
                                color="#11142D"
                                noWrap
                            >
                                {translate("courseforms.platform", "Select your tutelage platform")}
                            </Typography>
                            <Box
                                flex={1}
                                display="flex"
                                flexDirection={{ xs: "column", md: "row" }}
                                gap="10px"
                                mb={1}
                            >
                                <TextField
                                    required
                                    id="standard-select-currency"
                                    select
                                    variant="standard"
                                    color="info"
                                    defaultValue={course.platform}
                                    {...register("platform", {required: true}) }
                                >
                                    <MenuItem value="Zoom">{translate("courseforms.zoom", "Zoom")}</MenuItem>
                                    <MenuItem value="Frontal">{translate("courseforms.frontal", "Frontal")}</MenuItem>
                                    <MenuItem value="Both">{translate("courseforms.both", "Both")}</MenuItem>
                                </TextField>
                            </Box>
                        </Box>
                        <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            gap="10px"
                            mt={2}
                        >
                            <RateReview sx={{ color: "#11142D" }} />
                            <Typography
                                fontSize={17}
                                color="#11142D"
                                noWrap
                            >
                                {translate("courseforms.tablet", "Do you tutor with a tablet?")}
                            </Typography>
                            <Box
                                flex={1}
                                display="flex"
                                flexDirection={{ xs: "column", md: "row" }}
                                gap="10px"
                                mb={1}
                            >
                                <TextField
                                    required
                                    id="standard-select-currency"
                                    select
                                    variant="standard"
                                    color="info"
                                    defaultValue={course.tablet}
                                    {...register("tablet", {required: true}) }
                                >
                                    <MenuItem value="true">{translate("courseforms.yes", "Yes")}</MenuItem>
                                    <MenuItem value="false">{translate("courseforms.no", "No")}</MenuItem>
                                </TextField>
                            </Box>
                        </Box>
                        <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            gap="10px"
                            mt={2}
                            mb={3}
                        >
                            <Diversity3 sx={{ color: "#11142D" }} />
                            <Typography
                                fontSize={17}
                                color="#11142D"
                                noWrap
                            >
                                {translate("courseforms.method", "Do you tutor in groups or 1on1?")}
                            </Typography>
                            <Box
                                flex={1}
                                display="flex"
                                flexDirection={{ xs: "column", md: "row" }}
                                gap="10px"
                                mb={1}
                            >
                                <Typography>
                                    <TextField
                                        required
                                        id="standard-select-currency"
                                        select
                                        variant="standard"
                                        color="info"
                                        defaultValue={course.method}
                                        {...register("method", {required: true}) }
                                    >
                                        <MenuItem value="Groups">{translate("courseforms.groups", "Groups")}</MenuItem>
                                        <MenuItem value="One">{translate("courseforms.1on1", "1on1")}</MenuItem>
                                        <MenuItem value="Both">{translate("courseforms.both", "Both")}</MenuItem>
                                    </TextField>
                                </Typography>
                            </Box>
                        </Box>
                    </FormControl>
                </Box>
                <Box
                    flex={1}
                    display="flex"
                    flexDirection={{ xs: "column", md: "row" }}
                    gap="40px"
                    mb = "15px"
                >
                    <FormControl>
                        <FormHelperText
                            sx={{
                                fontWeight: 500,
                                fontSize: 14,
                                color: "#808191",
                                marginLeft: "0",
                            }}
                        >{translate("courseforms.price", "Price")}</FormHelperText>
                        <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            gap="10px"
                            mt={2}
                        >
                            <Sell sx={{ color: "#11142D" }} />
                            <Typography
                                fontSize={17}
                                color="#11142D"
                                noWrap
                            >
                                {translate("courseforms.priceI", "Price per hour - optional")}
                            </Typography>
                            <TextField
                                id="outlined-number"
                                placeholder={translate("courseforms.price", "Price")}
                                size="small"
                                color="info"
                                type="number"
                                defaultValue={course.price}
                                {...register("price")}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    min: 0
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="start">₪</InputAdornment>,
                                }}
                            />
                        </Box>
                    </FormControl>
                </Box>
                <Box
                    flex={1}
                    display="flex"
                    flexDirection={{ xs: "column", md: "row" }}
                    gap="20px"
                    mb="5px"
                >
                    <Stack direction="row" gap="10px">
                        <CustomButton
                            type="submit"
                            title={formLoading ? translate("courseforms.saving", "Saving...") : translate("courseforms.save", "Save Changes")}
                            backgroundColor="#475BE8"
                            color="#FCFCFC"
                            icon = <SaveAlt/>
                        />
                        <CustomButton
                            type="button"
                            title={translate("courseforms.cancel", "Cancel")}
                            backgroundColor="none"
                            color="#000000"
                            handleClick={handleEditState} // This will update the state to edit (close) on cancel.
                        />
                    </Stack>
                </Box>
            </form>
            </Box>
        </Card>
    );
}

export default EditCourseForm;
