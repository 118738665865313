import React, { useState } from 'react';
import { useGetLocale, useTranslate } from '@refinedev/core';
import { Box, Card, CardContent, CardMedia, Stack, Typography } from '@mui/material';
import { TutorProfileProps } from '../../interfaces/profile';
import { TutorFullCard } from '../index';

function TutorDetailsCard({ tutorProfile, coursesTaught, description } : TutorProfileProps) {
    const [openPopUp, setOpenPopUp] = useState<boolean>(false);
    const isAdmin = tutorProfile.role === 3;
    const translate = useTranslate();
    const locale = useGetLocale();
    const currDir = locale() === 'en' ? 'ltr' : 'rtl';

    const handlePopUp = () => {
        setOpenPopUp(!openPopUp);
    }

    return (
        <Box
            flex={1}
            display="flex"
            flexDirection={{ xs: "column", md: "column" }}
            marginLeft={{ xs: "20px", md: "30px" }}
            dir={currDir}
        >
            <Stack direction="row" flexWrap="wrap" flexDirection="row">
                <Card
                    sx = {{
                        minWidth: "10vw",
                        minHeight: "25vh",
                        padding: "5px",
                        cursor: "pointer",
                        backgroundColor: isAdmin ? "goldenrod" : "inherit",
                        border: "2px solid",
                        color: isAdmin ? "goldenrod" : "#FCFCFC",
                        '&:hover': {
                            transition: "all 0.5s",
                            boxShadow: isAdmin ? '0px 10px 48px -6px rgba(171,94,0,0.7)' : '0px 10px 48px -6px rgba(0,0,0,1)',
                            transform: 'scale(1.1)',
                        }
                    }}
                    elevation={2}
                    onClick={handlePopUp}
                >
                    {isAdmin && (
                        <Box textAlign="center">
                            <Typography
                                fontSize={16}
                                fontWeight={500}
                                color="#11142D"
                                textTransform="capitalize"
                            >
                                {translate("tutorcards.webcreator", "Project Creator")}
                            </Typography>
                        </Box>
                    )}
                    <CardMedia
                        component="img"
                        width={250}
                        height={250}
                        image={tutorProfile.avatar}
                        alt="Tutor Card"
                        sx={{
                            borderRadius: "5px",
                            objectFit: "fill",
                            maxWidth: "250px",
                            maxHeight: "250px",
                        }}
                    />
                    <CardContent
                        sx = {{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "row",
                        }}
                    >
                        <Box
                            alignItems="center"
                        >
                            <Typography
                                fontSize={18}
                                fontWeight={500}
                                color="#11142D"
                                textTransform="capitalize"
                                textAlign="center"
                                display="flex"
                            >
                                {tutorProfile.firstName} {tutorProfile.lastName}
                            </Typography>
                        </Box>
                    </CardContent>
                </Card>
            </Stack>
            {openPopUp && (
                <TutorFullCard tutorProfile={tutorProfile} tutorCourses={coursesTaught} description={description} dialogStatus={openPopUp} handleClose={handlePopUp} />
            )}
        </Box>
    );
}

export default TutorDetailsCard;
