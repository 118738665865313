import React, { useEffect, useState } from 'react';
import { useLink, useTranslate } from '@refinedev/core';
import { Box, Card, CardContent, Typography, useMediaQuery } from '@mui/material';
import { Lock, PersonAdd } from '@mui/icons-material';
import { videoP } from '../assets';
import { LandingFooter, LandingHeader } from '../components/layout';
import '../assets/styles.css';

function IndexPage() {
    const translate = useTranslate();

    const paragraphs = [
        translate("indexpage.movingtext1","The portal for <span style='color:orange'>Braude students</span>"),
        translate("indexpage.movingtext2","Services made by <span style='color:orange'>students for students</span>"),
        translate("indexpage.movingtext3","Find a <span style='color:orange'>tutor</span> that suits you or publish yourself as a tutor"),
        translate("indexpage.movingtext4","Dynamically create your <span style='color:orange'>schedule</span> with ease and comfort"),
        translate("indexpage.movingtext5","Read <span style='color:orange'>students' exams</span> in a variety of courses and publish your own!")
    ];
    const [currentParagraphIndex, setCurrentParagraphIndex] = useState(0);
    const [animation, setAnimation] = useState(false);
    const canFooterFit = useMediaQuery('(min-width: 450px)')
    const Link = useLink();



    useEffect(() => {
        const intervalId = setInterval(() => {
            setAnimation(true);
            setTimeout(() => {
                setCurrentParagraphIndex((prevIndex) => (prevIndex + 1) % paragraphs.length);
                setAnimation(false);
            }, 500);
        }, 5500);
        return () => clearInterval(intervalId);
    }, [paragraphs.length]);
    return (
        <Box
            sx = {{
                position: "relative",
                height: "100vh",
                width: "100%",
                overflow: "hidden",
            }}
        >
            <Box style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100vh', overflow: 'hidden', zIndex: -1 }}>
                <video
                    autoPlay
                    muted
                    loop
                    playsInline
                    style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover' }}
                    controls={false}
                    onPlay={e => e.preventDefault()}
                    onPause={e => e.preventDefault()}
                    src={videoP}
                >
                    <source src={videoP} type="video/webm" />
                    <source src={videoP} type="video/mp4" />
                </video>
            </Box>
            <Box
                sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                }}
            >
                <Box
                    sx={{
                        color: "#fff",
                        fontWeight: 600,
                        fontSize: "2rem",
                        textAlign: "center",
                        marginBottom: "1rem",
                    }}
                >
                    {paragraphs.map((paragraph, index) => (
                        <p
                            key={index}
                            dangerouslySetInnerHTML={{ __html: paragraph }}
                            className={`slide-in ${index === currentParagraphIndex ? "current" : ""} ${animation ? "animate" : ""}`}
                        />
                    ))}
                </Box>
                <LandingHeader landingPage={true}/>
                <Box sx={{ display: "flex", flexDirection: "column", gap: "2rem", paddingTop: "15rem", width: "80%", marginBottom: "30vh" }}>
                    <Box sx={{ display: "flex", flexDirection: ["column", "row"], gap: "2rem", justifyContent: "center" }}>
                        <Card sx={{ maxWidth: ["none", "400px"], width: "100%", borderRadius: "16px", border: "1px solid #FFFFFF", backgroundColor: "inherit", '&:hover': { boxShadow: '-10px 45px 45px 10px rgba(176, 176, 176, 0.2)', color: "#FFFFFF" } }}>
                            <Link to="/register" style={{ textDecoration: "none", color: "inherit"}}>
                                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: ["5vh", "15vh"], backgroundColor: "inherit", borderRadius: "16px 16px 0 0", paddingTop: "2rem" }}>
                                    <PersonAdd sx={{ fontSize: ["3rem", "6rem"] }} />
                                </Box>
                                <CardContent sx={{ textAlign: "center" }}>
                                    <Typography variant="h5" sx={{ fontWeight: "bold", fontSize: ["1.5rem", "2rem"] }}>
                                        {translate("indexpage.signup2", "Sign Up")}
                                    </Typography>
                                    <Typography variant="body1" sx={{ marginTop: "16px", fontSize: ["0.8rem", "1rem"] }}>
                                        {translate("indexpage.signupinfo","Become a part of the latest network that is determined to make life easier for students.")}
                                    </Typography>
                                </CardContent>
                            </Link>
                        </Card>
                        <Card sx={{ maxWidth: ["none", "400px"],  width: "100%", borderRadius: "16px", border: "1px solid #FFFFFF", backgroundColor: "inherit", '&:hover': { boxShadow: '10px 45px 45px 10px rgba(176, 176, 176, 0.2)', color: "#FFFFFF" } }}>
                            <Link to="/login" style={{ textDecoration: "none", color: "inherit"}}>
                                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: ["5vh", "15vh"], backgroundColor: "inherit", borderRadius: "16px 16px 0 0", paddingTop: "2rem" }}>
                                    <Lock sx={{ fontSize: ["3rem", "6rem"] }} />
                                </Box>
                                <CardContent sx={{ textAlign: "center" }}>
                                    <Typography variant="h5" sx={{ fontWeight: "bold", fontSize: ["1.5rem", "2rem"] }}>
                                        {translate("indexpage.signin2", "Sign In")}
                                    </Typography>
                                    <Typography variant="body1" sx={{ marginTop: "16px", fontSize: ["0.8rem", "1rem"] }}>
                                        {translate("indexpage.signininfo","Sign in and use a variety of tools designed just for you.")}
                                    </Typography>
                                </CardContent>
                            </Link>
                        </Card>
                    </Box>
                </Box>
            </Box>
            {canFooterFit && (
                <Box
                    sx={{
                        position: "absolute",
                        bottom: 0,
                        width: "100%",
                        backgroundColor: "transparent",
                        color: "#fff",
                        textAlign: "center",
                    }}
                >
                    <LandingFooter />
                </Box>
            )}
        </Box>
    );
}

export default IndexPage;
