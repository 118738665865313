import React, { useContext, useEffect, useState } from 'react';
import { useDataProvider, useGetIdentity, useGetLocale, useLink, useSetLocale, useTranslate } from '@refinedev/core';
import {
  AppBar,
  Avatar,
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery
} from '@mui/material';
import i18n from 'i18n';

import { ColorModeContext } from 'contexts';
import { en, he, logoNonCollapsed } from '../../../assets';
import { UserStorage } from '../../../interfaces/common';
import { loggedInUserRole } from '../../../App';


export const Header: React.FC = () => {
  const { mode, setMode } = useContext(ColorModeContext);
  const maxWidthHeader = useMediaQuery('(min-width: 434px)');
  const { data } = useGetIdentity<UserStorage>();
  const profile: any = data ?? [];

  const dataProvider = useDataProvider();
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  useEffect(() => {
    if (loggedInUserRole >= 2)
      setIsAdmin(true);
  }, [dataProvider]);

  const shouldRenderHeader = true; // since we are using the dark/light toggle; we don't need to check if user is logged in or not.
  const Link = useLink();

  const locale = useGetLocale();
  const changeLanguage = useSetLocale();
  const currentLocale = locale();

  const translate = useTranslate();

  return shouldRenderHeader ? (
      <AppBar color="default" position="sticky" elevation={1}>
        <Toolbar>
          <Stack
              direction="row"
              width="100%"
              justifyContent="space-between"
              alignItems="center"
              gap={5}
          >
              {maxWidthHeader && (
                  <Typography variant="subtitle1">
                      <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
                          <Typography sx={{ '&:hover' : { fontWeight: "600" } }}>
                              {translate("header.home", "Home")}
                          </Typography>
                      </Link>
                  </Typography>
              )}
            {/*<IconButton*/}
            {/*  onClick={() => {*/}
            {/*    setMode();*/}
            {/*  }}*/}
            {/*>*/}
            {/*  {mode === "dark" ? <LightModeOutlined /> : <DarkModeOutlined />}*/}
            {/*</IconButton>*/}
            <Stack
                direction="row"
                gap="16px"
                alignItems="center"
                justifyContent="center"

            >
                <FormControl sx={{ minWidth: 64 }}>
                    <Select
                        disableUnderline
                        defaultValue={currentLocale}
                        inputProps={{ "aria-label": "Without label" }}
                        variant="standard"
                        sx={{
                            color: "inherit",
                            "& .MuiSvgIcon-root": {
                                color: "inherit",
                            },
                            "& .MuiStack-root > .MuiTypography-root": {
                                display: {
                                    xs: "none",
                                    sm: "block",
                                },
                            },
                        }}
                    >
                        {[...(i18n.languages ?? [])].sort().map((lang: string) => (
                            // @ts-ignore
                            <MenuItem
                                selected={currentLocale === lang}
                                key={lang}
                                defaultValue={lang}
                                onClick={() => {
                                    changeLanguage(lang);
                                }}
                                value={lang}
                            >
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Avatar
                                        sx={{
                                            width: "24px",
                                            height: "24px",
                                            marginRight: "5px",
                                        }}
                                        src={lang === "en" ? en : he}
                                    />
                                    <Typography>
                                        {lang === "en" ? "English" : "עברית"}
                                    </Typography>
                                </Stack>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
              {profile?.firstName && profile?.lastName ? (
                  <>
                      {isAdmin && maxWidthHeader && (
                          <Typography fontSize={15} fontWeight={700} color="#11142D" >{translate("header.admin", "Administrator")}:</Typography>
                      )}
                      <Typography variant="subtitle2">{profile?.firstName} {profile?.lastName}</Typography>
                  </>
              ) : null }
              {profile?.avatar ? (
                  <Avatar src={profile?.avatar} alt={profile?.firstName} />
              ) : null}
            </Stack>
          </Stack>
        </Toolbar>
      </AppBar>
  ) : null;
};

export const LandingHeader = (props: { landingPage: boolean }) => {
  const Link = useLink();
  const maxWidthDash = useMediaQuery("(min-width:600px)");
  const maxWidthLang = useMediaQuery("(min-width:450px)");

  const locale = useGetLocale();
  const changeLanguage = useSetLocale();
  const translate = useTranslate();

  const currentLocale = locale();

  return (
      <Box mb={18}>
        <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end", // aligns items to the right
              position: "absolute", // positions the box absolutely
              top: "0", // positions the box at the top
              right: "0", // positions the box at the right
              padding: "1rem", // adds some padding
            }}
        >
            <Stack direction={maxWidthLang ? 'row' : props.landingPage ? 'column' : 'row'} flexWrap="wrap" gap={maxWidthLang ? 5 : 2} alignItems="center" justifyContent="center">
                <Link to="/register" style={{ textDecoration: "none", color: "inherit" }}>
                    {props.landingPage ? (
                        <button className="action-button">
                            <Typography fontSize={14} color="#FCFCFC">
                                {translate("header.signup", "Sign Up")}
                            </Typography>
                        </button>
                    ) : (
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: "#1E36E8",
                                '&:hover': {
                                    backgroundColor: "#475BE8"
                                }
                            }}
                        >
                            {translate("header.signup", "Sign Up")}
                        </Button>
                    )}
                </Link>
                <Link to="/login" style={{ textDecoration: "none", color: "inherit" }}>
                    {props.landingPage ? (
                        <button className="action-button">
                            <Typography fontSize={14} color="#FCFCFC">
                                {translate("header.signin", "Sign In")}
                            </Typography>
                        </button>
                    ) : (
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: "inherit",
                                borderRadius: "5px",
                                border: "1px",
                                '&:hover' : {
                                    border: props.landingPage ? "1px solid #FFFFFF" : "",
                                    backgroundColor: "inherit"
                                }
                            }}
                        >
                            <Typography fontSize={14} color={props.landingPage ? "inherit" : "#FCFCFC"}>
                                {translate("header.signin", "Sign In")}
                            </Typography>
                        </Button>
                    )}
                </Link>
                <FormControl sx={{ minWidth: 64 }}>
                    <Select
                        disableUnderline
                        defaultValue={currentLocale}
                        inputProps={{ "aria-label": "Without label" }}
                        variant="standard"
                        sx={{
                            color: "inherit",
                            "& .MuiSvgIcon-root": {
                                color: "inherit",
                            },
                            "& .MuiStack-root > .MuiTypography-root": {
                                display: {
                                    xs: "none",
                                    sm: "block",
                                },
                            }
                        }}
                        MenuProps={{
                            classes: {
                                paper: "transparent-background",
                            },
                        }}
                    >
                        {[...(i18n.languages ?? [])].sort().map((lang: string) => (
                            <MenuItem
                                selected={currentLocale === lang}
                                key={lang}
                                defaultValue={lang}
                                onClick={() => {
                                    changeLanguage(lang);
                                }}
                                value={lang}
                            >
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Avatar
                                        sx={{
                                            width: "24px",
                                            height: "24px",
                                        }}
                                        src={lang === "en" ? en : he}
                                    />
                                    <Typography>
                                        {lang === "en" ? "English" : "עברית"}
                                    </Typography>
                                </Stack>
                            </MenuItem>
                        ))}
                    </Select>
                    <style>
                        {`
                            .transparent-background {
                              background-color: transparent !important;
                            }
                        `}
                    </style>
                </FormControl>
            </Stack>
        </Box>
        <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start", // aligns items to the right
              position: "absolute", // positions the box absolutely
              top: "0", // positions the box at the top
              left: "0", // positions the box at the right
              padding: "0.5rem 0 0 0.5rem",
            }}
        >
            {props.landingPage && (
                <>
                    <Link to="/">
                        <img src={ logoNonCollapsed } alt="BraudeOverflow" height="50px" width="100px" style={{ color: "#fff" }} />
                    </Link>
                    {maxWidthDash && (
                        <Link to="/dashboard" style={{ textDecoration: "none", color: "inherit" }}>
                            <Button
                                variant="contained"
                                sx={{
                                    margin: "0 1rem",
                                    backgroundColor: "inherit",
                                    border: "1px",
                                    '&:hover' : {
                                        border: props.landingPage ? "2px solid #FFFFFF" : "",
                                        borderRadius: "1px",
                                        backgroundColor: "inherit"
                                    }

                                }}
                            >
                                <Typography fontSize={14} color={props.landingPage ? "inherit" : "#11142D"}>
                                    {translate("header.dashboard", "Dashboard")}
                                </Typography>
                            </Button>
                        </Link>
                    )}
                </>
            )}
        </Box>
      </Box>
  );
};
