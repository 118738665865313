import React, { useMemo, useState } from 'react';
import { useGetLocale, useTable, useTranslate } from "@refinedev/core";
import { Avatar, AvatarGroup, Badge, Box, IconButton, Stack, TextField, Typography } from "@mui/material";
import { ArrowBackIosNew, ArrowForwardIos, Close, Pending, PersonAddAlt1 } from "@mui/icons-material";
import VUserButton from "../common/VUserButton";
import CollaboratorsOverlay from "./CollaboratorsOverlay";

function CollaboratorsItem({ collaborators, addCollaborator, removeCollaborator, isTaskOwner }: any) {
    const [inviteCollab, openInviteCollab] = useState<boolean>(false);
    const [collabOverlay, setCollabOverlay] = useState<boolean>(false);
    const translate = useTranslate();
    const locale = useGetLocale();
    const currDir = locale() === 'en' ? 'ltr' : 'rtl';

    const handleCollabOverlay = () => {
      setCollabOverlay(!collabOverlay);
    };


    const {
        tableQueryResult: { data: tableData },
        current,
        setCurrent,
        pageCount,
        filters,
        setFilters,
    } = useTable({
        resource: `applications/search/users`,
        syncWithLocation: false,
        pagination: {
            pageSize: 5
        }
    });

    let userSearch = tableData?.data ?? [];

    const currentFilterValues = useMemo(() => {
        const logicalFilters = filters.flatMap((item) => (
            'field' in item ? item : []
        ));

        return {
            name: logicalFilters.find((item) => item.field === 'name')?.value || '',
        }
    }, [filters]);

    const handleInviteCollab = () => {
        openInviteCollab(!inviteCollab);
        setFilters([
            {
                field: 'name',
                operator: 'contains',
                value: ''
            },
        ]);
        userSearch = [];
    };

    return (
        <Stack direction="row" flexDirection="row" flexWrap="nowrap" gap={1} alignItems="center" display="flex" justifyContent="flex-start">
            {collabOverlay &&
                <CollaboratorsOverlay
                    openCollab={collabOverlay}
                    isTaskOwner={isTaskOwner}
                    handleCollabOverlay={handleCollabOverlay}
                    addCollaborator={addCollaborator}
                    removeCollaborator={removeCollaborator}
                    collaborators={collaborators}
                />
            }
            {isTaskOwner && inviteCollab ? (
                <Box dir={currDir} mb={1}>
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 3}}>
                        <Stack direction="row" width="100%" alignItems="center">
                            <IconButton aria-label={translate("privatetutors-page.closeButton", "Close")} onClick={handleInviteCollab}>
                                <Close />
                            </IconButton>
                            <Box flexWrap="wrap" alignItems="center">
                                <Box display="flex" ml={1} gap={2} alignItems="center" flexWrap="wrap" mb={{ xs: '20px', sm: 0}}>
                                    <TextField
                                        variant="outlined"
                                        color="info"
                                        size="small"
                                        sx={{ width: '50%', minWidth: { xs: '75%', md: '75%', lg: '75%' } }}
                                        placeholder={translate("privatetutors-page.firstlastName", "Search by name")}
                                        value={currentFilterValues.name}
                                        onChange={(e) => {
                                            setFilters([
                                                {
                                                    field: 'name',
                                                    operator: 'contains',
                                                    value: e.currentTarget.value ? e.currentTarget.value : undefined
                                                }
                                            ])
                                            if (currentFilterValues.name === '') {
                                                setCurrent(1)
                                            }
                                        }}
                                        onKeyDown={(e) => {
                                            e.stopPropagation()
                                        }}
                                    />
                                </Box>
                            </Box>
                            <Box display="flex" flexDirection="row" flexGrow={1}>
                                <Typography fontSize={14} fontWeight={500} color="#11142D">
                                    {!userSearch.length && "No Users Found"}
                                </Typography>
                                {userSearch?.map((user) => (
                                    <Box display="flex" flexDirection="row" width="20%" mr={2} key={user._id}>
                                        <VUserButton
                                            user={user}
                                            isTaskOwner={isTaskOwner}
                                            isCollaborator={collaborators?.some((collaborator: any) => collaborator.user?._id === user._id) ?? false}
                                            addCollaborator={addCollaborator}
                                            removeCollaborator={removeCollaborator}
                                        />
                                    </Box>
                                ))}
                            </Box>
                            {userSearch.length > 0 &&
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexWrap: 'nowrap',
                                        alignItems: 'flex-end',
                                        gap: 1,
                                    }}
                                    width="15%"
                                    dir={locale() === "he" ? "rtl" : "ltr"}
                                >
                                    <IconButton
                                        aria-label={translate("privatetutors-page.prevButton", "Previous")}
                                        onClick={() => setCurrent((prev) => prev - 1)}
                                        disabled={!(current > 1)}
                                    >
                                        <ArrowBackIosNew />
                                    </IconButton>
                                    <IconButton
                                        aria-label={translate("privatetutors-page.nextButton", "Next")}
                                        onClick={() => setCurrent((prev) => prev + 1)}
                                        disabled={current === pageCount}
                                    >
                                        <ArrowForwardIos />
                                    </IconButton>
                                </Box>
                            }
                        </Stack>
                    </Box>
                </Box>
            ) : (
                <>
                    <Typography fontSize={16} fontWeight={500} color="#11142D">
                        Collaborators:
                    </Typography>
                    {collaborators.length > 0 ? (
                        <AvatarGroup max={4} onClick={handleCollabOverlay} sx={{ marginLeft: "5px", '&:hover': { opacity: 0.5 } }}>
                            {collaborators?.map((collaborator) =>
                                collaborator.pendingInvite ? (
                                    <Badge
                                        key={collaborator.user?._id}
                                        overlap="circular"
                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                        badgeContent={
                                            <Avatar sx={{ width: 16, height: 16 }} title="Pending Invitation">
                                                <Pending />
                                            </Avatar>
                                        }
                                    >
                                        <Avatar
                                            alt="CollaboratorImage"
                                            src={collaborator.user?.avatar}
                                            title={collaborator.user?.firstName + " " + collaborator.user?.lastName}
                                        />
                                    </Badge>
                                ) : (
                                    <Avatar
                                        alt="CollaboratorImage"
                                        src={collaborator.user?.avatar}
                                        title={collaborator.user?.firstName + " " + collaborator.user?.lastName}
                                    />
                                )
                            )}
                        </AvatarGroup>
                    ) : (
                        <Typography>None</Typography>
                    )}
                    {isTaskOwner && (
                        <IconButton
                            aria-label={translate("privatetutors-page.inviteCollab", "Invite Collaborators")}
                            onClick={handleInviteCollab}
                        >
                            <PersonAddAlt1 />
                        </IconButton>
                    )}
                </>
            )}
        </Stack>
    );
}

export default CollaboratorsItem;
