import { useDataProvider, useGetIdentity, useGetLocale, useTranslate } from '@refinedev/core';
import { AddCourseForm, CustomButton, Profile, TutorProfile } from 'components';
import { Box, Typography } from '@mui/material';
import { Add, CastForEducation, KeyboardArrowDown } from '@mui/icons-material';
import { axios } from '../App';
import React, { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { useForm } from '@refinedev/react-hook-form';
import { FieldValues } from 'react-hook-form';
import { Divider } from 'antd';
import { MyTutorProfileProps, TutorCoursesProfileProps } from '../interfaces/profile';
import { CourseObjectProps, CourseProps } from '../interfaces/apps';
import '../assets/styles.css';
import { useLocation } from 'react-router-dom';
import { UserStorage } from '../interfaces/common';

const dialogStyles = {
    dialog: {
        backgroundColor: '#FFFFFF',
        color: '#11142D',
    },
    title: {
      textAlign: 'center',
        fontWeight: "700"
    },
    cancelButton: {
        color: '#475BE8',
    },
    confirmButton: {
        color: '#FFFFFF',
        backgroundColor: '#475BE8',
        '&:hover': {
            backgroundColor: '#1E36E8',
        },
    },
};

function MyProfile() {
    const [openAddCourse, closeAddCourse] = useState<boolean>(false);
    const [description, setDescription] = useState<string>('');
    const [tutorCourses, setTutorCourses] = useState<CourseProps[]>([]);
    const [tutorRole, setTutorRole] = useState<number>(0);

    const { data } = useGetIdentity<UserStorage>();
    const myProfile: any = useMemo(() => {
        return data ?? [];
    }, [data]);

    const dataProvider = useDataProvider();
    const locale = useGetLocale();
    const currentLocale = locale();
    const currDir = currentLocale === 'en' ? 'ltr' : 'rtl';

    // Get the tutor role value from the localStorage when the component mounts
    useEffect(() => {
        setTutorRole(myProfile.role);
    }, [myProfile]);

    // Listen for changes in the tutorRole state and save the updated value to the localStorage
    useEffect(() => {
        let newUser = JSON.parse(localStorage.getItem('user') || '{}');
        newUser = {
            ...newUser,
            role: tutorRole,
        }
        localStorage.setItem('user', JSON.stringify(newUser));
    }, [tutorRole]);

    useEffect(() => {
        async function getTutorProfile() {
            try {
                if (tutorRole && (tutorRole % 2 === 1)) {
                    const response = await axios.get(`${dataProvider().getApiUrl()}/users/my-courses`);
                    const profileData = response.data;
                    setTutorCourses(profileData.coursesTaught || []);
                    setDescription(profileData.description || '');
                }
            } catch (err) {
                console.error(err);
            }
        }

        getTutorProfile();
    }, [tutorRole, dataProvider]);


    const manageTutorID = async (create: boolean) => {
        const response = create ? await axios.post(`${dataProvider().getApiUrl()}/my-profile/`) :
            await axios.delete(`${dataProvider().getApiUrl()}/my-profile/`);

        if (response.status === 200) {
            handleSetTutor(response.data.role);
            if (!create) {
                setTutorCourses([]);
                setDescription('');
            }
        }
    }

    const updateTutorCourses = (newCourses: CourseProps[] | []) => {
      setTutorCourses(newCourses);
    };

    const updateSingleTutorCourse = (newCourse: CourseProps) => {
        setTutorCourses(currentCourses =>
            currentCourses.map(course =>
                course.course === newCourse.course ? newCourse : course
            )
        );
    };

    const handleAddCourseForm = () => {
        closeAddCourse(!openAddCourse);
    }

    const handleSetTutor = (tutorRole: number) => {
        if (openAddCourse)
            handleAddCourseForm();
        setTutorRole(tutorRole);
    }

    const handleTutorCreation = async (tutorRole: number) => {
        if (tutorRole % 2 === 0) {
            await manageTutorID(true);
        } else {
            handleAddCourseForm();
        }
    }

    return (
        <Box dir= {currDir}>
            <Profile
                firstName={myProfile.firstName}
                lastName={myProfile.lastName}
                email={myProfile.email}
                avatar={myProfile.avatar}
                dateOfBirth={myProfile.dateOfBirth}
                phone = {myProfile.phone}
                degreeDepartment={myProfile.degreeDepartment}
                gender={myProfile.gender}
                role={myProfile.role}
                deleteTutorID={() => manageTutorID(false)}
                tutorHook={tutorRole}
            />
            {openAddCourse ? (
                <AddCourseSection
                    tutorCourses={tutorCourses}
                    handleAddCourseForm={handleAddCourseForm}
                    updateTutorCourses={updateTutorCourses}
                />
                ) : (
                    <TutorProfileSection
                        myProfile={myProfile}
                        tutorCourses={tutorCourses}
                        description={description}
                        handleTutorCreation={handleTutorCreation}
                        tutorHook={tutorRole}
                        updateSingleTutorCourse={updateSingleTutorCourse}
                    />
                 )
            }

        </Box>
    );
}

function AddCourseSection({ tutorCourses, handleAddCourseForm, updateTutorCourses } : TutorCoursesProfileProps ) {
    // const [openDialog, setOpenDialog] = useState<boolean>(false);
    const { refineCore: { formLoading }, register, setValue, handleSubmit } = useForm();
    const dataProvider = useDataProvider();
    // const translate = useTranslate();

    const handleCourseChange = (event: SyntheticEvent<Element, Event>, value: CourseObjectProps | null) => {
        if (value) {
            setValue('course', value._id);
            setValue('courseName', value.name);
            setValue('courseId', value.id);
        } else {
            setValue('course', '');
            setValue('courseName', '');
            setValue('courseId', '');
        }
    };

    // const handleDialog = () => {
    //     setOpenDialog(!openDialog);
    // }

    // const handleConfirmDialog = async (data: FieldValues) => {
    //     try {
    //         await axios.post(`${dataProvider().getApiUrl()}/my-profile/tutor`, { ...data });
    //         handleAddCourseForm(); // Close the form.
    //         window.location.reload(); // todo, replace with a useState. A bit complicated and redundant at the moment.
    //     } catch (err) {
    //         console.error(err);
    //     }
    // }
    const onFinishHandler = async (data: FieldValues) => {
        // If price is empty, display pop up first and then handle the form submission from there.
        // if (data.price === "") {
        //     setOpenDialog(true);
        //     return;
        // }

        try {
            await axios.post(`${dataProvider().getApiUrl()}/my-profile/tutor`, { ...data });
            handleAddCourseForm(); // Close the form.
            const newCourses = [...tutorCourses, data as CourseProps];
            updateTutorCourses(newCourses); // Update the courses list.
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <Box>
            <AddCourseForm
                register={register}
                formLoading={formLoading}
                handleSubmit={handleSubmit}
                handleCourseChange={handleCourseChange}
                handleAddCourseForm={handleAddCourseForm}
                onFinishHandler={onFinishHandler}
                tutorCourses={tutorCourses}
            />
            {/*<Dialog open={openDialog} onClose={handleDialog} PaperProps={{ sx: dialogStyles.dialog }}>*/}
            {/*    <DialogTitle sx={dialogStyles.title}>Confirm submission</DialogTitle>*/}
            {/*    <Divider style={{ marginTop: 0, marginBottom: 0}}></Divider>*/}
            {/*    <DialogContent>*/}
            {/*        <Typography color="#11142D" fontSize={16} fontWeight={500}>*/}
            {/*            You have left the price field empty. This means that you will not appear in lists filtered by price. Are you sure you want to proceed without a price?*/}
            {/*        </Typography>*/}
            {/*    </DialogContent>*/}
            {/*    <DialogActions>*/}
            {/*        <Button onClick={handleDialog} style={dialogStyles.cancelButton}>*/}
            {/*            Cancel*/}
            {/*        </Button>*/}
            {/*        <Button onClick={handleSubmit(handleConfirmDialog)} autoFocus sx={dialogStyles.confirmButton}>*/}
            {/*            Confirm*/}
            {/*        </Button>*/}
            {/*    </DialogActions>*/}
            {/*</Dialog>*/}
        </Box>
    );
}

function TutorProfileSection({ tutorCourses, description, myProfile, handleTutorCreation, tutorHook, updateSingleTutorCourse } : MyTutorProfileProps) {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const displayAnimation = searchParams.get('tutorGuide');
    const translate = useTranslate();

    return (
        <Box>
            {(tutorHook % 2 === 1) && (
                <Box>
                    <Divider style={{ marginBottom: '0px' }}></Divider>

                    <TutorProfile
                        updateSingleTutorCourse={updateSingleTutorCourse}
                        description={description}
                        tutorCourses={tutorCourses}
                    />
                </Box>
            )}
            {displayAnimation && tutorHook % 2 === 0 && (
                <Box position="relative" width="fit-content" mb={1} sx={{ height: '30px', width: '100%', flex: 1, animation: 'fade 0.5s infinite alternate', }}>
                    <KeyboardArrowDown
                        style={{
                            position: 'absolute',
                            top: '60%',
                            height: '20px',
                            width: '100%',
                        }}
                    />
                    <Typography
                        variant="subtitle1"
                        align="center"
                        color="#11142D"
                        fontSize={16}
                        fontWeight={500}
                        sx={{
                            position: 'absolute',
                            top: '-5px',
                            width: '100%',
                        }}
                    >
                        {translate("myprofilepage.clickHere", "Click here")}
                    </Typography>
                </Box>
            )}
            <Box mt={2} maxWidth={{ xs: '90%', md: '100%' }}>
                {myProfile.verified === false && (
                    <Box display="flex" justifyContent="center">
                        <Typography fontSize={16} fontWeight={400} color="#111142D">{translate("myprofilepage.verify","Verify your email to become a tutor. You can complete the process by going to the settings page.")}</Typography>
                    </Box>
                )}
                <CustomButton
                    type="button"
                    disabled={myProfile.verified === false}
                    title={tutorHook === 0 ? translate("myprofilepage.tutorButton", "Become A Tutor") : tutorHook === 2 ? translate("myprofilepage.tutorButton", "Become A Tutor") : translate("myprofilepage.addCourseButton", "Add Courses")}
                    backgroundColor="#475BE8"
                    color="#FCFCFC"
                    fullWidth
                    icon={tutorHook === 0 ? <CastForEducation /> : tutorHook === 2 ? <CastForEducation /> : <Add />}
                    handleClick={() => handleTutorCreation(tutorHook)}
                />
            </Box>
        </Box>
    );
}

export default MyProfile;
