import { StarRatingProps } from '../../interfaces/common';

function StarRating({ rating }: StarRatingProps) {
    let stars = '';
    for (let i = 1; i <= 5; i++) {
        if (i <= rating) {
            stars += '★'; // filled star
        } else {
            stars += '☆'; // empty star
        }
    }

    return (
        <span
            style={{
                WebkitTextFillColor: "#d79e07",
                WebkitTextStroke: "#d79e07",
                textShadow : "1px 1px 3px grey",
            }}
        >
                {stars}
        </span>
    );
}

export default StarRating;
