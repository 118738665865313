import React, { useState } from 'react';
import { CourseFormProps, CourseObjectProps, CourseProps } from '../../interfaces/apps';
import {
  ContentPasteSearch,
  Diversity3,
  Grading,
  HowToReg,
  RateReview,
  Sell,
  VideoSettings
} from '@mui/icons-material';
import { CustomButton } from '../index';
import InputAdornment from '@mui/material/InputAdornment';

import {
  Autocomplete,
  Box,
  Card,
  FormControl,
  FormHelperText,
  MenuItem,
  Stack,
  TextField,
  Typography
} from '@mui/material';

import { Divider } from 'antd';
import { useTranslate } from '@refinedev/core';

function AddCourseForm({ register, formLoading, handleSubmit, onFinishHandler, handleCourseChange, handleAddCourseForm, tutorCourses } : CourseFormProps) {
    const formSettingsData = localStorage.getItem("tutorFormSettings");
    const defaultFormSettings = formSettingsData ? JSON.parse(formSettingsData) : {};
    const [courses] = useState<CourseObjectProps[]>(
        JSON.parse(localStorage.getItem('courses') || '[]')
    );
    // Set the default values for your form fields
    const [gradeDefVal] = useState(defaultFormSettings.grade || 0);
    const [platformDefVal] = useState(defaultFormSettings.platform || "");
    const [priceDefVal] = useState(defaultFormSettings.price || null);
    const [methodDefVal] = useState(defaultFormSettings.method || "");
    const [tabletDefVal] = useState(defaultFormSettings.tablet || false);
    const translate = useTranslate();

    return (
        <Card sx ={{ backgroundColor : "#F8F8F8",borderRadius : "15px", height : "650px", boxShadow : "0px 0px 5px #000000" }}>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    height: "100%",
                    padding : "30px"
                }}
            >
                <Box textAlign="center" >
                    <Divider style = {{ fontSize: 24, borderInlineColor: "#11142D" }}>
                        <Typography fontSize={20} fontWeight={700} color="#11142D">{translate("courseforms.addtitle", "Add course to your list")}</Typography>
                    </Divider>
                </Box>

                <form onSubmit={handleSubmit(onFinishHandler)}>
                    <Box
                        flex={1}
                        display="flex"
                        flexDirection={{ xs: "column", md: "row" }}
                        gap="20px"
                        mb="15px"
                    >
                        <FormControl>
                            <FormHelperText
                                sx={{
                                    fontWeight: 500,
                                    fontSize: 14,
                                    color: "#808191",
                                    marginLeft: "0",
                                }}
                            >{translate("courseforms.course", "Course")}</FormHelperText>
                            <Box
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                                gap="10px"
                                mt={2}
                            >
                                <Typography
                                    fontSize={14}
                                    color="#11142D"
                                    noWrap
                                >
                                    <Stack
                                        direction="row"
                                        gap="10px"
                                        flexWrap="wrap"
                                    >
                                        <ContentPasteSearch sx={{ marginTop: "7px" }} />
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            options={courses}
                                            getOptionLabel={(course: CourseObjectProps) => `${course.name} ${course.id}`}
                                            filterOptions={(options, { inputValue }) =>
                                                options.filter(
                                                    (option) =>
                                                        !tutorCourses.some(
                                                            (tutorCourses : CourseProps) => tutorCourses.courseId === option.id
                                                        ) &&
                                                        (option.name.includes(inputValue) ||
                                                            option.id.toLocaleString().includes(inputValue))
                                                )
                                            }
                                            sx={{ width: 400 }}
                                            onChange={handleCourseChange}
                                            renderInput={(params) =>
                                                <TextField
                                                    required
                                                    color="info"
                                                    {...params}
                                                    placeholder={translate("courseforms.coursePlaceholder", "Search by course name or ID")}
                                                    size="small"
                                                />}
                                        />
                                    </Stack>
                                </Typography>
                            </Box>
                        </FormControl>
                    </Box>
                    <Box
                        flex={1}
                        display="flex"
                        flexDirection={{ xs: "column", md: "row" }}
                        gap="40px"
                        mb = "15px"
                    >
                        <FormControl>
                            <FormHelperText
                                sx={{
                                    fontWeight: 500,
                                    fontSize: 14,
                                    color: "#808191",
                                    marginLeft: "0",
                                }}
                            >{translate("courseforms.grade", "Grade")}</FormHelperText>
                            <Box
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                                gap="10px"
                                mt={2}
                            >
                                <Grading sx={{ color: "#11142D" }} />
                                <Typography
                                    fontSize={17}
                                    color="#11142D"
                                    noWrap
                                >
                                    {translate("courseforms.gradeI", "Enter your grade in the selected course")}
                                </Typography>
                                <TextField
                                    required
                                    id="outlined-number"
                                    placeholder={translate("courseforms.grade", "Grade")}
                                    size="small"
                                    color="info"
                                    type="number"
                                    defaultValue={gradeDefVal}
                                    {...register("grade", {required: true})}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        min: 55,
                                        max: 100,
                                    }}
                                />
                            </Box>
                        </FormControl>
                    </Box>
                    <Box
                        flex={1}
                        display="flex"
                        flexDirection={{ xs: "column", md: "row" }}
                        gap="40px"
                        mb = "15px"
                    >
                        <FormControl>
                            <FormHelperText
                                sx={{
                                    fontWeight: 500,
                                    fontSize: 14,
                                    color: "#808191",
                                    marginLeft: "0",
                                }}
                            >{translate("courseforms.details", "Details")}</FormHelperText>
                            <Box
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                                gap="10px"
                                mt={2}
                            >
                                <VideoSettings sx={{ color: "#11142D" }} />
                                <Typography
                                    fontSize={17}
                                    color="#11142D"
                                    noWrap
                                >
                                    {translate("courseforms.platform", "Select your tutelage platform")}
                                </Typography>
                                <Box
                                    flex={1}
                                    display="flex"
                                    flexDirection={{ xs: "column", md: "row" }}
                                    gap="10px"
                                >
                                    <TextField
                                        required
                                        id="standard-select-currency"
                                        select
                                        variant="standard"
                                        color="info"
                                        defaultValue={platformDefVal}
                                        {...register("platform", {required: true}) }
                                    >
                                        <MenuItem value="Zoom">{translate("courseforms.zoom", "Zoom")}</MenuItem>
                                        <MenuItem value="Frontal">{translate("courseforms.frontal", "Frontal")}</MenuItem>
                                        <MenuItem value="Both">{translate("courseforms.both", "Both")}</MenuItem>
                                    </TextField>
                                </Box>

                            </Box>
                            <Box
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                                gap="10px"
                                mt={2}
                            >
                                <RateReview sx={{ color: "#11142D" }} />
                                <Typography
                                    fontSize={17}
                                    color="#11142D"
                                    noWrap
                                >
                                    {translate("courseforms.tablet", "Do you tutor with a tablet?")}
                                </Typography>
                                <Box
                                    flex={1}
                                    display="flex"
                                    flexDirection={{ xs: "column", md: "row" }}
                                    gap="10px"
                                >
                                    <TextField
                                        required
                                        id="standard-select-currency"
                                        select
                                        variant="standard"
                                        color="info"
                                        defaultValue={tabletDefVal}
                                        {...register("tablet", {required: true}) }
                                    >
                                        <MenuItem value="true">{translate("courseforms.yes", "Yes")}</MenuItem>
                                        <MenuItem value="false">{translate("courseforms.no", "No")}</MenuItem>
                                    </TextField>
                                </Box>
                            </Box>
                            <Box
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                                gap="10px"
                                mt={2}
                            >
                                <Diversity3 sx={{ color: "#11142D" }} />
                                <Typography
                                    fontSize={17}
                                    color="#11142D"
                                    noWrap
                                >
                                    {translate("courseforms.method", "Do you tutor in groups or 1on1?")}
                                </Typography>
                                <Box
                                    flex={1}
                                    display="flex"
                                    flexDirection={{ xs: "column", md: "row" }}
                                    gap="10px"
                                >
                                    <Typography>
                                        <TextField
                                            required
                                            id="standard-select-currency"
                                            select
                                            variant="standard"
                                            color="info"
                                            defaultValue={methodDefVal}
                                            {...register("method", {required: true}) }
                                        >
                                            <MenuItem value="Groups">{translate("courseforms.groups", "Groups")}</MenuItem>
                                            <MenuItem value="One">{translate("courseforms.1on1", "1on1")}</MenuItem>
                                            <MenuItem value="Both">{translate("courseforms.both", "Both")}</MenuItem>
                                        </TextField>
                                    </Typography>
                                </Box>
                            </Box>
                        </FormControl>
                    </Box>
                    <Box
                        flex={1}
                        display="flex"
                        flexDirection={{ xs: "column", md: "row" }}
                        gap="40px"
                        mb = "15px"
                    >
                        <FormControl>
                            <FormHelperText
                                sx={{
                                    fontWeight: 500,
                                    fontSize: 14,
                                    color: "#808191",
                                    marginLeft: "0",
                                }}
                            >{translate("courseforms.price", "Price")}</FormHelperText>
                            <Box
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                                gap="10px"
                                mt={2}
                            >
                                <Sell sx={{ color: "#11142D" }} />
                                <Typography
                                    fontSize={17}
                                    color="#11142D"
                                    noWrap
                                >
                                    {translate("courseforms.priceI", "Price per hour - optional")}
                                </Typography>
                                <TextField
                                    id="outlined-number"
                                    placeholder={translate("courseforms.price", "Price")}
                                    size="small"
                                    color="info"
                                    type="number"
                                    defaultValue={priceDefVal}
                                    {...register("price")}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">₪</InputAdornment>,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Box>
                        </FormControl>
                    </Box>
                    <Box>
                        <CustomButton
                            type = "submit"
                            title={formLoading ? translate("courseforms.saving", "Saving...") : translate("courseforms.save", "Save Changes")}
                            backgroundColor="#475BE8"
                            color="#FCFCFC"
                            icon = {<HowToReg />}
                        />
                        <CustomButton
                            type="button"
                            title={translate("courseforms.cancel", "Cancel")}
                            backgroundColor="none"
                            color="#000000"
                            handleClick={handleAddCourseForm} // Close form on cancel.
                        />
                    </Box>
                </form>
            </Box>
        </Card>
    );
}



export default AddCourseForm;
