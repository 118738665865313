import React, { useEffect, useMemo, useState } from 'react';
import { useDataProvider, useGetIdentity, useLogout } from '@refinedev/core';
import { axios } from '../App';
import { UserStorage } from '../interfaces/common';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    List,
    ListItem,
    Menu,
    MenuItem,
    Stack,
    TableCell,
    TextField,
    Typography
} from '@mui/material';
import { CalendarMonth, Delete, DeleteForever, Description, Settings } from '@mui/icons-material';
import { CustomButton } from '../components';
import { Divider } from 'antd';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';

interface User {
    _id: string;
    firstName: string;
    lastName: string;
    email: string;
    registrationType: string;
    verified: boolean;
    dateOfBirth: string | null;
    phone: string;
    gender: string;
    degreeDepartment: string;
    role: number;
    isBanned: boolean;
}

interface BannedUser {
    userId: string;
    firstName: string;
    lastName: string;
    email: string;
    role: number;
    reason: string;
    bannedAt: Date;
    unbannedAt: Date;
}

interface ReportArray {
    _id: string;
    reason: string;
    reporter: string;
    reporterUsername: string;
}

interface Report {
    _id: string;
    reportedUser: string;
    reportedUsername: string;
    escanId: string;
    numberOfReports: number;
    reports: ReportArray[];
}

interface ReportCellMenuProps {
    reports: ReportArray[];
    examReportID: string;
}

interface UserCellMenuProps {
    userData: User;
}

interface BannedInfoCellMenuProps {
    userData: User;
    type: string;
}

const dialogStyles = {
    dialog: {
        backgroundColor: '#FFFFFF',
        color: '#11142D',
        maxWidth: 'xl',
        width: '500px',
    },
    title: {
        textAlign: 'center',
        fontWeight: "700"
    },
    cancelButton: {
        color: '#475BE8',
    },
    confirmButton: {
        color: '#FFFFFF',
        backgroundColor: '#475BE8',
        '&:hover': {
            backgroundColor: '#1E36E8',
        },
    },
};

const ReportCellMenu = ({ reports, examReportID }: ReportCellMenuProps) => {
    const dataProvider = useDataProvider();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDeleteReport = async (reportID: string) => {
        try {
            handleClose(); // Close menu
            await axios.delete(`${dataProvider().getApiUrl()}/admin-control/escans-reports/${reportID}`, {
                data: {
                    examReportID
                },
            });
            window.location.reload();
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <>
            <Button onClick={handleClick} color="info">
                View Reports
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                sx={{ maxHeight: "100vh", width: "60ch" }}
            >
                {reports?.map((report, index) => (
                    <List dense>
                        <ListItem key={index}>
                            <Stack direction="column">
                                <Typography>
                                    Reporter: {report.reporter}
                                </Typography>
                                <Typography>
                                    Reporter name: {report.reporterUsername}
                                </Typography>
                                <Typography>
                                    Reason: {report.reason}
                                </Typography>
                            </Stack>
                            <MenuItem sx={{ display: "flex", alignItems: "flex-end" }} onClick={() => handleDeleteReport(report._id)}>
                                <Delete fontSize="small" />
                                Delete
                            </MenuItem>
                        </ListItem>
                    </List>
                ))}
            </Menu>
        </>
    );
};

const BannedUserCellMenu = (userId: any) => {
    const dataProvider = useDataProvider();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleUnbanUser = async (userID: string) => {
        try {
            handleClose(); // Close menu
            await axios.delete(`${dataProvider().getApiUrl()}/admin-control/ban/${userID}`);
            window.location.reload();
        } catch (err: any) {
            console.error(err);
        }
    };

    return (
      <>
          <Button onClick={handleClick} color="inherit">
              <Settings />
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            sx={{ maxHeight: "100vh", width: "20ch" }}
          >
              <MenuItem onClick={() => handleUnbanUser(userId.userId)}>
                  Unban
              </MenuItem>
          </Menu>
      </>
    );
};

const UserCellMenu = ({ userData } : UserCellMenuProps) => {
    const [banFormOpen, setBanFormOpen] = useState<boolean>(false);
    const [reasonForBan, setReason] = useState<string>('');
    const [unbanDate, setUnbanDate] = useState<Dayjs | null>(null);
    const [banResult, setBanResult] = useState<string | null>(null);

    const { data } = useGetIdentity<UserStorage>();
    const myProfile: any = useMemo(() => {
        return data ?? [];
    }, [data]);

    const dataProvider = useDataProvider();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleBanForm = () => {
        setBanFormOpen(!banFormOpen);
    }

    const handleDeleteUser = async (userID: string) => {
        try {
            handleClose(); // Close menu
            await axios.delete(`${dataProvider().getApiUrl()}/admin-control/users`, {
                data: {
                    id: userID,
                }
            });
            window.location.reload();
        } catch (err) {
            console.error(err);
        }
    };

    const handleBanUser = async (userID: string) => {
        try {
            await axios.post(`${dataProvider().getApiUrl()}/admin-control/ban/${userID}`, {
                reason: reasonForBan,
                unbanDate,
            });
            setBanResult(`Successfully banned for ${reasonForBan} until  ${unbanDate?.format('DD-MM-YYYY') ?? 'forever'}`);
            handleClose(); // Close menu
        } catch (err: any) {
            setBanResult(err.response.data.message);
            console.error(err);
        }
    };

    const handleRevokeTutor = async (userID: string) => {
        try {
            handleClose(); // Close menu
            await axios.delete(`${dataProvider().getApiUrl()}/admin-control/users/demote-tutor`, {
                data: {
                    userID,
                }
            });
            window.location.reload();
        } catch (err) {
            console.error(err);
        }
    };

    const handleUpdateAdmin = async (userID: string, promote: boolean) => {
        try {
            handleClose(); // Close menu
            await axios.patch(`${dataProvider().getApiUrl()}/admin-control/users/${promote ? '' : 'demote'}`, {
                userID,
            });
            window.location.reload();
        } catch (err) {
            console.error(err);
        }
    };
    return (
        <>
            <Button onClick={handleClick} color="inherit">
                <Settings />
            </Button>
            {myProfile.userId !== userData._id && (
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    sx={{ maxHeight: "100vh", width: "20ch" }}
                >
                    {userData.role < 2 && myProfile.email === process.env.REACT_APP_MAIN_ADMIN_EMAIL && (
                        <MenuItem onClick={() => handleUpdateAdmin(userData._id, true)}>
                            Promote To Admin
                        </MenuItem>
                    )}
                    {userData.role >= 2 && myProfile.email === process.env.REACT_APP_MAIN_ADMIN_EMAIL && (
                        <MenuItem onClick={() => handleUpdateAdmin(userData._id, false)}>
                            Demote To User
                        </MenuItem>
                    )}
                    {(userData.role === 1 || userData.role === 3) && (myProfile.email === process.env.REACT_APP_MAIN_ADMIN_EMAIL) && (
                        <MenuItem onClick={() => handleRevokeTutor(userData._id)}>
                            Revoke Tutor Role
                        </MenuItem>
                    )}
                    {(userData.role < 2 || myProfile.email === process.env.REACT_APP_MAIN_ADMIN_EMAIL) && (
                        <Box>
                            {!userData.isBanned && (
                                <MenuItem onClick={() => handleBanForm()}>
                                    Ban
                                </MenuItem>
                            )}
                            <Divider/>
                            <MenuItem onClick={() => handleDeleteUser(userData._id)}>
                                Delete
                            </MenuItem>
                        </Box>
                    )}
                </Menu>
            )}
            {banFormOpen && (
              <Dialog open={banFormOpen} onClose={handleBanForm} PaperProps={{ sx: dialogStyles.dialog }}>
                  <DialogTitle sx={dialogStyles.title}>Ban Form</DialogTitle>
                  <Divider style={{ marginTop: "5px", marginBottom: "5px" }}></Divider>
                  <DialogContent>
                      <form>
                          <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 2.5,
                            }}
                          >
                              <Stack
                                direction="row"
                                gap={1}
                                mb={2}
                              >
                                  <Typography fontSize={16} fontWeight={500} color="#11142D">Ban User: </Typography>
                                  <Typography fontSize={16} fontWeight={400} color="#11142D">{userData.firstName} {userData.lastName}</Typography>
                              </Stack>
                              <Stack
                                direction="row"
                                gap={1}
                                mb={2}
                              >
                                  <Description/>
                                  <TextField
                                    required
                                    id="outlined-multiline-static"
                                    label="Reason For Ban"
                                    placeholder="Explain - This is a required field."
                                    multiline
                                    color="info"
                                    rows={5}
                                    maxRows={5}
                                    minRows={1}
                                    sx={{ width: "90%" }}
                                    onChange={(e) => setReason(e.currentTarget.value)}
                                  />
                              </Stack>
                              <Stack flex={1} gap="15px">
                                  <Typography
                                    fontSize={14}
                                    fontWeight={500}
                                    color="#808191"
                                  >
                                      Unban Date
                                  </Typography>
                                  <Box
                                    display="flex"
                                    flexDirection="row"
                                    alignItems="center"
                                    gap="10px"
                                  >
                                      <CalendarMonth sx={{ color: "#11142D" }} />
                                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                                          <DatePicker
                                            disablePast
                                            openTo="year"
                                            views={['year', 'month', 'day']}
                                            inputFormat="DD-MM-YYYY"
                                            value={unbanDate}
                                            onChange={(newDate) => setUnbanDate(dayjs(newDate))}
                                            renderInput={(params) =>
                                              <TextField
                                                placeholder="Empty field is a permanent ban"
                                                color="info"
                                                id="standard-basic"
                                                sx={{
                                                    width: '260px',
                                                }}
                                                variant="standard"
                                                {...params}
                                              />}
                                          />
                                      </LocalizationProvider>
                                  </Box>
                              </Stack>
                          </Box>
                      </form>
                      {banResult && (
                          <Typography color="red" fontSize={14} fontWeight={500} mt={2}>
                              {banResult}
                          </Typography>
                      )}
                  </DialogContent>
                  <DialogActions>
                      <Button onClick={handleBanForm} style={dialogStyles.cancelButton}>
                          Cancel
                      </Button>
                      <Button onClick={() => handleBanUser(userData._id)} autoFocus sx={dialogStyles.confirmButton}>
                          Submit
                      </Button>
                  </DialogActions>
              </Dialog>
            )}
        </>
    );
};

const BannedInfoCell = ({ userData, type }: BannedInfoCellMenuProps) => {
    if (userData.isBanned) {
        return (
            <Typography color="red" fontSize={14} fontWeight={500}>
                {userData[type]}
            </Typography>
        );
    }
    return (
        <Typography fontSize={14} fontWeight={400}>
            {userData[type]}
        </Typography>
    );
};

const userColumns: GridColDef[] = [
    {
        field: "_id",
        headerName: "ID",
        width: 230,
        renderCell: (params) => <BannedInfoCell userData={params.row} type="_id" />,
    },
    {
        field: "firstName",
        headerName: "First Name",
        width: 140,
        renderCell: (params) => <BannedInfoCell userData={params.row} type="firstName" />,
    },
    {
        field: "lastName",
        headerName: "Last Name",
        width: 150,
        renderCell: (params) => <BannedInfoCell userData={params.row} type="lastName" />,
    },
    {
        field: "email",
        headerName: "Email",
        width: 230,
        renderCell: (params) => <BannedInfoCell userData={params.row} type="email" />,
    },
    { field: "registrationType", headerName: "Registration Type", width: 100 },
    { field: "verified", headerName: "Verified", width: 100 },
    { field: "dateOfBirth", headerName: "Date of Birth", width: 125 },
    { field: "phone", headerName: "Phone", width: 140 },
    { field: "gender", headerName: "Gender", width: 100 },
    { field: "degreeDepartment", headerName: "Degree Department", width: 180 },
    { field: "role", headerName: "Role", width: 50 },
    {
        field: 'edit',
        headerName: 'Edit',
        width: 100,
        renderCell: (params) => <UserCellMenu userData={params.row} />
    },
];

const bannedUsersColumns: GridColDef[] = [
    { field: "userId", headerName: "ID", width: 230 },
    { field: "firstName", headerName: "First Name", width: 140 },
    { field: "lastName", headerName: "Last Name", width: 150 },
    { field: "email", headerName: "Email", width: 230 },
    { field: "role", headerName: "Role", width: 75 },
    { field: "reason", headerName: "Reason For Ban", width: 250 },
    { field: "unbannedAt", headerName: "Unban Date", width: 250 },
    {
        field: 'edit',
        headerName: 'Edit',
        width: 100,
        renderCell: (params) => <BannedUserCellMenu userId={params.row.userId} />
    },
];

export function BannedUsersPage() {
    const {mutate: logout} = useLogout();
    const dataProvider = useDataProvider();

    const [allBannedUsers, setAllBannedUsers] = useState<BannedUser[]>([]);
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        async function checkAdmin() {
            try {
                const response = await axios.get(`${dataProvider().getApiUrl()}/users/role`);
                setIsAdmin(response.data.role >= 2);
            } catch (err) {
                setError('Error checking admin status');
                console.error(err);
            }
        }

        checkAdmin();
    }, [dataProvider]);

    useEffect(() => {
        async function fetchBannedUsers() {
            try {
                const response = await axios.get(`${dataProvider().getApiUrl()}/admin-control/banned-users`);
                if (response.status === 200) {
                    setAllBannedUsers(response.data);
                } else if (response.status === 403) {
                    await logout();
                }
            } catch (err) {
                setError('Error fetching banned users');
                console.error(err);
            } finally {
                setLoading(false);
            }
        }

        if (isAdmin) {
            fetchBannedUsers();
        } else {
            setLoading(false);
        }
    }, [dataProvider, isAdmin, logout]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error :( - {error}</div>;
    }
    return (
        <>
            {isAdmin ? (
                <div style={{height: "80vh", width: '100%'}}>
                    <DataGrid
                        rows={allBannedUsers}
                        getRowId={(row) => row.userId}
                        columns={bannedUsersColumns}
                        pageSize={50}
                    />
                </div>
            ) : (
                <div>Unauthorized!</div>
            )
            }
        </>
    );
}

export function AllUsersPage() {
    const { mutate: logout } = useLogout();
    const dataProvider = useDataProvider();

    const [allUsers, setAllUsers] = useState<UserStorage[]>([]);
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        async function checkAdmin() {
            try {
                const response = await axios.get(`${dataProvider().getApiUrl()}/users/role`);
                setIsAdmin(response.data.role >= 2);
            } catch (err) {
                setError('Error checking admin status');
                console.error(err);
            }
        }

        checkAdmin();
    }, [dataProvider]);

    useEffect(() => {
        async function fetchUsers() {
            try {
                const response = await axios.get(`${dataProvider().getApiUrl()}/admin-control/users`);
                if (response.status === 200) {
                    setAllUsers(response.data);
                } else if (response.status === 403) {
                    await logout();
                }
            } catch (err) {
                setError('Error fetching users');
                console.error(err);
            } finally {
                setLoading(false);
            }
        }

        if (isAdmin) {
            fetchUsers();
        } else {
            setLoading(false);
        }
    }, [dataProvider, isAdmin, logout]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error :( - {error}</div>;
    }

    return (
        <>
            {isAdmin ? (
                <div style={{ height: "80vh", width: '100%' }}>
                    <DataGrid
                        rows={allUsers}
                        getRowId={(row) => row._id}
                        columns={userColumns}
                        pageSize={50}
                    />
                </div>
                ) : (
                <div>Unauthorized!</div>
                )
            }
        </>
    );
}

export function AdminToolsPage() {
    const dataProvider = useDataProvider();
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        async function checkAdmin() {
            try {
                const response = await axios.get(`${dataProvider().getApiUrl()}/users/role`);
                setIsAdmin(response.data.role >= 2);
            } catch (err) {
                setError('Error checking admin status');
                console.error(err);
            }
        }

        checkAdmin();
    }, [dataProvider]);

    const handleScrapeGroups = async () => {
        await axios.get(`${dataProvider().getApiUrl()}/courses/scrape-groups`);
    }
    const handleScrapeCourses = async () => {
        await axios.get(`${dataProvider().getApiUrl()}/courses/scrape-courses`);
    }

    if (error) {
        return <div>Error :( - {error}</div>;
    }

    return (
        <>
            {isAdmin ? (
                <Box>
                    <Box>
                        <Typography mt={5} fontSize={24} fontWeight={500} color="#11142D" justifyContent="center" display="flex"> Scrapers </Typography>
                    </Box>
                    <Box>
                        <Typography variant="subtitle2" dir="rtl" fontSize={18} fontWeight={500} color="#11142D" mt={5}>* השתמשו בזהירות. אם יש ספק, אין ספק. דברו עם אייל, שי או אבישי.</Typography>
                        <Stack direction="column" display="flex" alignItems="center" gap={10} mt={5}>
                            <Stack direction="column" display="flex" alignItems="center" gap={2}>
                                <Typography> Update Groups Collection </Typography>
                                <CustomButton
                                    type="button"
                                    title="Scrape Groups"
                                    backgroundColor="#475BE8"
                                    color="#FCFCFC"
                                    handleClick={handleScrapeGroups}
                                />
                            </Stack>
                            <Stack direction="column" display="flex" alignItems="center" gap={2}>
                                <Typography> Update Courses Collection </Typography>
                                <CustomButton
                                    type="button"
                                    title="Scrape Courses"
                                    backgroundColor="#475BE8"
                                    color="#FCFCFC"
                                    handleClick={handleScrapeCourses}
                                />
                            </Stack>
                        </Stack>
                    </Box>
                </Box>
                ) : (
                <div>Unauthorized!</div>
                )
            }
        </>
    );
}

export function AllReportsPage() {
    const { mutate: logout } = useLogout();
    const dataProvider = useDataProvider();

    const [allReports, setAllReports] = useState<Report[]>([]);
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        async function checkAdminStatus() {
            try {
                const response = await axios.get(`${dataProvider().getApiUrl()}/users/role`);
                setIsAdmin(response.data.role >= 2);
            } catch (err) {
                setError('Error checking admin status');
                console.error(err);
            }
        }

        checkAdminStatus();
    }, [dataProvider]);

    useEffect(() => {
        async function fetchReports() {
            try {
                const response = await axios.get(`${dataProvider().getApiUrl()}/admin-control/escans-reports`);
                if (response.status === 200) {
                    setAllReports(response.data);
                } else if (response.status === 403) {
                    await logout();
                }
            } catch (err) {
                setError('Error fetching reports');
                console.error(err);
            } finally {
                setLoading(false);
            }
        }

        if (isAdmin) {
            fetchReports();
        } else {
            setLoading(false);
        }
    }, [dataProvider, isAdmin, logout]);


    const handleDeleteReport = async (reportID: string) => {
        try {
            await axios.delete(`${dataProvider().getApiUrl()}/admin-control/escans-reports/FullReport/${reportID}`);
            setAllReports(prevReports => prevReports.filter(report => report._id !== reportID));
        } catch (err) {
            console.error(err);
        }
    };

    const reportColumns: GridColDef[] = [
        { field: "_id", headerName: "Report ID", width: 250 },
        { field: "reportedUser", headerName: "Reported User ID", width: 250 },
        { field: "reportedUsername", headerName: "Reported User Name", width: 200 },
        { field: "escanId", headerName: "Reported Exam ID", width: 250 },
        { field: "numberOfReports", headerName: "Number Of Reports", width: 160 },
        {
            field: "reports",
            headerName: "Reports",
            width: 450,
            renderCell: (params) => <ReportCellMenu reports={params.value} examReportID={params.row._id}/>,
        },
        {
            field: 'delete',
            headerName: 'Delete',
            width: 100,
            renderCell: (params) => (
                <TableCell>
                    <IconButton onClick={() => handleDeleteReport(params.row._id)}>
                        <DeleteForever/>
                    </IconButton>
                </TableCell>
            ),
        },
    ];

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error :( - {error}</div>;
    }

    return (
        <>
            {isAdmin ? (
                <div style={{ height: '100%', width: '100%' }}>
                    <DataGrid
                        rows={allReports}
                        getRowId={(row) => row._id}
                        columns={reportColumns}
                        pageSize={50}
                    />
                </div>
                ) : (
                <div>Unauthorized!</div>
                )
            }
        </>
    );
}
